/* eslint-disable complexity */
/* eslint-disable no-restricted-imports */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable sonarjs/no-ignored-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable max-lines */
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticonRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { TabContext, TabList } from '@mui/lab'
import type { AutocompleteRenderGetTagProps } from '@mui/material'
import { Autocomplete, Avatar, Box, Button, Checkbox, Chip, Divider, FormControlLabel, IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, Select, Stack, Tab, Table, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import type { BaseEmoji } from 'emoji-mart'
import _ from 'lodash'
import type { SyntheticEvent } from 'react'
import { useEffect, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { newTaskUpdateNotification, sendTaskUpdate } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import EmojiPicker from 'src/components/form-elements/EmojiPicker'
import PrioritySelect from 'src/components/form-elements/PrioritySelectField'
import StatusSelect from 'src/components/form-elements/StatusSelectField'
import TagsInputField from 'src/components/form-elements/TagsInputField'
import TaskboardFilesDrawer from 'src/components/pages/Files/Taskboard/TaskBoardFilesDrawer'
import { DropzoneProvider } from 'src/components/pages/Projects/FullscreenDropzone'
import EditCustomFieldDrawer from 'src/components/pages/Task/TaskDrawer/EditCustomField'
import SelectAssignee from 'src/components/pages/Task/TaskDrawer/PersonSelect'
import TaskDrawer from 'src/components/pages/Task/TaskDrawer/TaskDrawer'
import TaskDrawerDescription from 'src/components/pages/Task/TaskDrawer/TaskDrawerRows/TaskDrawerDescriptionRow'
import TaskDrawerTitle from 'src/components/pages/Task/TaskDrawer/TaskDrawerRows/TaskDrawerTitleRow'
import TaskDrawerUpdate from 'src/components/pages/Task/TaskDrawer/TaskDrawerUpdate'
import { everyNminutes } from 'src/components/pages/Task/TaskDrawer/TimeMinutes'
import HistoryItem from 'src/components/pages/Task/TaskViews/TaskViewComponents/HistoryItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useTasksboard } from 'src/components/providers/TasksboardProvider'
import type Account from 'src/models/Account'
import type { TaskBoard, TaskGroup, TaskItem } from 'src/models/Taskboard'
import { CheckListItem, CustomFieldValue, TaskBoardEdit, TaskUpdate } from 'src/models/Taskboard'

type Props = {
  closeTaskDrawer: (requestDeletion: boolean,
    taskData?: TaskItem | undefined,
    assignees?: string[],
    originalData?: TaskItem | undefined) => () => void
  taskDataSet?: TaskItem
  taskboard: TaskBoard
  editTaskGroupData: TaskGroup | undefined
  members: Account[]
  open: boolean
}

const modernTimeSlots = everyNminutes(15)

const reorder = (list: CheckListItem[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const TaskDrawerComponent = (props: Props) => {
  const originalData = _.cloneDeep(props.taskDataSet)
  const themeItem = useTheme()
  const { currentAccount } = useAuth()
  const { tasksboardUpdates } = useTasksboard()
  const [valueTaskData, setTaskDataValue] = useState(props.taskDataSet)
  const [valueStartDate, setValueStartDate] = useState(valueTaskData?.startDate ?? null)
  const [valueDueDate, setValueDueDate] = useState(valueTaskData?.dueDate ?? null)
  const [valueStartTime, setValueStartTime] = useState(valueTaskData?.startTime)
  const [valueDueTime, setValueDueTime] = useState(valueTaskData?.dueTime)
  const [description, setDescription] = useState(valueTaskData?.description ?? '')
  const [title, setTitle] = useState(valueTaskData?.title ?? '')
  const [status, setStatus] = useState(valueTaskData?.status)
  const [priority, setPriority] = useState(valueTaskData?.priority)
  const [tags, setTags] = useState(valueTaskData?.tags)
  const [openTime, setOpenTime]  = useState(valueTaskData?.allDay)
  const [recurring, setRecurring]  = useState(valueTaskData?.recurring && valueTaskData.recurring.length > 0
    ? valueTaskData.recurring
    : 'None')
  const [assignee, setAsignee] = useState<Account[]>([])

  const [openDateStart, setOpenDateStart] = useState(false)
  const [openDateEnd, setOpenDateEnd] = useState(false)

  const [anchorElementPerson, setAnchorElementPerson] = useState<HTMLElement | null>(null)
  const openPersonMenu = Boolean(anchorElementPerson)
  const handlePersonClose = () => {
    setAnchorElementPerson(null)
  }

  const updateItems = tasksboardUpdates.filter(item => item.taskId === props.taskDataSet?.id)

  useEffect(() => {
    if (props.taskDataSet) {
      const getAssignees = () => {
        setAsignee([])
        if (props.taskDataSet) {
          for (const assignees of props.taskDataSet.assignee) {
            const temporaryItem = props.members.find(item => item.id === assignees)
            if (temporaryItem) {
              setAsignee(previousAssignees => [...previousAssignees, temporaryItem])
            }
          }
        }
      }
      getAssignees()
    }
  }, [])

  const handleAssigneeChange = (event: Account[]) => {
    const stringArray = event.map(item => item.id)

    setAsignee(event)
    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.assignee = stringArray
      setTaskDataValue(temporaryData)
    }
  }

  const [tabForTemplates, setTabForTemplates] = useState('0')

  const [anchorElementEmoji, setAnchorElementEmoji] = useState<HTMLElement | null>(null)
  const openEmojiMenu = Boolean(anchorElementEmoji)

  const handleEmojiClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementEmoji(event.currentTarget)
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const [anchorElementMore, setAnchorElementMore] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElementMore)

  const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementMore(event.currentTarget)
  }

  const handleMoreClose = () => {
    setAnchorElementMore(null)
  }

  const [anchorElementChecklist, setAnchorElementChecklist] = useState<HTMLElement | null>(null)
  const openChecklistMenu = Boolean(anchorElementChecklist)
  const [activeDeleteChecklist, setActiveDeleteChecklist] = useState('')

  const handleChecklistClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorElementChecklist(event.currentTarget)
    setActiveDeleteChecklist(id)
  }

  const handleChecklistClose = () => {
    setAnchorElementChecklist(null)
  }

  const handleChangeStartDate = (newValue: Date | null) => {
    const temporaryData = valueTaskData
    setValueStartDate(newValue ? dayjs(new Date(newValue)).toDate() : null)
    if (!openTime && temporaryData) {
      setValueDueDate(newValue ? dayjs(new Date(newValue)).toDate() : null)
      temporaryData.dueDate = newValue ? dayjs(new Date(newValue)).toDate() : null
    }
    if (valueTaskData && temporaryData) {
      temporaryData.startDate = newValue ? dayjs(new Date(newValue)).toDate() : null
      setTaskDataValue(temporaryData)
    }
  }

  const handleChangeDueDate = (newValue: Date | null) => {
    setValueDueDate(newValue ? dayjs(new Date(newValue)).toDate() : null)
    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.dueDate = newValue ? dayjs(new Date(newValue)).toDate() : null
      setTaskDataValue(temporaryData)
    }
  }

  const handleStartTimeChange = (event: SyntheticEvent, value: string) => {
    setValueStartTime(value)
    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.startTime = value
      setTaskDataValue(temporaryData)
    }
  }

  const handleDueTimeChange = (event: SyntheticEvent, value: string) => {
    setValueDueTime(value)
    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.dueTime = value
      setTaskDataValue(temporaryData)
    }
  }

  const [messageText, setMessageText] = useState('')
  const handleEmojiClose = () => {
    setAnchorElementEmoji(null)
  }

  const addEmoji = (event: BaseEmoji) => {
    const symbol = event.native
    setMessageText(`${messageText + symbol} `)
  }

  const handleTitleChange = (value: string) => {
    setTitle(value)
  }

  const handleRecurringChange = (value: string) => {
    setRecurring(value)
    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.recurring = value
      setTaskDataValue(temporaryData)
    }
  }

  const handleDescriptionChangeModern = (event: string) => {
    setDescription(event)
  }

  const handleStatusChange = (value: 'Done' | 'In progress' | 'Not approved' | 'Not started') => {
    setStatus(value)
    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.status = value
      setTaskDataValue(temporaryData)
    }
  }

  const handlePriorityChange = (value: 'High' | 'Low' | 'Medium' | 'None') => {
    setPriority(value)
    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.priority = value
      setTaskDataValue(temporaryData)
    }
  }

  const handleTagsChange = (values: string[]) => {
    setTags(values)
    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.tags = values
      setTaskDataValue(temporaryData)
    }
  }

  const handleTimeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenTime(event.target.checked)

    if (valueTaskData && valueStartDate) {
      setValueDueDate(new Date(valueStartDate))
      const temporaryData = valueTaskData
      temporaryData.dueDate = new Date(valueStartDate)
      setTaskDataValue(temporaryData)
    }

    if (valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.allDay = event.target.checked
      setTaskDataValue(temporaryData)
    }
  }

  const handleFieldChange = (newvalue: CustomFieldValue) => {
    if (valueTaskData) {
      if (valueTaskData.customFields.some(item => item.rowId === newvalue.rowId)) {
        const index = valueTaskData.customFields.findIndex(item => item.rowId === newvalue.rowId)
        if (index !== -1) {
          valueTaskData.customFields[index] = newvalue
          setTaskDataValue(props.taskDataSet)
        }
      } else {
        valueTaskData.customFields.push(newvalue)
        setTaskDataValue(valueTaskData)
      }
    }
  }

  const handleChecklistChange = (newvalue: CheckListItem) => {
    if (valueTaskData) {
      if (valueTaskData.checklist.some(item => item.id === newvalue.id)) {
        setTaskDataValue(oldValue => {
          if (oldValue) {
            const checklistItemTemporary = oldValue.checklist.map(item => item.id === newvalue.id
              ? new CheckListItem(newvalue)
              : item)
            return { ...oldValue, checklist: checklistItemTemporary }
          }
        })
      } else {
        setTaskDataValue(oldValue => {
          if (oldValue) {
            const checklistItemTemporary = [...oldValue.checklist, new CheckListItem(newvalue)]
            return { ...oldValue, checklist: checklistItemTemporary }
          }
        })
      }
    }
  }

  const handleChecklistDelete = (id: string) => {
    if (valueTaskData && valueTaskData.checklist.some(item => item.id === id)) {
      setTaskDataValue(oldValue => {
        if (oldValue) {
          const checklistItemTemporary = oldValue.checklist.filter(item => item.id !== id)
          return { ...oldValue, checklist: checklistItemTemporary }
        }
      })
    }
  }

  const handleSendMessage = async () => {
    if (messageText.length > 0 && valueTaskData) {
      setTaskDataValue(oldValue => {
        if (oldValue) {
          const messages = [...oldValue.updates,
            new TaskUpdate({ account: currentAccount.id, message: messageText })]
          return { ...oldValue, updates: messages }
        }
      })

      if (props.taskDataSet && props.taskboard.id && props.editTaskGroupData?.id) {
        await sendTaskUpdate(
          props.taskboard.id,
          props.editTaskGroupData.id,
          valueTaskData,
          new TaskUpdate({ id: v4(), account: currentAccount.id, message: messageText })
        )
          .then(() => {
            if (props.taskboard.id && props.taskDataSet?.id) {
              const taskUpdateItem = new TaskBoardEdit({
                id: v4(),
                taskboardId: props.taskboard.id,
                taskId: props.taskDataSet.id,
                accountId: currentAccount.id,
                modificationType: 'Update',
                itemType: 'Task',
                title: props.taskDataSet.title,
              })
              sendTasksboardUpdate(taskUpdateItem)
            }
          })
          .then(() => {
            valueTaskData.assignee.map(async assigneeItem => assigneeItem !== currentAccount.id
              ? await newTaskUpdateNotification(assigneeItem, {
                first_name: currentAccount.firstName,
                taskboard: props.taskboard.title,
                task_comment: messageText,
                task_name: valueTaskData.title,
                taskboard_id: props.taskboard.id ?? '',
                task_id: valueTaskData.id,
              })
              : undefined)
          })
      }
      setMessageText('')
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId && valueTaskData?.checklist && props.taskDataSet) {
      let temporaryFullElement = Object.assign({}, valueTaskData.checklist)
      temporaryFullElement = reorder(valueTaskData.checklist, result.source.index, result.destination.index)
      setTaskDataValue(oldValue => oldValue
        ? { ...oldValue, checklist: temporaryFullElement }
        : undefined)
    }
  }

  return (
    <>
      {valueTaskData &&
      <TaskDrawer
        drawerWidth={640}
        onClose={props.closeTaskDrawer(false,
          { ...valueTaskData, title, description },
          valueTaskData.assignee,
          originalData)}
        open={props.open}
      >
        <Stack alignItems='center' height={1} overflow='auto' paddingTop={0} width={1}>
          <Divider sx={{ width: 1 }} />
          <TabContext value={tabForTemplates}>
            <TabList
              aria-label='Current template tab'
              onChange={(event, value: string) => setTabForTemplates(value)}
              sx={{
                minHeight: 48,
                width: 1,
                '.MuiTab-root':
                  {
                    textTransform: 'none',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    minHeight: 48,
                    width: 1,
                    borderRadius: 0,
                  },
              }}
              variant='fullWidth'
            >
              <Tab
                icon={<TaskAltRoundedIcon />}
                label='Overview'
                value='0'
              />
              <Tab
                icon={<SendRoundedIcon />}
                label='Updates'
                value='1'
              />
              <Tab
                icon={<HistoryRoundedIcon />}
                label='History'
                value='2'
              />
            </TabList>
          </TabContext>
          <Divider sx={{ width: 1 }} />
          <Stack
            direction='column'
            display={tabForTemplates === '0' ? 'flex' : 'none'}
            flexGrow={1}
            height='calc(100% - 52px)'
            justifyContent='flex-end'
            width={1}
          >
            <Stack
              height={1}
              paddingBottom={8}
              paddingTop={2}
              paddingX={2}
              spacing={5}
              sx={{
                overflowY: 'auto',
              }}
              width={1}
            >
              <Stack direction='row' justifyContent='space-between' spacing={6} width={1} >
                <TaskDrawerTitle
                  handleTitleChange={handleTitleChange}
                  title={title}
                />
                <Stack direction='row' spacing={1} >
                  <IconButton onClick={handleMoreClick}>
                    <MoreVertRoundedIcon />
                  </IconButton>
                  <IconButton onClick={props.closeTaskDrawer(false,
                    { ...valueTaskData, title, description },
                    [],
                    originalData)}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </Stack>
              </Stack>

              {!props.taskboard.hiddenFields.includes('Due date') &&
              <Stack marginTop='16px!important' spacing={2} width={1}>
                <Stack direction='row' justifyContent='center' marginTop='16px!important' spacing={2} width={1}>
                  <Stack
                    direction='column'
                    onClick={() => setOpenDateStart(true)}
                    sx={{
                      cursor: 'pointer!important',
                    }}
                  >
                    <DatePicker
                      format='MMMM DD, YYYY'
                      onChange={value => handleChangeStartDate(dayjs(value).toDate())}
                      onClose={() => setOpenDateStart(false)}
                      open={openDateStart}
                      slotProps={{ textField: { variant: 'outlined' } }}
                      sx={{
                        marginTop: 0,
                      }}
                      value={valueStartDate ? dayjs(valueStartDate) : null}
                      views={['year', 'month', 'day']}
                    />
                  </Stack>
                  <Stack direction='column' flexShrink={0} justifyContent='center'>
                    <Typography sx={{ marginTop: 0 }} variant='inputLabel'>to</Typography>
                  </Stack>
                  <Stack
                    direction='row'
                    onClick={() => openTime ? setOpenDateEnd(true) : null}
                    sx={{
                      cursor: 'pointer!important',
                    }}
                  >
                    <DatePicker
                      disabled={!openTime}
                      format='MMMM DD, YYYY'
                      onChange={value => handleChangeDueDate(dayjs(value).toDate())}
                      onClose={() => setOpenDateEnd(false)}
                      open={openDateEnd}
                      slotProps={{ textField: { variant: 'outlined' } }}
                      sx={{
                        marginTop: 0,
                      }}
                      value={valueDueDate ? dayjs(valueDueDate) : null}
                      views={['year', 'month', 'day']}
                    />
                  </Stack>
                  <Stack direction='row' flexShrink={0} justifyContent='center'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={openTime}
                          onChange={handleTimeCheck}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                        />
                      }
                      label='All day'
                    />
                  </Stack>
                </Stack>
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Stack direction='column' height={1} justifyContent='center'>
                    <AccessTimeRoundedIcon color='action' fontSize='large' />
                  </Stack>
                  <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                    <Autocomplete
                      defaultValue={valueStartTime}
                      disableClearable
                      id='start-time'
                      onChange={handleStartTimeChange}
                      options={modernTimeSlots}
                      renderInput={params =>
                        <TextField
                          {...params}
                          sx={{ height: 42, marginTop: 0, width: 1 }}
                          variant='standard'
                        />}
                      style={{ height: 42 }}
                      sx={{ marginTop: 0, width: 1, '.MuiInputBase-input': { cursor: 'pointer' }   }}
                    />
                  </Stack>
                  <Stack
                    direction='column'
                    flexShrink={0}
                    height={1}
                    justifyContent='center'
                    width='fit-content'
                  >
                    <Typography sx={{ marginTop: 0, flexShrink: 0 }} variant='inputLabel'> to </Typography>
                  </Stack>
                  <Stack alignItems='flex-end' direction='row' justifyContent='flex-end' spacing={1} width={1}>
                    <Autocomplete
                      defaultValue={valueDueTime}
                      disableClearable
                      id='due-time'
                      onChange={handleDueTimeChange}
                      options={modernTimeSlots}
                      renderInput={params =>
                        <TextField
                          {...params}
                          sx={{ height: 42, marginTop: 0 }}
                          variant='standard'
                        />}
                      style={{ height: 42 }}
                      sx={{ marginTop: 0, width: 1, '.MuiInputBase-input': { cursor: 'pointer' }  }}
                    />
                  </Stack>
                  <Select
                    id='recurring'
                    onChange={event => handleRecurringChange(event.target.value)}
                    sx={{
                      width: '155px!important',
                      flexShrink: 0,
                      marginTop: '-12px!important',
                    }}
                    value={recurring}
                    variant='standard'
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return 'Recurring'
                      }
                      return selected
                    }}
                  >
                    <MenuItem value='None'>
                      Does not repeat
                    </MenuItem>
                    <MenuItem value='Weekly'>
                      Weekly
                    </MenuItem>
                    <MenuItem value='Monthly'>
                      Monthly
                    </MenuItem>
                  </Select>
                </Stack>

                {valueTaskData.lastDoneOn && (recurring === 'Monthly' || recurring === 'Weekly') &&
                <Stack width={1}>
                  <Typography
                    sx={{
                      marginTop: 0,
                    }}
                    variant='inputLabel'
                  >
                    Last completed
                    {' '}
                    {dayjs(new Date(valueTaskData.lastDoneOn)).format('LLLL')}
                  </Typography>
                </Stack>}
              </Stack>}

              <Stack marginTop='16px!important' spacing={2} width={1}>
                {!props.taskboard.hiddenFields.includes('Assignee') &&
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <SelectAssignee
                    assignee={assignee}
                    handleAssigneeChange={handleAssigneeChange}
                    members={props.members}
                  />
                </Stack>}
                {!props.taskboard.hiddenFields.includes('Status') &&
                recurring !== 'Monthly' &&
                recurring !== 'Weekly' &&
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography
                    sx={{
                      marginTop: 0,
                      width: 80,
                    }}
                    variant='inputLabel'
                  >
                    Status
                  </Typography>
                  <StatusSelect
                    setInputSelected={handleStatusChange}
                    statusName={status as 'Done' | 'In progress' | 'Not approved' | 'Not started'}
                  />
                </Stack>}
                {!props.taskboard.hiddenFields.includes('Priority') &&
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography
                    sx={{
                      marginTop: 0,
                      width: 80,
                    }}
                    variant='inputLabel'
                  >
                    Priority
                  </Typography>
                  <PrioritySelect
                    priorityName={priority as 'High' | 'Low' | 'Medium' | 'None'}
                    setInputSelected={handlePriorityChange}
                  />
                </Stack>}

                {!props.taskboard.hiddenFields.includes('Tags') &&
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography
                    sx={{
                      marginTop: 0,
                      width: 80,
                    }}
                    variant='inputLabel'
                  >
                    Tags
                  </Typography>
                  <TagsInputField
                    setInputSelected={handleTagsChange}
                    tags={tags ?? ['']}
                  />
                </Stack>}
              </Stack>

              {!props.taskboard.hiddenFields.includes('Description') &&
              <TaskDrawerDescription
                description={description}
                handleDescriptionChangeModern={handleDescriptionChangeModern}
              />}

              {props.taskboard.customFields.length > 0 &&
              <Stack spacing={1} width={1}>
                <Typography
                  color={themeItem.palette.text.label}
                  variant='h4'
                >
                  Fields
                </Typography>
                <Table>
                  {props.taskboard.customFields.filter(field => field.fieldType !== 'Formula').map(field =>
                    <TableRow
                      key={field.id}
                      sx={{
                        '.MuiTableCell-root': {
                          borderBottom: 0.5,
                          borderColor: 'divider',
                          color: 'text.secondary',
                        },
                      }}
                    >
                      <EditCustomFieldDrawer
                        dropdownOptions={field.dropdownOptions}
                        fieldColors={field.dropdownColors}
                        fieldName={field.title}
                        fieldType={field.fieldType}
                        fieldValue={props.taskDataSet?.customFields.find(item => item.rowId === field.id) ??
                  new CustomFieldValue({ rowId: field.id, peopleValue: [] })}
                        handleFieldChange={handleFieldChange}
                        members={props.members}
                      />
                    </TableRow>)}
                </Table>
              </Stack>}

              <Stack
                spacing={1}
                width={1}
              >
                <Typography
                  color={themeItem.palette.text.label}
                  variant='h4'
                >
                  Checklist
                </Typography>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='checklist' key='checklist'>
                    {provided => <Stack {...provided.droppableProps} ref={provided.innerRef} width={1}>
                      {valueTaskData.checklist.map((checklistItem, index) =>
                        <Draggable
                          draggableId={checklistItem.id}
                          index={index}
                          key={checklistItem.id}
                        >
                          {providedDrag =>
                            <Stack
                              alignItems='center'
                              direction='row'
                              key={checklistItem.id}
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}
                              spacing={1}
                              width={1}
                            >
                              <Checkbox
                                checked={checklistItem.completed}
                                checkedIcon={<CheckCircleRoundedIcon />}
                                color='success'
                                icon={<RadioButtonUncheckedRoundedIcon />}
                                onChange={event => {
                                  handleChecklistChange({ ...checklistItem, completed: event.target.checked })
                                }}
                                size='medium'
                                sx={{
                                  padding: 0,
                                  width: 24,
                                  height: 24,
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 24,
                                  },
                                }}
                              />
                              <TextField
                                fullWidth
                                onChange={event => {
                                  handleChecklistChange({ ...checklistItem, title: event.target.value })
                                }}
                                placeholder='Aa'
                                sx={{
                                  marginTop: 0,
                                  background: 'transparent!important',
                                  border: 'none',
                                  '.MuiInputBase-root': {
                                    border: 'none',
                                    background: 'transparent!important',
                                    borderColor: 'transparent!important',
                                    paddingY: 0,
                                    marginTop: 0,
                                  },
                                }}
                                value={checklistItem.title}
                              />
                              <Tooltip title='Re-order'>
                                <IconButton
                                  size='small'
                                  {...providedDrag.dragHandleProps}
                                >
                                  <DragIndicatorRoundedIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title='More'>
                                <IconButton
                                  onClick={event => handleChecklistClick(event, checklistItem.id)}
                                  size='small'
                                >
                                  <MoreVertRoundedIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>}
                        </Draggable>)}
                      <Menu
                        anchorEl={anchorElementChecklist}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        onClick={handleChecklistClose}
                        onClose={handleChecklistClose}
                        open={openChecklistMenu}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      >
                        <MenuItem onClick={() => handleChecklistDelete(activeDeleteChecklist)}>
                          <ListItemIcon>
                            <DeleteRoundedIcon color='error' fontSize='medium' />
                          </ListItemIcon>
                          Delete
                        </MenuItem>
                      </Menu>
                      {provided.placeholder}
                    </Stack>}
                  </Droppable>
                </DragDropContext>
                <Button
                  color='inherit'
                  onClick={() => {
                    handleChecklistChange({
                      id: v4(),
                      title: '',
                      completed: false,
                    })
                  }}
                  size='small'
                  startIcon={<AddRoundedIcon />}
                  sx={{
                    color: theme => theme.palette.text.label,
                    width: 'fit-content',
                  }}
                  variant='text'
                >
                  New checklist item
                </Button>
              </Stack>

              <Stack spacing={1} width={1}>
                <Typography
                  color={themeItem.palette.text.label}
                  variant='h4'
                >
                  Files
                </Typography>
                <DropzoneProvider>
                  <TaskboardFilesDrawer
                    taskId={props.taskDataSet?.id}
                    taskboardId={props.taskboard.id}
                  />
                </DropzoneProvider>
              </Stack>
            </Stack>

          </Stack>
          <Stack
            direction='column'
            display={tabForTemplates === '1' ? 'flex' : 'none'}
            flexGrow={1}
            height='calc(100% - 52px)'
            justifyContent='flex-end'
            paddingTop={tabForTemplates === '0' ? 2 : 0}
            spacing={2}
            width={1}
          >
            <Stack
              direction='column-reverse'
              height={tabForTemplates === '0' ? 300 : 1}
              overflow='auto'
              paddingX={1.5}
              paddingY={1}
              spacing={1}
              sx={{
                backgroundColor: theme => theme.palette.background.input,
              }}
            >
              {[...valueTaskData.updates].reverse().map(element =>
                <TaskDrawerUpdate
                  key={element.timestamp.toString()}
                  taskUpdate={element}
                />)}
            </Stack>
            <Stack justifyContent='center' paddingBottom={2}>
              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position='end' style={{ height: '100%' }}  >
                    <IconButton onClick={handleEmojiClick} size='small'>
                      <InsertEmoticonIcon />
                    </IconButton>
                    <Divider flexItem orientation='vertical' />
                    <IconButton
                      color='primary'
                      disabled={!messageText}
                      onClick={handleSendMessage}
                      size='small'
                    >
                      <SendRoundedIcon />
                    </IconButton>
                  </InputAdornment>,
                }}
                autoComplete='off'
                fullWidth
                onChange={event => setMessageText(event.currentTarget.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    void handleSendMessage()
                  }
                }}
                placeholder='Aa'
                style={{ paddingLeft: 8, paddingRight: 8, marginTop: 0 }}
                value={messageText}
              />
            </Stack>
            <Menu
              anchorEl={anchorElementEmoji}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              onClose={handleEmojiClose}
              open={openEmojiMenu}
              transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <EmojiPicker emojiHandler={addEmoji} />
            </Menu>
            <Menu
              anchorEl={anchorElementMore}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              onClick={handleMoreClose}
              onClose={handleMoreClose}
              open={openMoreMenu}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <MenuItem onClick={props.closeTaskDrawer(true,
                { ...valueTaskData, title, description },
                valueTaskData.assignee,
                originalData)}
              >
                <ListItemIcon >
                  <DeleteRoundedIcon fontSize='medium' />
                </ListItemIcon>
                Delete task
              </MenuItem>
            </Menu>

            <Menu
              anchorEl={anchorElementPerson}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              onClose={handlePersonClose}
              open={openPersonMenu}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <Autocomplete
                getOptionLabel={option => option.firstName
                  ? `${option.firstName} ${option.lastName}`
                  : `${option.email}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                multiple
                onChange={(_event, newValue) => handleAssigneeChange(newValue)}
                open
                options={props.members}
                renderInput={params =>
                  <TextField
                    {...params}
                    label='Assignees'
                    placeholder='Select people'
                    sx={{
                      marginTop: 0,
                    }}
                  />}
                renderOption={(renderProps, option) =>
                  <Box component='li' {...renderProps} >
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Avatar
                        src={option.profilePictureUrl}
                        sx={{ height: 24, width: 24 }}
                      />
                      <Typography
                        sx={{ color: theme => theme.palette.text.secondary }}
                        variant='body2'
                      >
                        {option.firstName
                          ? `${option.firstName} ${option.lastName}`
                          : `${option.email}`}
                      </Typography>
                    </Stack>
                  </Box>}
                renderTags={(value: Account[], getTagProps: AutocompleteRenderGetTagProps) =>
                  value.map((option: Account, index: number) =>
                    <Chip
                      avatar={<Avatar src={option.profilePictureUrl} />}
                      key={option.id}
                      label={`${option.firstName} ${option.lastName}`}
                      onDelete={getTagProps({ index }).onDelete}
                      sx={{ margin: 0.5 }}
                      variant='subtle'
                    />)}
                sx={{
                  marginTop: 0,
                  minHeight: 42,
                  width: 300,
                }}
                value={assignee}
              />
            </Menu>
          </Stack>
          <Stack
            direction='column'
            display={tabForTemplates === '2' ? 'flex' : 'none'}
            flexGrow={1}
            height='calc(100% - 52px)'
            justifyContent='flex-end'
            width={1}
          >
            <Stack
              height={1}
              sx={{
                overflowY: 'auto',
              }}
              width={1}
            >
              <Table>
                {updateItems.map(element =>
                  <HistoryItem
                    item={element}
                    key={element.id}
                    members={props.members}
                  />)}
              </Table>
            </Stack>
          </Stack>
        </Stack>
      </TaskDrawer>}
    </>
  )
}

export default TaskDrawerComponent
