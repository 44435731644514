/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable no-case-declarations */
import AppleIcon from '@mui/icons-material/Apple'
import FacebookIcon from '@mui/icons-material/FacebookRounded'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import GoogleIcon from '@mui/icons-material/Google'
import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, darken, Divider, FormControl, FormControlLabel, FormHelperText, Grid, lighten, Link, Stack, TextField, Typography, useTheme } from '@mui/material'
import type { FirebaseError } from 'firebase/app'
import type { MultiFactorError, MultiFactorResolver } from 'firebase/auth'
import { createUserWithEmailAndPassword, FacebookAuthProvider, getMultiFactorResolver, GoogleAuthProvider, OAuthProvider, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, signInWithPopup } from 'firebase/auth'
import { useEffect, useRef, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useLocation, useNavigate } from 'react-router-dom'

import { verifyEmail } from 'src/api/accounts'
import { startSignUp } from 'src/api/organisation'
import CookiesHandlerSignup from 'src/components/pages/Login/CookiesHandlerSignup'
import { useAuth } from 'src/components/providers/AuthProvider'
import { RouterLink } from 'src/components/SafeRouterLink'
import firebaseErrorMessages from 'src/data/firebaseAuthErrorCodes'
import { auth, parseError } from 'src/utils/firebase'

const OrDivider = <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={1}>
  <Divider sx={{ flexGrow: 1 }} />
  <Typography variant='overline'>
    Or
  </Typography>
  <Divider sx={{ flexGrow: 1 }} />
</Stack>

const SignUp = () => {
  const themeHook = useTheme()
  const { search } = useLocation()
  const { refreshCurrentAccount } = useAuth()
  const [loginState, setLoginState] = useState('login')
  const [loadingMfa, setLoadingMFA] = useState(false)
  const [acceptTerms, setAcceptTerms]  = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorMessageTerms, setErrorMessageTerms] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [resolverLogin, setResolverLogin] = useState<MultiFactorResolver>()
  const navigate = useNavigate()
  const emailItem = new URLSearchParams(search).get('email')
  const emailLead = new URLSearchParams(search).get('signup')
  const [_ga, setGa] = useState<string>()
  const [fbp, setFbp] = useState<string>()
  const [fbc, setFbc] = useState<string>()
  const [_utm_source, setUtm_source] = useState<string>()
  const [_utm_medium, setUtm_medium] = useState<string>()
  const [_utm_campaign, setUtm_campaign] = useState<string>()
  const [_utm_content, setUtm_content] = useState<string>()
  const [_utm_term, setUtm_term] = useState<string>()

  const ref = useRef<HTMLDivElement | null>(null)
  const [captchaTest, setCaptchaTest] = useState<RecaptchaVerifier>()
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    if (ref.current && !captchaTest) {
      setCaptchaTest(new RecaptchaVerifier(ref.current, {
        size: 'invisible',
      }, auth))
    }
  }, [ref, captchaTest])

  useEffect(() => {
    if (captchaTest && !rendered) {
      void captchaTest.render()
        .then(() => {
          setRendered(true)
        })
    }
  }, [captchaTest, rendered])

  useEffect(() => {
    if (emailItem) {
      setEmail(emailItem)
    }
  }, [emailItem])

  useEffect(() => {
    if (emailLead) {
      setEmail(emailLead)
      void startSignUp(emailLead, fbp, fbc)
        .catch(() => null)
    }
  }, [emailLead, fbp, fbc])

  const handleMultiError = (error: MultiFactorError) => {
    switch (error.code) {
      case 'auth/multi-factor-auth-required':
        const resolver = getMultiFactorResolver(auth, error)
        if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          const recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha',
            {
              'size': 'invisible',
            },
            auth,
          )
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          }
          const phoneAuthProvider = new PhoneAuthProvider(auth)
          setResolverLogin(resolver)
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(setVerificationId)
            .then(() => setLoginState('mfa'))
        } else {
          setLoadingMFA(false)
          setErrorMessage(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
        }
        break
      default:
        setLoadingMFA(false)
        setErrorMessage(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
    }
  }

  const facebookLogin = () => {
    const facebookProvider = new FacebookAuthProvider()
    facebookProvider.addScope('email')
    void signInWithPopup(auth, facebookProvider)
      .then(async user => {
        if (!user.user.emailVerified && user.user.email) {
          await verifyEmail(user.user.email)
            .catch(() => null)
        }
        void refreshCurrentAccount()
      })
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const appleLogin = () => {
    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email')
    void signInWithPopup(auth, appleProvider)
      .then(refreshCurrentAccount)
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const googleLogin = () => {
    const googleProvider = new GoogleAuthProvider()
    void signInWithPopup(auth, googleProvider)
      .then(refreshCurrentAccount)
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const handleCheckboxTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptTerms(event.target.checked)
  }

  const handleMfa = () => {
    setErrorMessage('')
    setLoadingMFA(true)
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    if (resolverLogin) {
      resolverLogin.resolveSignIn(multiFactorAssertion)
        .then(refreshCurrentAccount)
        .catch((error: MultiFactorError) => {
          void handleMultiError(error)
          setLoadingMFA(false)
        })
    }
  }

  const handleSubmit = async () => {
    if (captchaTest &&
      minChars &&
      minNumber &&
      minSpecialChar &&
      minUppercase &&
      minLowercase) {
      const valueCaptcha = await captchaTest.verify()
        .catch(() => {
          setLoading(false)
          setErrorMessage('Please complete the CAPTCHA to log in')
          return ''
        })
      if (valueCaptcha.length > 0) {
        if (acceptTerms) {
          setErrorMessage('')
          setErrorMessageTerms('')
          setLoading(true)
          await createUserWithEmailAndPassword(auth, email, password)
            .then(async () => {
              await verifyEmail(email)
                .then(() => navigate('/', { replace: true }))
                .catch(() => navigate('/', { replace: true }))
            })
            .catch((error: FirebaseError) => setErrorMessage(parseError(error)))
            .finally(() => setLoading(false))
        } else {
          setErrorMessage('')
          setErrorMessageTerms('Please accept the terms and conditions and our privacy policy.')
        }
      } else {
        setLoading(false)
        setErrorMessage('Please complete the CAPTCHA to log in')
      }
    }
  }

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      void handleSubmit()
    }
  }

  const minChars = password.length > 7
  const minUppercase = new RegExp(/(?=.*[A-Z])/).test(password)
  const minLowercase = new RegExp(/(?=.*[a-z])/).test(password)
  const minNumber = new RegExp(/\d/).test(password)
  const minSpecialChar = new RegExp(/^(?=.*[!#$%&*,.?@^])[\d!#$%&*,.?@A-Z^a-z]/).test(password)

  return <>
    <Stack alignContent='center' alignItems='flext-start' width={1}>
      <Stack height={0} id='recaptcha' marginTop='0px!important' />
    </Stack>
    {(() => {
      switch (loginState) {
        case 'mfa':
          return <Stack alignItems='center' paddingBottom={2} spacing={2}>
            <Typography variant='body2'>
              Please enter the code that was sent your phone number, standard rates apply.
            </Typography>
            <form onSubmit={handleMfa}>
              <Stack alignItems='center' paddingBottom={2} spacing={3}>
                <OtpInput
                  focusStyle={{
                    borderColor: themeHook.palette.primary.main,
                    borderSize: '1px',
                    borderStyle: 'solid',
                    outline: 'none',
                  }}
                  hasErrored={!!errorMessage}
                  inputStyle={{
                    borderColor: themeHook.palette.border,
                    borderSize: '1px',
                    borderStyle: 'solid',
                    borderRadius: '8px',
                    width: '54px',
                    height: '54px',
                    fontSize: '16px',
                    color: '#000',
                    fontWeight: '400',
                  }}
                  isInputNum
                  numInputs={6}
                  onChange={(event: string) => setVerificationCode(event)}
                  separator={<span style={{ width: '8px' }} />}
                  shouldAutoFocus
                  value={verificationCode}
                />
                <LoadingButton
                  id='handle-login'
                  loading={loadingMfa}
                  onClick={handleMfa}
                  onSubmit={handleMfa}
                  type='submit'
                  variant='contained'
                >
                  Login
                </LoadingButton>
                {errorMessage &&
                <Typography color='error' variant='helperText'>{errorMessage}</Typography>}
              </Stack>
            </form>
          </Stack>
        default:
          return <Stack maxWidth={400} spacing={2} width={1}>
            <Stack alignItems='center' direction='column' spacing={1} width={1}>
              <Button
                fullWidth
                onClick={facebookLogin}
                startIcon={<FacebookIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.facebook,
                  ':hover': {
                    backgroundColor: theme => darken(theme.palette.facebook, 0.2),
                  },
                }}
              >
                Continue with Facebook
              </Button>
              <Button
                fullWidth
                onClick={appleLogin}
                startIcon={<AppleIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.apple,
                  ':hover': {
                    backgroundColor: theme => lighten(theme.palette.apple, 0.2),
                  },
                }}
              >
                Sign in with Apple
              </Button>
              <Button
                fullWidth
                onClick={googleLogin}
                startIcon={<GoogleIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.google,
                  ':hover': {
                    backgroundColor: theme => darken(theme.palette.google, 0.2),
                  },
                }}
              >
                Continue with Google
              </Button>
            </Stack>
            {OrDivider}
            <Stack paddingTop={1} spacing={4}>
              <TextField
                disabled={!!emailItem}
                error={!!errorMessage}
                label='Email'
                onChange={event => setEmail(event.target.value)}
                onKeyPress={handleEnter}
                placeholder='example@email.com'
                required
                type='email'
                value={email}
              />
              <TextField
                error={!!errorMessage}
                label='Password'
                onChange={event => setPassword(event.target.value)}
                onKeyPress={handleEnter}
                placeholder='Password'
                required
                type='password'
                value={password}
              />
            </Stack>
            <Stack width={1}>
              <Grid container>
                <Grid display='flex' item sx={{ alignItems: 'center' }} xs={6}>
                  <FiberManualRecordRoundedIcon
                    sx={{
                      paddingRight: 1,
                      color: theme => minChars
                        ? theme.palette.primary.main
                        : theme.palette.text.label,
                    }}
                  />
                  <Typography
                    color={minChars
                      ? 'primary'
                      : 'textLabel'}
                    textAlign='left'
                    variant='body3'
                  >
                    8 characters minimum
                  </Typography>
                </Grid>
                <Grid display='flex' item sx={{ alignItems: 'center' }} xs={6}>
                  <FiberManualRecordRoundedIcon
                    sx={{
                      paddingRight: 1,
                      color: theme => minNumber
                        ? theme.palette.primary.main
                        : theme.palette.text.label,
                    }}
                  />
                  <Typography
                    color={minNumber
                      ? 'primary'
                      : 'textLabel'}
                    textAlign='left'
                    variant='body3'
                  >
                    One number
                  </Typography>
                </Grid>
                <Grid display='flex' item sx={{ alignItems: 'center' }} xs={6}>
                  <FiberManualRecordRoundedIcon
                    sx={{
                      paddingRight: 1,
                      color: theme => minLowercase
                        ? theme.palette.primary.main
                        : theme.palette.text.label,
                    }}
                  />
                  <Typography
                    color={minLowercase
                      ? 'primary'
                      : 'textLabel'}
                    textAlign='left'
                    variant='body3'
                  >
                    One lowercase character
                  </Typography>
                </Grid>
                <Grid display='flex' item sx={{ alignItems: 'center' }} xs={6}>
                  <FiberManualRecordRoundedIcon
                    sx={{
                      paddingRight: 1,
                      color: theme => minUppercase
                        ? theme.palette.primary.main
                        : theme.palette.text.label,
                    }}
                  />
                  <Typography
                    color={minUppercase
                      ? 'primary'
                      : 'textLabel'}
                    textAlign='left'
                    variant='body3'
                  >
                    One uppercase character
                  </Typography>
                </Grid>
                <Grid display='flex' item sx={{ alignItems: 'center' }} xs={6}>
                  <FiberManualRecordRoundedIcon
                    sx={{
                      paddingRight: 1,
                      color: theme => minSpecialChar
                        ? theme.palette.primary.main
                        : theme.palette.text.label,
                    }}
                  />
                  <Typography
                    color={minSpecialChar
                      ? 'primary'
                      : 'textLabel'}
                    textAlign='left'
                    variant='body3'
                  >
                    One special character
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <FormControl
              error={!!errorMessageTerms}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={handleCheckboxTerms}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  />
                }
                label={<Typography align='left' variant='helperText'>
                  {'I agree to the '}
                  <Link href='https://releese.io/legal/terms-and-conditions' target='_blank'>
                    Terms and conditions
                  </Link>
                  {', the '}
                  <Link href='https://releese.io/legal/distribution-agreement' target='_blank'>
                    Distribution Agreement
                  </Link>
                  {' and the '}
                  <Link href='https://releese.io/legal/privacy-policy' target='_blank'>
                    Privacy policy.
                  </Link>
                </Typography>}
              />
              {errorMessageTerms &&
              <FormHelperText>{errorMessageTerms}</FormHelperText>}
            </FormControl>
            <LoadingButton
              disabled={!minChars ||
              !minNumber ||
              !minSpecialChar ||
              !minUppercase ||
              !acceptTerms ||
              !minLowercase ||
              email.length === 0 ||
              !captchaTest}
              loading={loading}
              onClick={() => void handleSubmit()}
              variant='contained'
            >
              Sign Up
            </LoadingButton>
            <Typography color='textSecondary' textAlign='left' variant='body2'>
              {'Already a member? '}
              <Link component={RouterLink} to={`/account/login${search}`}>
                Sign in
              </Link>
            </Typography>
            <CookiesHandlerSignup
              setFbc={setFbc}
              setFbp={setFbp}
              setGa={setGa}
              setUtm_campaign={setUtm_campaign}
              setUtm_content={setUtm_content}
              setUtm_medium={setUtm_medium}
              setUtm_source={setUtm_source}
              setUtm_term={setUtm_term}
            />
          </Stack>
      }
    })()}
    <Stack ref={ref} />
  </>
}

export default SignUp
