import CelebrationRoundedIcon from '@mui/icons-material/CelebrationRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  readonly close: () => void
  readonly open: boolean
}

const ExtendedTrialModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack height={1} paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width={1}
        >
          <Typography variant='h3'>
            Tutorial complete
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack alignItems='center' spacing={2} width={1}>
          <CelebrationRoundedIcon
            color='primary'
            sx={{
              fontSize: 128,
            }}
          />
          <Typography textAlign='center' variant='body1'>
            Congrats, you have completed the 5 tasks to get you started. You are well on track to optimizing your
            career.
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
          <Button
            color='primary'
            fullWidth
            onClick={props.close}
            variant='contained'
          >
            Close tutorial
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
export default ExtendedTrialModal
