/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */

import { Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getProjects } from 'src/api/projects'
import ProjectsTableEmpty from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectsTableEmpty'
import ProjectsTableItem from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectsTableItem'
import ProjectsTableLoading from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectsTableLoading'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useNotifications } from 'src/components/providers/NotificationProvider'
import type Project from 'src/models/Project'

type Props = {
  searchQuery: string
  statusQuery: string
  formatQuery: string
  genreQuery: string
  orderBy: string
  filterTags: string[]
  filterArtists: string[]
}

const ProjectsTable = (props: Props) => {
  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()
  const { notifications } = useNotifications()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Project[]>([])
  const [page, setPage] = useState(1)

  const filterData = data.filter(project =>
    (project.title.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
    project.UPC.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
    project.recordLabel.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
    (project.primaryArtists.map(artist => artist.name.toLowerCase().includes(props.searchQuery.toLowerCase()))
      .includes(true) ||
    project.featuredArtists.map(artist => artist.name.toLowerCase().includes(props.searchQuery.toLowerCase()))
      .includes(true))) &&
    (props.filterTags.length === 0 || project.tags.some(value => props.filterTags.includes(value))) &&
    (props.filterArtists.length === 0 ||
      project.featuredArtists.map(item => item.name).some(value => props.filterArtists.includes(value)) ||
      project.primaryArtists.map(item => item.name).some(value => props.filterArtists.includes(value))) &&
    project.status.toLowerCase().includes(props.statusQuery.toLowerCase()) &&
    project.format.toLowerCase().includes(props.formatQuery.toLowerCase()) &&
    project.genre.toLowerCase().includes(props.genreQuery.toLowerCase()))
    .sort((a, b) => {
      switch (props.orderBy) {
        case 'Most recent':
          return Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
        case 'Oldest':
          return Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
        case 'Newest release date':
          return Number(new Date(b.releaseDate)) - Number(new Date(a.releaseDate))
        case 'Oldest release date':
          return Number(new Date(a.releaseDate)) - Number(new Date(b.releaseDate))
        default:
          return Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
      }
    })

  const currentPageData = filterData.slice(((page * 100) / 5) - 20, (page * 100) / 5)

  useEffect(() => {
    setLoading(true)
    void updateProjects()
      .finally(() => setLoading(false))
  }, [currentOrganisation?.id])

  useEffect(() => {
    void updateProjects()
  }, [notifications.length])

  useEffect(() => {
    setPage(1)
  }, [filterData.length])

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  const updateProjects = async () => {
    await getProjects()
      .then(setData)
      .finally(() => setLoading(false))
  }

  let interval: NodeJS.Timeout

  const refreshProjectsPeriodically = () => {
    interval = setInterval(() =>  {
      if (currentOrganisation?.importingContent) {
        void updateProjects()
        void refreshCurrentOrganisation()
      } else {
        clearInterval(interval)
      }
    }, 3000)
  }

  useEffect(() => {
    refreshProjectsPeriodically()
    return () => clearInterval(interval)
  }, [currentOrganisation?.importingContent])

  return (
    <>
      {
        loading ? <ProjectsTableLoading />
          : <Stack height={1}>
            {filterData.length > 0
              ? <TableContainer>
                <Table aria-label='projects table' size='small'>
                  <TableHead sx={{
                    '.MuiTableCell-root':
          {
            borderBottom: 2,
            borderBottomColor: 'divider',
            color: 'text.secondary',
          },
                  }}
                  >
                    <TableRow>
                      {!matches &&
                      <TableCell />}
                      <TableCell />
                      <TableCell align='left'>Title</TableCell>
                      {!matches &&
                      <>
                        <TableCell align='left'>Status</TableCell>
                        <TableCell align='left'>Release Date</TableCell>
                        <TableCell align='left'>Collaborators</TableCell>
                        <TableCell align='left'>UPC</TableCell>
                        <TableCell align='left' sx={{ width: 160 }}>Last Modified</TableCell>
                        <TableCell align='left'>Tags</TableCell>
                      </>}

                      <TableCell align='left'>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPageData.map(project =>
                      <ProjectsTableItem
                        key={`project-${project.id}`}
                        project={project}
                        updateProjects={updateProjects}
                      />)}
                  </TableBody>
                </Table>
                <Stack>
                  {
                    filterData.length > 20 &&
                    <Pagination
                      count={filterData.length > 0 ? Math.ceil(filterData.length / 20) : 1}
                      onChange={(event, value: number) => {
                        updateCurrentDisplayData(value)
                      }}
                      page={page}
                      sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
                    />
                  }
                </Stack>
              </TableContainer>
              : <ProjectsTableEmpty />}
          </Stack>
      }
    </>
  )
}

export default ProjectsTable
