import { Grid, Skeleton, Stack } from '@mui/material'

const LoadingAudience = () =>
  <Stack
    spacing={2}
    width={1}
  >
    <Stack
      alignItems='center'
      width={1}
    >
      <Grid
        container
        spacing={1}
        sx={{
          '.MuiGrid-item': {
            maxWidth: '100%',
            flexGrow: 1,
          },
        }}
      >
        {Array.from({ length: 6 }, () =>
          <Grid item lg={2} md={3} sm={3} xl={2} xs={3}>
            <Skeleton
              sx={{
                width: '100%',
                height: 32,
                marginBottom: 0.5,
                marginRight: 0.5,
                transform: 'none',
                borderRadius: '32px',
              }}
            />
          </Grid>)}
      </Grid>
    </Stack>
    <Stack direction='row' width={1}>
      <Grid
        container
        spacing={1}
        sx={{
          '.MuiGrid-item': {
            maxWidth: '100%',
            flexGrow: 1,
          },
        }}
      >
        {Array.from({ length: 6 }, () =>
          <Grid item lg={3} md={4} sm={6} xl={3} xs={6}>
            <Skeleton
              sx={{
                width: '100%',
                height: 128,
                transform: 'none',
                borderRadius: '32px',
              }}
            />
          </Grid>)}
      </Grid>
    </Stack>
    <Skeleton
      sx={{
        height: 360,
        width: '100%',
        transform: 'none',
        borderRadius: '32px',
      }}
    />
  </Stack>

export default LoadingAudience
