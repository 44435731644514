import CloudOffRoundedIcon from '@mui/icons-material/CloudOffRounded'
import { Button, Stack, Typography } from '@mui/material'

import { RouterLink } from 'src/components/SafeRouterLink'

const PageNotFound = () => <Stack
  alignItems='center'
  direction='column'
  height={1}
  justifyContent='center'
  width='100%'
>
  <Stack
    alignItems='center'
    direction='column'
    height={1}
    justifyContent='center'
    spacing={2}
    width='100%'
  >
    <Stack
      alignItems='center'
      direction='column'
      justifyContent='center'
      paddingX={2}
      width='100%'
    >
      <CloudOffRoundedIcon sx={{ color: 'action.disabled', fontSize: '144px' }} />
      <Typography color='text.label' textAlign='center' variant='h3'>
        This page isn&apos;t available
      </Typography>
      <Typography color='text.disabled' textAlign='center' variant='body1'>
        The link may be broken or the page you are looking for might have been removed.
      </Typography>
    </Stack>
    <Button
      component={RouterLink}
      to='/'
      variant='outlined'
    >
      Go to dashboard
    </Button>
  </Stack>
</Stack>

export default PageNotFound
