import { Stack, Typography } from '@mui/material'

import EditOrgLinkPixels from 'src/components/pages/Links/EditLink/EditLinkModals/EditOrgDefaultPixels'

const Pixels = () =>
  <Stack width={1}>
    <Stack padding={2} width={1}>
      <Typography lineHeight={1.2} variant='h3'>Tracking pixels</Typography>
      <Typography color='text.label' maxWidth={640} variant='body1'>
        {'Your default tracking pixels will be used in your Landing Pages '}
      </Typography>
    </Stack>
    <EditOrgLinkPixels />
  </Stack>

export default Pixels
