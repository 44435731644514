/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Avatar, createFilterOptions, ListItemText, MenuItem, Stack, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { getLabels } from 'src/api/distribution'
import { SongStatsLabels } from 'src/models/Analytics'

type Props = {
  disabled?: boolean
  placeholder: string
  selectedArtists: SongStatsLabels | null | undefined
  setSelectedArtists: (values: SongStatsLabels | null | undefined) => void
  error?: boolean
}

const SearchOneLabelSignup = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [searchArtists, setSearchArtists] = useState<SongStatsLabels[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const emptyLabelItem = new SongStatsLabels({
    songstats_label_id: '',
    avatar: '',
    name: searchQuery,
    site_url: '',
  })

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      void searchArtistFunction(searchQuery)
    } else {
      setSearchArtists([])
    }
  }, [searchQuery])

  const searchArtistFunction = async (query: string) => {
    setLoading(true)
    await getLabels(query)
      .then(response => setSearchArtists(response))
      .catch(() => null)
      .finally(() => setLoading(false))
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: SongStatsLabels) => `${option.name}`,
  })

  return (
    <>
      <Autocomplete
        disabled={props.disabled}
        filterOptions={filterOptions}
        getOptionLabel={option => `${option.name}`}
        inputValue={searchQuery}
        loading={loading}
        onChange={(event, value) => props.setSelectedArtists(value)}
        onInputChange={(event, value) =>
          setSearchQuery(value)}
        options={searchQuery.length > 0 ? [emptyLabelItem, ...searchArtists] : []}
        renderInput={params =>
          <TextField
            {...params}
            disabled={props.disabled}
            error={props.error ?? false}
            fullWidth
            placeholder={props.placeholder}
            sx={{
              marginTop: 0,
            }}
          />}
        renderOption={(renderProps, option) => {
          if (option.name && option.name.length > 0) {
            return <MenuItem
              {...renderProps}
              key={option.songstats_label_id}
              sx={{
                paddingY: 0.5,
              }}
            >
              <Stack
                sx={{
                  minWidth: 48,
                }}
              >
                <Avatar
                  src={option.avatar}
                  sx={{
                    height: 36,
                    width: 36,
                    borderRadius: 0.5,
                    backgroundColor: theme => theme.palette.background.input,
                  }}
                />
              </Stack>
              <ListItemText
                primary={option.name}
                secondary={option.songstats_label_id.length === 0 ? 'Create new profile' : undefined}
              />
            </MenuItem>
          }
        }}
        value={props.selectedArtists}
      />
      {props.error &&
      <Typography color='error' variant='body2'>
        Please do not include duplicate artists
      </Typography>}
    </>
  )
}

export default SearchOneLabelSignup
