import { TabContext } from '@mui/lab'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Profile from 'src/components/pages/Settings/Profile/Profile/MarketingProfile'

const ProfileSettings = () => {
  const location = useLocation().pathname
  const [currentTab, _setCurrentTab] = useState(location)
  return (
    <TabContext value={currentTab}>
      <Stack height={1} overflow='auto' width={1}>
        <Routes>
          <Route element={<Profile />} path='/profile' />
        </Routes>
      </Stack>
    </TabContext>
  )
}
export default ProfileSettings
