/* eslint-disable unicorn/filename-case */
/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const MlcLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 75 29'>
    <path d='M31.3438 1.49473L25.8436 10.1654L19.767 0.582031H9.88477V27.8509H19.1612V14.3339L25.6835 24.032H25.8436L31.3438 15.855V1.49473Z'  />
    <path d='M67.1813 16.8289C66.9598 17.2078 66.7303 17.5654 66.4821 17.8964C65.4039 19.3294 64.0082 20.2555 61.8358 20.2555C61.2514 20.2555 60.699 20.1674 60.1866 20.002C59.5061 19.7805 58.8976 19.4202 58.3799 18.9452C58.2518 18.8251 58.1263 18.6996 58.0116 18.5662C57.6593 18.1659 57.3631 17.7042 57.1309 17.1891C57.0535 17.0184 56.9815 16.8395 56.9201 16.6581C56.792 16.2925 56.6959 15.9028 56.6292 15.4972C56.5625 15.0915 56.5278 14.6619 56.5278 14.2189V14.1415C56.5278 12.7111 56.9308 11.39 57.6513 10.3573C58.58 9.03091 60.0345 8.17959 61.8332 8.17959C63.2903 8.17959 64.4031 8.61192 65.2865 9.33514C65.3852 9.4152 65.4786 9.5006 65.572 9.586C65.7562 9.76213 65.9323 9.94894 66.0951 10.1491C66.4234 10.5521 66.7142 11.0004 66.9784 11.4888L74.7978 7.08809C73.3033 4.48343 71.0989 2.36448 68.1313 1.14221C66.3486 0.410981 64.2937 0 61.9559 0C60.1039 0 58.3398 0.290889 56.7173 0.829968C54.8598 1.44644 53.1866 2.38849 51.7668 3.59208C51.4119 3.89364 51.0729 4.21122 50.75 4.54214C48.3375 7.03738 46.8857 10.4 46.8857 14.2162V14.2936C46.8857 14.5578 46.8937 14.8193 46.9071 15.0782C46.9471 15.8548 47.0459 16.6047 47.1953 17.3279C47.3474 18.0511 47.5529 18.7477 47.8064 19.4122C48.2308 20.5224 48.7912 21.5498 49.4664 22.4812C50.0055 23.2284 50.6166 23.9116 51.2918 24.5334C51.628 24.843 51.9803 25.1366 52.3459 25.4114C53.811 26.5163 55.4977 27.3436 57.3151 27.8506C57.7687 27.9787 58.2331 28.0855 58.7028 28.1709C59.6422 28.3443 60.6082 28.4324 61.5903 28.4324C62.0307 28.4324 62.4603 28.4191 62.8766 28.3951C63.7093 28.3443 64.4965 28.2456 65.2411 28.1015C66.3566 27.8853 67.3734 27.5704 68.3021 27.1728C71.0856 25.9799 73.0764 24.0424 74.5336 21.822C74.6964 21.5738 74.8511 21.3256 75.0006 21.0721L67.1813 16.8289Z' />
    <path d='M1.68662 24.8508V27.8984H0V19.5H1.68662V22.5477H8.23296V24.8508H1.68662Z' />
    <path d='M0 18.1912V15.8881H3.27184V11.9945H0V9.69141H8.23296V11.9945H4.96113V15.8881H8.23296V18.1912H0Z' />
    <path d='M0 7.56267V0.664062H1.68662V5.25958H3.21579V0.664062H4.90508V5.25958H6.54634V0.664062H8.23296V7.56267H0Z' />
    <path d='M49.9947 25.5691C48.308 24.0186 47.0351 22.1425 46.2131 20.0609H42.8425V0.582031H33.3633V27.8509H53.2051C52.0469 27.2264 50.9687 26.4632 49.9947 25.5691Z' />
  </SvgIcon>

export default MlcLogo
