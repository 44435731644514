import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { onboardingEvent } from 'src/api/organisation'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import MarketingCloudItem from 'src/images/marketingCloud.jpg'

const premiumFeatures = [
  'Gain new followers with Spotify, Apple Music and Deezer Pre-saves',
  'Exponentially grow your day-1 streams with Pre-saves Subscriptions',
  'Collect fan emails and notify them when you release new music',
  'Use your own domain',
]

type Props = {
  readonly close: () => void
  readonly open: boolean
}

const BioLinkFreeModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [billingModal, setBillingModalOpen] = useState(false)

  const { currentOrganisation } = useAuth()

  const notRightTier = currentOrganisation?.membershipTier === 'FREE'

  useEffect(() => {
    if (notRightTier) {
      void onboardingEvent('View premium feature')
    }
  }, [notRightTier])

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='sm'
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Stack height={1} paddingX={3} paddingY={3} spacing={4} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              Create a Bio Link
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>

          <Stack direction='column' height={1} spacing={4} width={1}>
            {notRightTier &&
              <Stack
                height='auto'
                padding={fullScreen ? 2 : 3}
                spacing={2}
                sx={{
                  borderRadius: 2,
                  background: 'black',
                }}
                width={1}
              >
                <Stack justifyContent='center' width={1}>
                  <Typography
                    color={themeItem.palette.primary.contrastText}
                    lineHeight={1.3}
                    variant='h3'
                  >
                    Grow your audience 2x faster with Releese
                  </Typography>
                  <Typography
                    color={themeItem.palette.primary.contrastText}
                    maxWidth={300}
                    variant='body2'
                  >
                    Transform your fan acquisition strategy with a premium subscription. Cancel anytime.
                  </Typography>
                </Stack>
                <Stack
                  alignItems='center'
                  direction={fullScreen ? 'column' : 'row'}
                  height={1}
                  spacing={2}
                  width={1}
                >
                  <Stack direction='column' spacing={2} width={fullScreen ? '100%' : '50%'}>
                    {premiumFeatures.map(item =>
                      <Stack alignItems='center' direction='row' key={item} spacing={1} width={1}>
                        <CheckCircleRoundedIcon color='success' fontSize='medium' />
                        <Typography
                          color={themeItem.palette.primary.contrastText}
                          textAlign='left'
                          variant='body2'
                        >
                          {item}
                        </Typography>
                      </Stack>)}
                  </Stack>
                  <img
                    alt='Releese Marketing Cloud'
                    height='auto'
                    src={MarketingCloudItem}
                    style={{
                      borderRadius: '16px',
                      objectFit: 'cover',
                      width: fullScreen ? '100%' : '50%',
                    }}
                    width='100%'
                  />
                </Stack>
                <Stack width={1}>
                  <Button
                    onClick={() => setBillingModalOpen(true)}
                    rounded
                    startIcon={<AutoAwesomeRoundedIcon />}
                    sx={{
                      marginTop: 1,
                      flexShrink: 0,
                      color: 'white!important',
                      background: '#FFA500',
                      ':hover': {
                        background: '#c47f00',
                      },
                    }}
                  >
                    Upgrade
                  </Button>
                </Stack>
              </Stack>}
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}
export default BioLinkFreeModal
