import CloseIcon from '@mui/icons-material/Close'
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Avatar, Button, Dialog, IconButton, InputAdornment, ListItemText, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import countries, { getName } from 'i18n-iso-countries'
import countryCodes from 'i18n-iso-countries/langs/en.json'
import { useState } from 'react'
import stripeTaxUtils from 'stripe-tax-utils'

import { createTaxId } from 'src/api/tax'

type Props = {
  close: () => void
  getTaxCodes: () => Promise<void>
  open: boolean
}

const AddTaxIdModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  countries.registerLocale(countryCodes)

  const allTaxTypes = stripeTaxUtils.getMap()

  const [loading, setLoading] = useState(false)

  const [code, setCode] = useState('')
  const [taxType, setTaxType] =
    useState<{ country: string, type: string, description: string, regex: RegExp, example: string }>()

  const addTaxCode = async () => {
    if (taxType && code.length > 0) {
      setLoading(true)
      await createTaxId(taxType.type, code)
        .then(() => void props.getTaxCodes())
        .then(() => setLoading(false))
        .finally(() => props.close())
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Add Tax ID
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <Autocomplete
          getOptionLabel={option => option.description}
          onChange={(_event, value) => setTaxType(value ?? undefined)}
          options={allTaxTypes}
          renderInput={params =>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment:
  <InputAdornment position='start'>
    <Stack
      sx={{
        minWidth: 36,
      }}
    >
      <Avatar
        src={`https://purecatamphetamine.github.io/country-flag-icons/1x1/
${taxType?.country.toUpperCase()}.svg`}
        sx={{
          height: 32,
          width: 32,
          borderRadius: 0.5,
          backgroundColor: theme => theme.palette.background.input,
        }}
      >
        <LanguageRoundedIcon sx={{ color: theme => theme.palette.action.disabled }} />
      </Avatar>
    </Stack>
  </InputAdornment>,
              }}
              fullWidth
              label='Tax ID Type'
              placeholder='Search'
              sx={{
                marginTop: 0,
              }}
            />}
          renderOption={(renderProps, option) =>
            <MenuItem
              {...renderProps}
              key={option.description}
              sx={{
                paddingY: 0.5,
              }}
            >
              <Stack
                sx={{
                  minWidth: 48,
                }}
              >
                <Avatar
                  src={`https://purecatamphetamine.github.io/country-flag-icons/1x1/
${option.country.toUpperCase()}.svg`}
                  sx={{
                    height: 32,
                    width: 32,
                    borderRadius: 0.5,
                    backgroundColor: theme => theme.palette.background.input,
                  }}
                />
              </Stack>
              <ListItemText
                primary={option.description}
                secondary={getName(option.country, 'en')}
              />
            </MenuItem>}
          value={taxType}
        />
        <TextField
          autoComplete='off'
          error={code.length > 0 && taxType && !taxType.regex.test(code)}
          label={taxType ? taxType.description : 'Tax ID'}
          onChange={event => setCode(event.target.value)}
          placeholder={taxType ? taxType.example : '12345678-9'}
          value={code}
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            disabled={code.length === 0 || !taxType || !taxType.regex.test(code)}
            loading={loading}
            onClick={addTaxCode}
            variant='contained'
          >
            Add Tax ID
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default AddTaxIdModal
