import type { AxiosProgressEvent, CancelTokenSource } from 'axios'
import axios from 'axios'

import type { BulkRecording, NewRecording, RecordingDto, Recoupment } from 'src/models/Recording'
import Recording from 'src/models/Recording'
import { toFormData } from 'src/utils/objectUtils'

const PATH = '/recordings' as const

// leaving this ownerId undefined returns all releases belonging to current user
export const getRecordings = () =>
  axios.get<RecordingDto[]>(`${PATH}`)
    .then(response => response.data.map(dto => new Recording(dto)))

export const getRecordingsByProjectIdPublic = (projectId: string) =>
  axios.get<RecordingDto[]>(`${PATH}/project/public/${projectId}`)
    .then(response => response.data.map(dto => new Recording(dto)))

export const getRecordingsByProjectId = (projectId: string) =>
  axios.get<RecordingDto[]>(`${PATH}/project/${projectId}`)
    .then(response => response.data.map(dto => new Recording(dto)))

export const getRecordingByCollaborator = (collaboratorId: string) =>
  axios.get<RecordingDto[]>(`${PATH}/collaborations/${collaboratorId}`)
    .then(response => response.data.map(dto => new Recording(dto)))

export const getRecording = (id: string) =>
  axios.get<RecordingDto>(`${PATH}/${id}`)
    .then(response => new Recording(response.data))

// Pass empty array after toformdata to pass empty array
export const savePartialRecording = ({ id, ...recording }: Partial<Recording> & { id: string }) =>
  axios.patch<RecordingDto>(`${PATH}/${id}`, toFormData(recording))
    .then(response => new Recording(response.data))

export const createRecording = (recording: Partial<NewRecording>,
  updateProgress: (percent: number, loaded: number, total: number) => void,
  cancelToken: CancelTokenSource) =>
  axios.post<RecordingDto>(PATH, toFormData(recording), {
    cancelToken: cancelToken.token,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const percent = Math.floor((progressEvent.loaded * 100) / (progressEvent.total ?? 1))
      updateProgress(percent, progressEvent.loaded, (progressEvent.total ?? 1))
    } }).then(response => new Recording(response.data))

export const deleteRecording = (recording: Partial<Recording>) =>
  axios.delete<string>(`${PATH}/delete/${recording.id}`)
    .then(result => result.data)

export const deleteRecordingById = (recording: string) =>
  axios.delete<string>(`${PATH}/delete/${recording}`)
    .then(result => result.data)

export const bulkUpdateRecordings = (projectId: string, updatedQuery: BulkRecording) =>
  axios.put<RecordingDto[]>(`${PATH}/bulk/${projectId}`, updatedQuery)
    .then(response => response.data.map(dto => new Recording(dto)))

export const addTransactionRecording = (recordingId: string, recoupItem: Recoupment) =>
  axios.put<RecordingDto>(`${PATH}/transaction/${recordingId}`, recoupItem)
    .then(response => new Recording(response.data))

export const getAllWriters = () =>
  axios.get<string[]>(`${PATH}/writers/all`)
    .then(response => response.data.map(dto => dto))

export const getAllComposers = () =>
  axios.get<string[]>(`${PATH}/composers/all`)
    .then(response => response.data.map(dto => dto))

export const getAllProducers = () =>
  axios.get<string[]>(`${PATH}/producers/all`)
    .then(response => response.data.map(dto => dto))

export const firstRecording = () =>
  axios.post<string>(`${PATH}/firstRecording`)
    .then(response => response)
