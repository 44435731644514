import { Stack, Typography } from '@mui/material'

import UpdateRecordingItem from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/UpdateRecordingItem'
import type { Distribution } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  recordings: Recording[]
  project: Project
  setRecordings: React.Dispatch<React.SetStateAction<Recording[]>>
  setAudio: React.Dispatch<React.SetStateAction<{ file: File, id: string }[]>>
  distributionItem: Distribution
  audioSetup: { file: File, id: string }[]
}

const RecordingsUpdate = (props: Props) =>
  <Stack spacing={4} width={1}>
    <Stack spacing={1} width={1}>
      <Typography variant='h3'>
        Recordings
      </Typography>
    </Stack>
    <Stack alignItems='center' direction='column' spacing={2} width={1}>
      {props.recordings.map(recording =>
        <UpdateRecordingItem
          audioSetup={props.audioSetup}
          distribution
          distributionItem={props.distributionItem}
          key={recording.id}
          project={props.project}
          recording={recording}
          setAudio={props.setAudio}
          setRecordings={props.setRecordings}
        />)}
    </Stack>
  </Stack>

export default RecordingsUpdate
