/* eslint-disable sonarjs/cognitive-complexity */
import { Stack, useTheme } from '@mui/material'

import ConversationHeader from 'src/components/pages/Messages/ConversationsPanel/ConversationHeader'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import type { Conversation } from 'src/models/Conversation'

type Props = {
  conversation: Conversation
  searchText: string
}

const ConversationItem = (props: Props) => {
  const { currentAccount, currentOrganisation } = useAuth()
  const { selectConversation, currentConversation } = useMessenger()
  const muiTheme = useTheme()

  const otherOrganisation = props.conversation.participants?.filter(participant =>
    participant.id !== currentOrganisation?.id)
  const otherAccounts = props.conversation.accounts?.filter(participant =>
    participant.id !== currentAccount.id)

  const title = props.conversation.title === ''
    ? props.conversation.type === 'Organisation'
      ? otherOrganisation?.map(org => org.name).join(', ')
      : props.conversation.type === 'Project' && props.conversation.project?._id
        ? props.conversation.project._id.title
        : otherAccounts?.map(account => `${account.firstName} ${account.lastName}`).join(', ')
    : props.conversation.title

  return (
    <Stack
      onClick={() => selectConversation(props.conversation)}
      sx={{
        cursor: 'pointer',
        width: 1,
        height: '84px',
        display: title?.toLowerCase().includes(props.searchText.toLowerCase()) ? 'flex' : 'none',
        border: '1px solid transparent',
        color: theme => theme.palette.text.primary,
        backgroundColor: currentConversation?.id === props.conversation.id
          ? theme => theme.palette.primary.contrast2
          : props.conversation.lastMessage.content !== '' &&
            !props.conversation.lastMessage.readByAccountIds.includes(currentAccount.id) &&
              props.conversation.lastMessage.senderId !== currentAccount.id
            ? theme => theme.palette.primary.subtleResting
            : 'transparent',
        ':hover': {
          background: theme => theme.palette.background.input,
        },
      }}
    >
      <Stack alignItems='center' direction='row' padding={1} width={1}>
        <ConversationHeader
          conversation={props.conversation}
          currentAccount={currentAccount}
          currentOrganisation={currentOrganisation}
          theme={muiTheme}
        />
      </Stack>
    </Stack>)
}

export default ConversationItem
