import axios from 'axios'

import type { InvoiceDto } from 'src/models/Invoice'
import { PaymentMethod } from 'src/models/Invoice'
import { Invoice } from 'src/models/Invoice'
import type { PriceLookupKey } from 'src/models/Organisation'
import type { ActivePlanBenefitsDto, Subscription, UpcomingInvoiceDto } from 'src/models/Payment'
import { ActivePlanBenefits, UpcomingInvoice } from 'src/models/Payment'

const PATH = '/payment' as const

export const getProductPrice = (priceLookupKey: Exclude<PriceLookupKey, 'FREE'>, quantity: number) =>
  axios.get<number>(`${PATH}/products/${priceLookupKey}/price/${quantity}`)
    .then(response => response.data)

export const getProtatedPrice = (
  priceLookupKey: Exclude<PriceLookupKey, 'FREE'>,
  quantity: number,
  country?: string,
  city?: string,
  line1?: string,
  postal_code?: string,
  state?: string
) =>
  axios.post<UpcomingInvoiceDto>(`${PATH}/update/${priceLookupKey}/${quantity}`, {
    country,
    city,
    line1,
    postal_code,
    state,
  })
    .then(response => new UpcomingInvoice(response.data))

export const createSubscription = (
  priceLookupKey: Exclude<PriceLookupKey, 'FREE'>,
  quantity: number,
  setupIntent: string,
  firstTrial: boolean,
) =>
  axios
    .put<Subscription>(`${PATH}/subscriptions`, { priceLookupKey, quantity, setupIntent, firstTrial })
    .then(response => response.data)

export const setupIntent = () => axios.post<{ client_secret: string }>(`${PATH}/setupIntent`)
  .then(response => response.data)

export const reActivateSubscription = () =>
  axios
    .put<Subscription>(`${PATH}/reactivate`)
    .then(response => response.data)

export const cancelSubscription = () =>
  axios
    .put<Subscription>(`${PATH}/subscriptions`, { priceLookupKey: 'FREE' })
    .then(response => response.data)

export const getInvoices = (accountId: string) =>
  axios.get<InvoiceDto[] | undefined>(`${PATH}/invoices/${accountId}`)
    .then(response => response.data ? response.data.map(dto => new Invoice(dto)) : undefined)

export const cancelSubscriptionText = (reason: string, text: string, trial: boolean, plan: string) =>
  axios.post<boolean>(`${PATH}/cancelPlanReason`, { reason, text, trial, plan })
    .then(response => response.data)

export const pricingFeedback = (reason: string) =>
  axios.post<boolean>(`${PATH}/pricingFeedback`, { reason })
    .then(response => response.data)

export const getPaymentMethods = () =>
  axios.get<PaymentMethod[]>(`${PATH}/paymentMethods`)
    .then(response => response.data.map(item => new PaymentMethod(item)))

export const temporaryPurchaseSerices = (
  service: string,
  price: string,
  countries: string,
  similarArtists: string,
  recording: string,
  projectId: string
) =>
  axios.post<boolean>(`${PATH}/service/${projectId}`, { service, price, countries, similarArtists, recording })
    .then(response => response.data)

export const getActivePlanBenefits = () =>
  axios.get<ActivePlanBenefitsDto>(`${PATH}/benefits`)
    .then(response => new ActivePlanBenefits(response.data))

export const extendPlanOnce = () =>
  axios.post<boolean>(`${PATH}/signup-discount`)
    .then(response => response.data)
