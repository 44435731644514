import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded'
import { Avatar, Card, IconButton, ListItemText, Stack, Tooltip } from '@mui/material'
import { useState } from 'react'
import stripeTaxUtils from 'stripe-tax-utils'

import DeleteTaxIdModal from 'src/components/pages/Account/Billing/BillingComponents/DeleteTaxIdModal'
import type { TaxId } from 'src/models/Payment'

type Props = {
  taxId: TaxId
  getTaxCodes: () => Promise<void>
}

const TaxComponentItemCheckout = (props: Props) => {
  const [openDeleteTaxModal, setOpenDeleteTaxModal] = useState(false)

  return <>
    {openDeleteTaxModal &&
    <DeleteTaxIdModal
      close={() => setOpenDeleteTaxModal(false)}
      getTaxCodes={props.getTaxCodes}
      open={openDeleteTaxModal}
      taxId={props.taxId}
    />}
    <Stack
      sx={{
        marginTop: '16px!important',
      }}
      width={1}
    >
      <Card
        elevation={0}
        sx={{
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <Stack alignItems='center' direction='row' padding={2} spacing={1} width={1}>
          <Avatar
            src={`https://purecatamphetamine.github.io/country-flag-icons/1x1/
${props.taxId.country?.toUpperCase()}.svg`}
            sx={{
              height: 32,
              width: 32,
              borderRadius: 0.5,
              backgroundColor: theme => theme.palette.background.input,
            }}
          >
            <LanguageRoundedIcon sx={{ color: theme => theme.palette.action.disabled }} />
          </Avatar>
          <ListItemText
            primary={props.taxId.value}
            secondary={stripeTaxUtils.getMap().find(item => item.type === props.taxId.type)?.description}
          />
          <Tooltip title='Remove Tax ID'>
            <IconButton
              onClick={() => setOpenDeleteTaxModal(true)}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Card>
    </Stack>
  </>
}
export default TaxComponentItemCheckout
