import AddRoundedIcon from '@mui/icons-material/AddRounded'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'
import { Button, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'

const ProjectsTableEmpty = () => {
  const themeItem = useTheme()
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  return (
    <>
      {newProjectModalOpen &&
      <NewProjectModal
        close={() => setNewProjectModalOpen(false)}
        open={newProjectModalOpen}
      />}
      <Stack
        alignItems='center'
        height={1}
        justifyContent='center'
        width='100%'
      >
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme => theme.palette.primary.main,
            borderRadius: 16,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={2}
          >
            <LibraryMusicIcon
              sx={{
                color: theme => theme.palette.primary.contrastText,
                fontSize: '64px',
              }}
            />
          </Stack>
        </Paper>
        <Typography
          color={themeItem.palette.text.secondary}
          sx={{
            marginTop: 1,
          }}
          variant='h4'
        >
          No Releases Found
        </Typography>
        <Typography
          color={themeItem.palette.text.label}
          sx={{
            textAlign: 'center',
          }}
          variant='body1'
        >
          Create a new Release to upload Recordings and invite Collaborators
        </Typography>
        <Button
          color='primary'
          onClick={() => setNewProjectModalOpen(true)}
          startIcon={<AddRoundedIcon />}
          sx={{
            marginTop: 1,
          }}
        >
          Create a Release
        </Button>
      </Stack>
    </>
  )
}

export default ProjectsTableEmpty
