import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'

import { useAuth } from 'src/components/providers/AuthProvider'
import { notificationPreferences } from 'src/models/Notification'

const keysStateSetter = (
  stateSetter: Dispatch<SetStateAction<string[]>>,
  key: string,
  checked: boolean
) => stateSetter(previous => {
  if (checked) {
    return previous.filter(previousKey => previousKey !== key)
  }
  if (!previous.includes(key)) {
    return [...previous, key]
  }

  return previous
})

const Notifications = () => {
  const { currentAccount, updateCurrentAccount, refreshCurrentAccount } = useAuth()

  const [loading, setLoading] = useState(false)
  const [allDisabled, setAllDisabled] = useState(currentAccount.disabledNotifications.disabled)
  const [emailDisabled, setEmailDisabled] = useState(currentAccount.disabledNotifications.email)
  const [inAppDisabled, _] = useState(currentAccount.disabledNotifications.inApp)

  const disableAll = async (value: boolean) => {
    setLoading(true)
    setAllDisabled(value)
    await updateCurrentAccount({
      ...currentAccount,
      disabledNotifications: {
        disabled: value,
        email: emailDisabled,
        inApp: inAppDisabled,
      },
    })
      .then(() => setLoading(false))
      .then(refreshCurrentAccount)
      .finally(() => setLoading(false))
  }

  const handleSave = async () => {
    setLoading(true)
    await updateCurrentAccount({
      ...currentAccount,
      disabledNotifications: {
        disabled: allDisabled,
        email: emailDisabled,
        inApp: inAppDisabled,
      },
    })
      .then(() => setLoading(false))
      .then(refreshCurrentAccount)
      .finally(() => setLoading(false))
  }

  return (
    <Stack height={1} overflow='auto'>
      <Stack
        direction='column'
        maxWidth={1096}
        padding={{
          xl: 2,
          lg: 2,
          md: 0,
          sm: 0,
          xs: 0,
        }}
        spacing={2}
      >
        <Stack direction='column'>
          <Typography lineHeight={1.2} variant='h3'>
            Notifications
          </Typography>
          <Typography color='text.label' variant='body1'>
            Set up the notifications you&apos;d like to receive and your preferred delivery methods.
          </Typography>
        </Stack>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          {allDisabled
            ? <>
              <Typography color='error.main' variant='subtitle2'>Notifications are disabled.</Typography>
              <Button onClick={() => disableAll(false)} size='small' variant='outlined'>Turn on notifications</Button>
            </>
            : <>
              <Typography color='success.main' variant='subtitle2'>Notifications are enabled.</Typography>
              <Button onClick={() => disableAll(true)} size='small' variant='outlined'>Turn off notifications</Button>
            </>}
        </Stack>
        <Table aria-label='projects table' size='small' stickyHeader>
          <TableHead
            sx={{
              '.MuiTableCell-root':
          {
            borderBottom: 2,
            borderBottomColor: 'divider',
            color: 'text.secondary',
            background: 'transparent',
          },
            }}
          >
            <TableRow>
              <TableCell align='left' width={250}>
                Type
              </TableCell>
              <TableCell align='left' width={150}>
                <Stack>
                  <Typography variant='tableCell'>
                    Email
                  </Typography>
                  <Typography variant='body3'>
                    Receive updates to your email address
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(notificationPreferences).map(([key, { label, Icon, description }]) =>
              <TableRow
                key={label}
                sx={{
                  '.MuiTableCell-root': { borderBottomColor: 'divider' },
                }}
              >
                <TableCell sx={{ paddingY: 2 }} width={150} >
                  <Stack alignItems='center' direction='row' spacing={2}>
                    <Icon color='action' fontSize='medium' />
                    <Stack>
                      <Typography variant='tableCell'>{label}</Typography>
                      <Typography variant='body3'>{description}</Typography>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell width={150}>
                  <Checkbox
                    defaultChecked={!currentAccount.disabledNotifications.email.includes(key)}
                    disabled={allDisabled}
                    onChange={event => keysStateSetter(setEmailDisabled, key, event.currentTarget.checked)}
                  />
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <Button variant='text'>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={handleSave}
            sx={{ width: 'fit-content' }}
            variant='contained'
          >
            Save changes
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  )
}
export default Notifications
