import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import DeleteItemComponent from 'src/components/DeleteItemComponent'

type Props = {
  close: () => void
  open: boolean
  message: string
}

const FailedDistributionModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Distribution Failed
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <DeleteItemComponent
          primary='Your Distribution Has Failed'
          secondary={props.message}
        />

        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            onClick={() => props.close()}
            variant='contained'
          >
            Go back to Release
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default FailedDistributionModal
