/* eslint-disable react-hooks/exhaustive-deps */
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import { AppBar, Card, CardActionArea, Checkbox, Paper, Slide, Stack, Typography, useTheme } from '@mui/material'

import { pricingFeedback } from 'src/api/payment'

type Props = {
  open: boolean
  setForm: React.Dispatch<React.SetStateAction<boolean>>
  setFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

const reasons = [
  'Pricing is not clear',
  'Just browsing',
  'Looking for different features',
  'Too expensive per month',
  'Commission is too high',
  'Other',
]

const FeedbackModal = (props: Props) => {
  const themeItem = useTheme()

  return (
    <Slide direction='up' in={props.open}>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 20,
          borderRadius: '8px 8px 0px 0px',
          zIndex: 1000,
          overflow: 'hidden',
        }}
      >
        <Stack
          direction='column'
          display={props.open
            ? 'flex'
            : 'none'}
          height={400}
          overflow='auto'
          position='relative'
          width={320}
        >
          <AppBar
            position='static'
            sx={{
              backgroundColor: 'transparent!important',
              background: 'transparent',
              '&.MuiAppBar-root': {
                background: 'transparent',
              },
            }}
          >
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              paddingX={2}
              paddingY={1}
              width={1}
            >
              <Typography color={themeItem.palette.primary.contrastText} variant='h4'>
                What is holding you back?
              </Typography>
            </Stack>
          </AppBar>
          <Stack
            height='auto'
            paddingBottom={2}
            paddingX={2}
            spacing={1}
            visibility='visible'
          >
            {reasons.map(reason =>
              <Stack
                key={reason}
                width={1}
              >
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: '8px',
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      void pricingFeedback(reason)
                        .then(() => {
                          props.setFormSubmitted(true)
                          props.setForm(false)
                        })
                    }}
                  >
                    <Stack alignItems='center' direction='row' padding={1} paddingRight={2} spacing={1} width={1}>
                      <Checkbox
                        checked={false}
                        color='success'
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        rounded
                        size='small'
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '16px',
                          },
                        }}
                      />
                      <Typography variant='body1'>
                        {reason}
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
              </Stack>)}
          </Stack>
        </Stack>
      </Paper>
    </Slide>
  )
}

export default FeedbackModal
