/* eslint-disable react-hooks/exhaustive-deps */

import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import NotesClient from 'src/components/pages/Notes/NotesClient'
import type Project from 'src/models/Project'
import { auth } from 'src/utils/firebase'

type Props = {
  slug: string
  project?: Project
}

const NotesInitialValue = (props: Props) => {
  const [token, setToken] = useState<string | undefined>()

  useEffect(() => {
    if (auth.currentUser) {
      void auth.currentUser.getIdToken()
        .then(tokenItem => setToken(tokenItem))
    }
  }, [auth.currentUser])

  return (
    <Stack width={1}>
      {token &&
      <NotesClient
        project={props.project}
        slug={props.slug}
        token={token}
      />}
    </Stack>
  )
}

export default NotesInitialValue
