/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'

import { createMarketingLink, getBioLink, savePartialMarketingLink } from 'src/api/links'
import { OrganisationAvatar } from 'src/components/pages/Settings/Profile/Profile/OrganisationAvatar'
import { useAuth } from 'src/components/providers/AuthProvider'
import { MarketingLink } from 'src/models/Marketing'

const Profile = () => {
  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(currentOrganisation?.name)
  const [bioLink, setBioLink] = useState<MarketingLink>()

  const handleSubmit = async () => {
    setLoading(true)
    if (!bioLink) return
    await savePartialMarketingLink(
      {
        id: bioLink.id ?? '',
        ...bioLink,
        title: name ?? '',
      }
    )
      .then(item => setBioLink(item))
      .then(() => setLoading(false))
      .then(refreshCurrentOrganisation)
      .catch(() => setLoading(false))
      .finally(() => setLoading(false))
  }

  const uploadProfilePicture = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file || !bioLink) return
    setLoading(true)
    await savePartialMarketingLink(
      {
        id: bioLink.id ?? '',
        ...bioLink,
        artwork: file,
      }
    )
      .then(item => setBioLink(item))
      .then(() => setLoading(false))
      .then(refreshCurrentOrganisation)
      .catch(() => setLoading(false))
      .finally(() => setLoading(false))
  }

  const createBioLink = async () => {
    if (currentOrganisation) {
      await createMarketingLink(new MarketingLink({
        type: 'Biolink',
        title: currentOrganisation.name,
        domain: '',
        descriptionText: 'This is my bio!',
        artworkUrl: currentOrganisation.logoUrl,
        useOrgDefaultSocialIcons: true,
        theme: 'Subtle',
        url: '',
        releaseDate: new Date(),
        organisationId: currentOrganisation.id,
        bioLinkList: [
          {
            active: true,
            id: '12345',
            blockType: 'link',
            linkPrimaryText: 'Instagram',
            textContent: '',
            linkDestinationUrl: '',
            linkSecondaryText: '',
            videoEmbedUrl: '',
            imageUrl: '',
            socialIcons: [],
            header: false,
            embedHtml: '',
            icon: '',
          },
          {
            active: true,
            id: '123456',
            blockType: 'link',
            linkPrimaryText: 'TikTok',
            textContent: '',
            linkDestinationUrl: '',
            linkSecondaryText: '',
            videoEmbedUrl: '',
            imageUrl: '',
            socialIcons: [],
            header: false,
            embedHtml: '',
            icon: '',
          },
          {
            active: true,
            id: '123457',
            blockType: 'chat',
            linkPrimaryText: '',
            textContent: 'You can add more block types and embed content here!',
            linkDestinationUrl: '',
            linkSecondaryText: '',
            videoEmbedUrl: '',
            imageUrl: '',
            socialIcons: [],
            header: false,
            embedHtml: '',
            icon: '',
          },
        ],
        useDefaultPixels: currentOrganisation.membershipTier === 'CREATOR_PRO' ||
          currentOrganisation.membershipTier === 'CREATOR_PRO_YEARLY' ||
          currentOrganisation.membershipTier === 'ENTERPRISE' ||
          currentOrganisation.membershipTier === 'ENTERPRISE_YEARLY',
      }))
    }
  }

  const getBiolinkItem = async () => {
    await getBioLink()
      .then(setBioLink)
      .then(() => {
        if (!bioLink) {
          void createBioLink()
            .then(async () => {
              await getBioLink()
                .then(setBioLink)
            })
        }
      })
      .catch(() => {
        void createBioLink()
          .then(async () => {
            await getBioLink()
              .then(setBioLink)
          })
      })
  }

  useEffect(() => {
    if (currentOrganisation?.id) {
      void getBiolinkItem()
    }
  }, [currentOrganisation?.id])

  return (
    <Stack
      padding={0}
      width={1}
    >
      <Stack padding={2} width={1}>
        <Typography lineHeight={1.2} variant='h3'>Profile</Typography>
        <Typography color='text.label' maxWidth={640} variant='body1'>
          {'Your profile is visible on your Smart Links, Releases, Splits, and Bio Link '}
        </Typography>
      </Stack>
      {currentOrganisation &&
      <Stack direction='column' height={1} maxWidth={896} overflow='auto' padding={2} spacing={4}>
        <Stack alignItems='center' justifyContent='center' spacing={4}>
          <OrganisationAvatar
            account={currentOrganisation}
            changeProfilePicture={uploadProfilePicture}
            isCurrentAccount
          />
          <TextField
            fullWidth
            inputProps={{ maxLength: 24 }}
            label='Display name'
            onChange={event_ => setName(event_.target.value)}
            placeholder='mr_cool_99'
            required
            value={name}
          />
          <LoadingButton
            loading={loading}
            onClick={() => void handleSubmit()}
            variant='contained'
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>}
    </Stack>
  )
}

export default Profile
