/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { AppBar, Dialog, IconButton, Slide, Stack, Toolbar } from '@mui/material'

import BillingComponent from 'src/components/pages/Account/Billing/BillingComponent'
import ReleeseLogo from 'src/components/ReleeseLogo'
import type { Organisation, PriceLookupKey } from 'src/models/Organisation'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly account: Organisation
  readonly price?: PriceLookupKey
}

const BillingModal = (props: Props) =>
  <Dialog
    BackdropProps={{
      timeout: 500,
    }}
    closeAfterTransition
    fullScreen
    keepMounted={false}
    onClose={props.close}
    open={props.open}
    sx={{
      background: theme => theme.palette.background.input,
    }}
  >
    <Slide direction='up' in={props.open}>
      <Stack
        height={1}
        overflow='auto'
        sx={{
          background: theme => theme.palette.background.input,
        }}
      >
        <AppBar
          sx={{
            position: 'relative',
            background: 'transparent',
          }}
        >
          <Toolbar
            sx={{
              justifyContent: 'space-between',
              paddingX: 2,
              background: 'transparent',
            }}
          >
            <ReleeseLogo full />
            <IconButton
              aria-label='close'
              onClick={props.close}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <BillingComponent
          account={props.account}
          close={props.close}
          open={props.open}
          price={props.price}
        />
      </Stack>
    </Slide>
  </Dialog>

export default BillingModal
