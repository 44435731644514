/* eslint-disable max-lines */
/* eslint-disable no-useless-escape */
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { Autocomplete, Grid, InputAdornment, ListItemText, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'

import { getAllComposers, getAllProducers, getAllWriters } from 'src/api/recordings'
import type { EditRecordingFormProps } from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingsListItem'
import { useAuth } from 'src/components/providers/AuthProvider'

export const isrcValidation = new RegExp(/[a-z]{2}[\da-z]{3}\d{7}/i)

const RecordingPublishingTab = (props: EditRecordingFormProps) => {
  const { currentOrganisation } = useAuth()
  const [recordingFields, setRecordingFields] = useState(props.recording)
  const [loading, setLoading] = useState(false)

  const [writers, setWriters] = useState<string[]>([])
  const [composers, setComposers] = useState<string[]>([])
  const [producers, setProducers] = useState<string[]>([])

  const [searchWriter, setSearchWriter] = useState('')
  const [searchComposer, setSearchComposer] = useState('')
  const [searchProducer, setSearchProducer] = useState('')

  const save = async () => {
    await props.onSave(recordingFields)
  }

  useEffect(() => {
    setLoading(true)
    void getAllWriters()
      .then(result => {
        setWriters(result)
      })
    void getAllComposers()
      .then(result => {
        setComposers(result)
      })

    void getAllProducers()
      .then(result => {
        setProducers(result)
      })
    setLoading(false)
  }, [currentOrganisation?.id])

  const updateString = (field: 'catalogNumber' | 'ISRC' | 'ISWC' | 'previewStartTime' |
  'productionYear' | 'recordLabel', value: string) => {
    const recording = Object.assign({}, recordingFields)
    recording[field] = value
    setRecordingFields(recording)
    props.saveProgress(recording)
  }

  const updateArrayField = (field:
  'composedBy' | 'producedBy' | 'writtenBy',
  // eslint-disable-next-line sonarjs/no-identical-functions
  value: string[]) => {
    const recording = Object.assign({}, recordingFields)
    recording[field] = value
    setRecordingFields(recording)
    props.saveProgress(recording)
    void props.onSave(recording)
  }

  const checkISRC = !isrcValidation.test(recordingFields.ISRC) &&
  recordingFields.ISRC.length > 0
  const checkProducedBy = recordingFields.producedBy.length === 0 ||
    recordingFields.producedBy.length > 15

  const writerOnlyFirstName = !recordingFields.writtenBy.map(writer => writer.trim().split(' ').length > 1)
    .every(element => element)
  const checkWrittenBy = writerOnlyFirstName ||
    recordingFields.writtenBy.length === 0 ||
    recordingFields.writtenBy.length > 15

  const composerOnlyFirstName = !recordingFields.composedBy.map(composer => composer.trim().split(' ').length > 1)
    .every(element => element)
  const checkComposedBy = composerOnlyFirstName ||
    recordingFields.composedBy.length === 0 ||
    recordingFields.composedBy.length > 15

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        void save()
      }}
    >
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <TextField
            autoComplete='new-password'
            disabled={props.project?.distributed}
            error={(!isrcValidation.test(recordingFields.ISRC) && recordingFields.ISRC.length > 0) || checkISRC}
            fullWidth
            inputProps={{ maxLength: 12 }}
            label='ISRC'
            onBlur={() => void save()}
            onChange={event => updateString('ISRC', event.target.value.replace(/\s/g, ''))}
            placeholder='ISRC'
            value={recordingFields.ISRC}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <TextField
            autoComplete='new-password'
            disabled={props.project?.distributed}
            fullWidth
            inputProps={{ maxLength: 12 }}
            label='ISWC'
            onBlur={() => void save()}
            onChange={event => updateString('ISWC', event.target.value.replace(/\s/g, ''))}
            placeholder='ISWC'
            value={recordingFields.ISWC}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <TextField
            autoComplete='new-password'
            disabled={props.project?.distributed}
            fullWidth
            label='Producer Catalogue Number'
            onBlur={() => void save()}
            onChange={event => updateString('catalogNumber', event.target.value.replace(/\s/g, ''))}
            placeholder='PUB00001'
            value={recordingFields.catalogNumber}
          />

        </Grid>

        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <Autocomplete
            clearOnBlur
            disabled={props.project?.distributed}
            freeSolo
            id='written-by'
            inputValue={searchWriter}
            limitTags={3}
            loading={loading}
            multiple
            onChange={(event, value) => updateArrayField('writtenBy', value)}
            onInputChange={(event, value) => setSearchWriter(value)}
            options={searchWriter.length > 0 ? [...new Set([searchWriter, ...writers])] : [...writers]}
            renderInput={params =>
              <TextField
                {...params}
                autoComplete='new-password'
                disabled={props.project?.distributed}
                error={checkWrittenBy}
                label={<Tooltip title='Composers are people who helped composed the melodies in a recoridng.
                  All composers must have a first and last name.'
                >
                  <span>
                    Composed by *
                    <InfoRoundedIcon sx={{ marginY: 'auto', marginLeft: 0.5, fontSize: '16px' }} />
                  </span>
                </Tooltip>}
                placeholder='Type and press enter to add a composer'
                sx={{
                  '.MuiFormLabel-root': {
                    pointerEvents: 'all',
                  },
                }}
              />}
            renderOption={(renderProps, option) =>
              <MenuItem
                {...renderProps}
                key={option}
                sx={{
                  paddingY: 0.5,
                }}
              >
                <ListItemText
                  primary={option}
                  secondary={option && option === searchWriter && !writers.includes(option)
                    ? 'Create new composer'
                    : undefined}
                />
              </MenuItem>}
            value={recordingFields.writtenBy}
          />
          <Stack>
            {writerOnlyFirstName &&
              <Typography color='error' variant='body2'>
                All composers must have a first and last name.
              </Typography>}
          </Stack>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <Autocomplete
            clearOnBlur
            disabled={props.project?.distributed}
            freeSolo
            id='composedBy'
            inputValue={searchComposer}
            limitTags={3}
            loading={loading}
            multiple
            onChange={(event, value) => updateArrayField('composedBy', value)}
            onInputChange={(event, value) => setSearchComposer(value)}
            options={searchComposer.length > 0 ? [...new Set([searchComposer, ...composers])] : [...composers]}
            renderInput={params =>
              <TextField
                {...params}
                autoComplete='new-password'
                disabled={props.project?.distributed}
                error={checkComposedBy}
                label={<Tooltip title='Writers are people who wrote lyrics on a recording, they are often known
                  as songwriters. All writers must have a first and last name.'
                >
                  <span>
                    Written by *
                    <InfoRoundedIcon sx={{ marginY: 'auto', marginLeft: 0.5, fontSize: '16px' }} />
                  </span>
                </Tooltip>}
                placeholder='Type and press enter to add a writer'
                sx={{
                  '.MuiFormLabel-root': {
                    pointerEvents: 'all',
                  },
                }}
              />}
            renderOption={(renderProps, option) =>
              <MenuItem
                {...renderProps}
                key={option}
                sx={{
                  paddingY: 0.5,
                }}
              >
                <ListItemText
                  primary={option}
                  secondary={option && option === searchComposer && !composers.includes(option)
                    ? 'Create new writer'
                    : undefined}
                />
              </MenuItem>}
            value={recordingFields.composedBy}
          />
          <Stack>
            {composerOnlyFirstName &&
              <Typography color='error' variant='body2'>
                All writers must have a first and last name.
              </Typography>}
          </Stack>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <Autocomplete
            clearOnBlur
            disabled={props.project?.distributed}
            freeSolo
            id='produced-by'
            inputValue={searchProducer}
            limitTags={3}
            loading={loading}
            multiple
            onChange={(event, value) => updateArrayField('producedBy', value)}
            onInputChange={(event, value) => setSearchProducer(value)}
            options={searchProducer.length > 0 ? [...new Set([searchProducer, ...producers])] : [...producers]}
            renderInput={params =>
              <TextField
                {...params}
                autoComplete='new-password'
                disabled={props.project?.distributed}
                error={checkProducedBy}
                label={<Tooltip title='Producers are people who helped produce the recording. A full name or
                  alias is required.'
                >
                  <span>
                    Produced by *
                    <InfoRoundedIcon sx={{ marginY: 'auto', marginLeft: 0.5, fontSize: '16px' }} />
                  </span>
                </Tooltip>}
                placeholder='Type and press enter to add a producer'
                sx={{
                  '.MuiFormLabel-root': {
                    pointerEvents: 'all',
                  },
                }}
              />}
            renderOption={(renderProps, option) =>
              <MenuItem
                {...renderProps}
                key={option}
                sx={{
                  paddingY: 0.5,
                }}
              >
                <ListItemText
                  primary={option}
                  secondary={option && option === searchProducer && !producers.includes(option)
                    ? 'Create new producer'
                    : undefined}
                />
              </MenuItem>}
            value={recordingFields.producedBy}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                <Typography variant='inputText'>S</Typography>
              </InputAdornment>,
            }}
            autoComplete='new-password'
            disabled={props.project?.distributed}
            fullWidth
            label='Preview Start'
            onBlur={() => void save()}
            onChange={event => updateString('previewStartTime', event.target.value)}
            placeholder='120.00'
            value={recordingFields.previewStartTime}
          />

        </Grid>
      </Grid>
    </form>
  )
}
export default RecordingPublishingTab
