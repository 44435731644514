/* eslint-disable max-lines */
/* eslint-disable complexity */
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded'
import PaidRoundedIcon from '@mui/icons-material/PaidRounded'
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded'
import StoreRoundedIcon from '@mui/icons-material/StoreRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { Avatar, Badge, Card, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Login from './Login'
import LostPassword from './LostPassword'
import SignUp from './SignUp'
import { getBasicOrganisation } from 'src/api/organisation'
import SignUpItem from 'src/components/pages/Login/SignUpItem'
import ReleeseLogo from 'src/components/ReleeseLogo'
import type { BasicOrganisation } from 'src/models/Organisation'

const LoginRoute = () => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const location = useLocation()
  const signUpPath = '/account/signup'
  const search = useLocation().search
  const email = new URLSearchParams(search).get('email')
  const orgId = new URLSearchParams(search).get('team')

  const [orgItem, setOrgItem] = useState<BasicOrganisation | null | undefined>()

  useEffect(() => {
    if (orgId && orgId.length > 0) {
      void getBasicOrganisation(orgId)
        .then(setOrgItem)
        .catch(() => null)
    }
  }, [orgId])

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 1,
        height: '100%',
        width: '100%!important',
        padding: 0,
        margin: 0,
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        maxWidth: '100%!important',
        backgroundColor: matches || location.pathname === signUpPath
          ? theme => theme.palette.background.default
          : theme => theme.palette.background.input,
      }}
    >
      <Stack
        alignItems='center'
        direction='row'
        height={1}
        justifyContent='center'
        spacing={2}
        width={1}
      >
        <Card
          elevation={matches || location.pathname === signUpPath
            ? 0
            : 2}
          sx={{
            padding: 3,
            textAlign: 'center',
            width: location.pathname !== signUpPath
              ? 448
              : matches
                ? 448
                : '50%',
            marginY: matches ? 0 : 2,
          }}
        >
          <Stack paddingY={4} >
            <ReleeseLogo full />
          </Stack>
          <Stack alignItems='center' spacing={2} width={1}>
            <Routes>
              <Route element={<Login />} path='/login' />
              <Route element={<LostPassword />} path='/lostpassword' />
              <Route element={<SignUp />} path='/signup' />
            </Routes>
          </Stack>
        </Card>
        {location.pathname === signUpPath &&
        !matches &&
        !email &&
        !orgId &&
        <Stack
          alignItems='center'
          height={1}
          justifyContent='center'
          overflow='auto'
          padding={2}
          spacing={4}
          sx={{
            background: theme => theme.palette.background.input,
            borderRadius: '64px 0px 0px 64px',
          }}
          width='50%'
        >
          <Stack alignItems='flex-start' justifyContent='flex-start' maxWidth='sm' width={1}>
            <Typography lineHeight={1} sx={{ marginBottom: 1 }} textAlign='left' variant='h1'>
              <span>
                Try every
              </span>
              <span
                style={{
                  color: themeColors.palette.primary.main,
                  fontFamily: 'Domaine-Display',
                }}
              >
                {' Releese '}
              </span>
              <span>
                feature
              </span>
            </Typography>

            <Typography textAlign='left' variant='h3'>
              With a 14-day trial
            </Typography>

            <Stack alignItems='flex-start' justifyContent='flex-start' width={1}>
              <Typography textAlign='left' variant='body1'>
                <CheckCircleRoundedIcon color='success' />
                {' Try now, decide later '}

              </Typography>
              <Typography textAlign='left' variant='body1'>
                <CheckCircleRoundedIcon color='success' />
                {' No upfront cost '}

              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems='center' justifyContent='center' spacing={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='center' spacing={2} width={1}>
              <SignUpItem
                color={themeColors.palette.info.main}
                subtitle='Sell your music in the world&apos;s top digital platforms'
                title='Distribution'
              >
                <StoreRoundedIcon
                  color='info'
                  sx={{
                    height: 48,
                    width: 48,
                    color: themeColors.palette.primary.contrastText,
                  }}
                />
              </SignUpItem>
              <SignUpItem
                color={themeColors.palette.warning.main}
                subtitle='Build hype for a new release with pre-save subscriptions'
                title='Pre-Saves'
              >
                <PhoneIphoneRoundedIcon
                  color='warning'
                  sx={{
                    height: 48,
                    width: 48,
                    color: themeColors.palette.primary.contrastText,
                  }}
                />
              </SignUpItem>
            </Stack>

            <Stack alignItems='center' direction='row' justifyContent='center' spacing={2} width={1}>
              <SignUpItem
                color={themeColors.palette.error.main}
                subtitle='Track your music in playlists, charts and social channels'
                title='Analytics'
              >
                <BubbleChartRoundedIcon
                  color='error'
                  sx={{
                    height: 48,
                    width: 48,
                    color: themeColors.palette.primary.contrastText,
                  }}
                />
              </SignUpItem>
              <SignUpItem
                color={themeColors.palette.success.main}
                subtitle='Collect your publishing + neighboring rights in one click'
                title='Monetization'
              >
                <PaidRoundedIcon
                  color='success'
                  sx={{
                    height: 48,
                    width: 48,
                    color: themeColors.palette.primary.contrastText,
                  }}
                />
              </SignUpItem>
            </Stack>

            <Stack alignItems='center' direction='row' justifyContent='center' spacing={2} width={1}>
              <SignUpItem
                color={themeColors.palette.secondary.main}
                subtitle='Manage, assign and divide work accross teams'
                title='Release Planning'
              >
                <TaskAltRoundedIcon
                  color='secondary'
                  sx={{
                    height: 48,
                    width: 48,
                    color: themeColors.palette.primary.contrastText,
                  }}
                />
              </SignUpItem>
              <SignUpItem
                color={themeColors.palette.primary.main}
                subtitle='Promote your music from a single link'
                title='Link in Bio'
              >
                <InsertLinkRoundedIcon
                  color='primary'
                  sx={{
                    height: 48,
                    width: 48,
                    color: themeColors.palette.primary.contrastText,
                  }}
                />
              </SignUpItem>
            </Stack>
          </Stack>
        </Stack>}

        {location.pathname === signUpPath &&
        !matches &&
        email &&
        orgId &&
        <Stack
          alignItems='center'
          height={1}
          justifyContent='center'
          overflow='auto'
          padding={2}
          spacing={2}
          sx={{
            background: theme => theme.palette.background.input,
          }}
          width='50%'
        >
          <Stack maxWidth='sm' width={1}>
            <Typography lineHeight={1.2} textAlign='center' variant='h3'>
              You have been invited to join
              {' '}
              <span
                style={{
                  color: themeColors.palette.primary.main,
                }}
              >
                {orgItem?.name}
              </span>
            </Typography>
          </Stack>

          <Stack alignItems='center' direction='row' spacing={1}>
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<CheckCircleRoundedIcon
                color='secondary'
                fontSize='small'
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                }}
              />}
              overlap='circular'
            >
              <Avatar
                src={orgItem?.logoUrl}
              />
            </Badge>
            <Typography variant='h6'>
              {orgItem?.name}
            </Typography>
          </Stack>

          <Stack maxWidth='sm' width={1}>
            <Typography
              color={themeColors.palette.text.secondary}
              textAlign='center'
              variant='body2'
            >
              Releese is a friendly music business management platform where you can plan, release and collaborate
              on any project, all in one place.
            </Typography>
          </Stack>
        </Stack>}

      </Stack>
    </Container>
  )
}

export default LoginRoute
