/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios from 'axios'

const PATH = '/artist-intelligence' as const

export const artistIntelligenceSpecficData = (type: string, code: string) =>
  axios.post<string>(`${PATH}/analysis/${type}/${code}`)
    .then(response => response.data)

export const artistIntelligenceWelcomeMessage = () =>
  axios.post<string>(`${PATH}/welcome`)
    .then(response => response.data)
