/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded'
import { LoadingButton } from '@mui/lab'
import { Fab, IconButton, Paper, Slide, Stack, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { getProjects } from 'src/api/projects'
import NewLinkModal from 'src/components/pages/Links/LinksList/LinksListModal/NewLinkModal'
import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'
import CheckMarkItemTour from 'src/components/pages/Tour/TourComponents/CheckmarkItem'
import ExtendedTrialModal from 'src/components/pages/Tour/TourComponents/ExtendedTrialModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Project from 'src/models/Project'

const CheckmarkTour = () => {
  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)
  const [minimized, setMinimized] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  const [newLinkModal, setNewLinkModalOpen] = useState(false)

  const [latestProject, setLatestProject] = useState<Project>()

  const openModalDone = (currentOrganisation?.firstProject ?? false) &&
    (currentOrganisation?.firstCollaborator ?? false) &&
    (currentOrganisation?.firstLandingPage ?? false) &&
    (currentOrganisation?.firstRecording ?? false) &&
    (currentOrganisation?.analyticsOnboarded ?? false) &&
    (currentOrganisation?.tutorialCheckmarks ?? false)

  useEffect(() => {
    if (currentOrganisation?.firstProject) {
      void getProjects()
        .then(items => {
          setLatestProject(items.find(() => true))
        })
    }
  }, [currentOrganisation?.firstProject, location.pathname])

  return (
    <>
      {openModalDone &&
      <ExtendedTrialModal
        close={async () => {
          setLoading(true)
          await updateCurrentOrganisation({ ...currentOrganisation, tutorialCheckmarks: false })
            .then(() => setLoading(false))
            .then(refreshCurrentOrganisation)
            .finally(() => setLoading(false))
        }}
        open={openModalDone}
      />}
      {newLinkModal &&
      <NewLinkModal
        close={() => setNewLinkModalOpen(false)}
        open={newLinkModal}
      />}
      {newProjectModalOpen &&
      <NewProjectModal
        close={() => setNewProjectModalOpen(false)}
        open={newProjectModalOpen}
      />}
      {minimized &&
        <Tooltip title='Getting started'>
          <Fab
            color='primary'
            onClick={() => setMinimized(false)}
            size='medium'
            sx={{
              bottom: 8,
              position: 'fixed',
              right: 8,
              zIndex: 998,
            }}
          >
            <SchoolRoundedIcon />
          </Fab>
        </Tooltip>}
      <Slide
        direction='up'
        in={!minimized}
        style={{
          position: 'fixed',
        }}
      >
        <Paper
          elevation={6}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            borderRadius: '8px',
            zIndex: 999,
            overflow: 'hidden',
            display: currentOrganisation?.tutorialCheckmarks ? 'block' : 'none',
          }}
        >
          <Stack alignItems='flex-start' overflow='auto' width={300}>
            <Stack height={1} spacing={2} width={1}>
              <Stack
                padding={2}
                spacing={2}
                sx={{
                  backgroundColor: theme => theme.palette.primary.main,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'white',
                  },
                }}
                width={1}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='space-between'
                >
                  <Stack
                    direction='column'
                    justifyContent='center'
                  >
                    <Typography
                      sx={{
                        marginLeft: 1,
                        color: 'white',
                      }}
                      variant='h4'
                    >
                      <AutoAwesomeRoundedIcon />
                      {' '}
                      Getting started
                    </Typography>
                    {currentOrganisation?.trial &&
                    <Typography
                      sx={{
                        marginLeft: 1,
                        color: 'white',
                      }}
                      variant='body2'
                    >
                      Learning Releese is easy, get started in 5 steps
                    </Typography>}
                  </Stack>
                  <Tooltip title='Minimize'>
                    <IconButton onClick={() => setMinimized(true)} size='small'>
                      <CloseRoundedIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>

              <Stack direction='column' marginBottom='16px!important' paddingX={2} spacing={1}>
                <CheckMarkItemTour
                  action={() => setNewProjectModalOpen(true)}
                  done={currentOrganisation?.firstProject ?? false}
                  title='Create your first Release'
                />
                <CheckMarkItemTour
                  action={() => {
                    if (latestProject) {
                      navigate(`/project/${latestProject.id}?goto=home&open=collaborators`)
                    } else {
                      setNewProjectModalOpen(true)
                    }
                  }}
                  done={currentOrganisation?.firstCollaborator ?? false}
                  title='Invite a Collaborator'
                />
                <CheckMarkItemTour
                  action={() => setNewLinkModalOpen(true)}
                  done={currentOrganisation?.firstLandingPage ?? false}
                  title='Create a Smart Link'
                />
                <CheckMarkItemTour
                  action={() => {
                    if (latestProject) {
                      navigate(`/project/${latestProject.id}?goto=recordings`)
                    } else {
                      setNewProjectModalOpen(true)
                    }
                  }}
                  done={currentOrganisation?.firstRecording ?? false}
                  title='Upload a Recording'
                />
                <CheckMarkItemTour
                  action={() => navigate('/analytics/overview')}
                  done={currentOrganisation?.analyticsOnboarded ?? false}
                  title='Claim analytics profile'
                />
                <Stack alignItems='center' width={1}>
                  <LoadingButton
                    color='inherit'
                    loading={loading}
                    onClick={async () => {
                      setLoading(true)
                      await updateCurrentOrganisation({ ...currentOrganisation, tutorialCheckmarks: false })
                        .then(() => setLoading(false))
                        .then(refreshCurrentOrganisation)
                        .finally(() => setLoading(false))
                    }}
                    size='small'
                    sx={{
                      width: 'fit-content',
                    }}
                    variant='text'
                  >
                    Close tutorial
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Slide>
    </>
  )
}

export default CheckmarkTour
