/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import HelpIcon from '@mui/icons-material/Help'
import { Alert, Button, FormControl, FormControlLabel, FormLabel, Grid, Input, Radio, RadioGroup, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { baseUrlLinksApi } from 'src/api/files'
import SearchArtistComponent from 'src/components/form-elements/SearchArtists'
import SearchSong from 'src/components/form-elements/SearchSong'
import SelectField from 'src/components/form-elements/SelectField'
import type { ArtistObject, Distribution } from 'src/models/Distribution'
import type Recording from 'src/models/Recording'
import type { CoverItem } from 'src/models/Recording'

type Props = {
  recording: Recording
  onSave: (recording: Recording) => void
  saveProgress: (recording: Recording) => void
  distributing?: boolean
  setAudio: React.Dispatch<React.SetStateAction<{ file: File, id: string }[]>>
  distributionItem: Distribution
  audioSetup: { file: File, id: string }[]
}

const DistributionRecordingDetailsTab = (props: Props) => {
  const [remixKey, setRemixKey] = useState(v4())
  const [coverKey, setCoverKey] = useState(v4())
  const [recordingFields, setRecordingFields] = useState(props.recording)

  const bannedTermsTitle = ['feat.', 'ft.', 'featuring', '(', ')']

  const save = () => {
    props.onSave(recordingFields)
  }

  useEffect(() => {
    setRecordingFields(props.recording)
  }, [props.recording])

  const [fileError, setFileError] = useState(false)

  const errorTitleFeature = recordingFields.featuredArtists.some(featured =>
    recordingFields.title.toLowerCase().includes(featured.name.toLowerCase()))
  const errorTitlePrimary = recordingFields.primaryArtists.some(featured =>
    recordingFields.title.toLowerCase().includes(featured.name.toLowerCase()))
  const errorTitleBannedTerms = bannedTermsTitle.some(featured =>
    recordingFields.title.toLowerCase().includes(featured.toLowerCase()))

  const checkTitleRemixVersionErrors = recordingFields.title.toLowerCase().includes('remix') ||
    recordingFields.subTitle.toLowerCase().includes('remix')

  const checkTitleErrors = errorTitleFeature || errorTitlePrimary || errorTitleBannedTerms

  const [selectedPrimaryArtists, setSelectedPrimaryArtists] =
    useState<ArtistObject[]>(recordingFields.primaryArtists)
  const [selectedFeaturedArtists, setSelectedFeaturedArtists] =
    useState<ArtistObject[]>(recordingFields.featuredArtists)
  const [selectedRemixArtists, setSelectedRemixArtists] =
    useState<ArtistObject[]>(recordingFields.remixArtists)

  const [selectedOriginalSong, setSelectedOriginalSong] =
    useState<CoverItem | null>(recordingFields.coverItem?.name && recordingFields.coverItem.name.length > 0
      ? recordingFields.coverItem
      : null)

  const handlePrimaryChange = (values: ArtistObject[] | undefined) => {
    setSelectedPrimaryArtists(values ?? [])
    const recording = Object.assign({}, recordingFields)
    recording.primaryArtists = values ?? []
    setRecordingFields(recording)
    props.saveProgress(recording)
    props.onSave(recording)
  }

  const handleFeaturedChange = (values: ArtistObject[] | undefined) => {
    setSelectedFeaturedArtists(values ?? [])
    const recording = Object.assign({}, recordingFields)
    recording.featuredArtists = values ?? []
    setRecordingFields(recording)
    props.saveProgress(recording)
    props.onSave(recording)
  }

  const handleRemixChange = (values: ArtistObject[] | undefined) => {
    setSelectedRemixArtists(values ?? [])
    const recording = Object.assign({}, recordingFields)
    recording.remixArtists = values ?? []
    setRecordingFields(recording)
    props.saveProgress(recording)
    props.onSave(recording)
  }

  const handleCoverSongChange = (value: CoverItem | null | undefined) => {
    setSelectedOriginalSong(value ?? null)
    const recording = Object.assign({}, recordingFields)
    recording.coverItem = value ?? undefined
    setRecordingFields(recording)
    props.saveProgress(recording)
    props.onSave(recording)
  }

  const updateField = (field:
  'subTitle' | 'title',
  value: string) => {
    const recording = Object.assign({}, recordingFields)
    recording[field] = value
    setRecordingFields(recording)
    props.saveProgress(recording)
  }

  const updateTrackType = (value: 'Cover' | 'Karaoke' | 'Original' | 'Remix') => {
    const recording = Object.assign({}, recordingFields)
    recording.trackType = value
    setRemixKey(v4())
    setCoverKey(v4())
    setSelectedRemixArtists([])
    setSelectedOriginalSong(null)
    setRecordingFields({ ...recording, remixArtists: [], coverItem: undefined })
    props.saveProgress({ ...recording, remixArtists: [], coverItem: undefined })
    props.onSave({ ...recording, remixArtists: [], coverItem: undefined })
  }

  const updateRecordingVersion =
    (value: 'Advertisement' | 'Demo' | 'Instrumental' | 'Master' | 'Mixed' | 'Pre-Master' | 'Rough' | 'Trailer') => {
      const recording = Object.assign({}, recordingFields)
      recording.recordingVersion = value
      setRecordingFields(recording)
      props.saveProgress(recording)
      props.onSave(recording)
    }

  const toFindDuplicates = (array: ArtistObject[]) =>
    array.filter((item, index) => array.findIndex(object => object.name === item.name) !== index)

  const errorPrimaryArtists = selectedPrimaryArtists.find(item =>
    selectedFeaturedArtists.find(featured => featured.name === item.name))

  const errorFeaturedArtists = selectedFeaturedArtists.find(item =>
    selectedRemixArtists.find(featured => featured.name === item.name))

  const errorDuplicatePrimaryArtists = toFindDuplicates(selectedPrimaryArtists)
  const errorDuplicateFeaturedArtists = toFindDuplicates(selectedFeaturedArtists)
  const errorDuplicateRemixArtists = toFindDuplicates(selectedRemixArtists)

  const startYearRegex = /^[12]\d{3}/
  const endYearRegex = /[12]\d{3}$/
  const endsWithNumber = /\d$/
  const startsWithNumber = /^\d/
  const matchVolume = /Vol\. \d+/

  const errorTitlesRecordings = endsWithNumber.test(recordingFields.title.trim()) &&
      !endYearRegex.test(recordingFields.title.trim()) &&
      !matchVolume.test(recordingFields.title.trim())
  const recordingTitlesStartWithNumbers = startsWithNumber.test(recordingFields.title.trim()) &&
      !startYearRegex.test(recordingFields.title.trim())

  const errorTitle = errorTitlesRecordings || recordingTitlesStartWithNumbers

  const checkTitle = (!recordingFields.title ||
    recordingFields.title.length === 0) &&
    props.distributing
  const checkPrimaryArtists = (recordingFields.primaryArtists.length === 0 ||
    recordingFields.primaryArtists.length > 5) &&
    props.distributing

  const checkCover = props.distributing &&
    props.recording.trackType === 'Cover' &&
    (!selectedOriginalSong ||
    selectedOriginalSong.name.length === 0)

  const newAudioItem = props.audioSetup.find(item => item.id === props.recording.id)

  const handleAudioUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file === undefined) return

    if (file.type.startsWith('audio')) {
      if (!(/\.(wav)$/i).test(file.name)) {
        setFileError(true)
      } else {
        props.setAudio(old => old.some(item => item.id === props.recording.id)
          ? old.map(item => item.id === props.recording.id
            ? { file, id: item.id }
            : item)
          : [...old, { file, id: props.recording.id }])
      }
    } else {
      props.setAudio(old => old.filter(item => item.id !== props.recording.id))
    }
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='File Error'
        onClose={() => setFileError(false)}
        open={fileError}
      >
        <Alert
          onClose={() => setFileError(false)}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Invalid file format
        </Alert>
      </Snackbar>
      <form
        encType='multipart/form-data'
        onSubmit={event => {
          event.preventDefault()
          save()
        }}
      >
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              error={checkTitleErrors || errorTitle || checkTitle || checkTitleRemixVersionErrors}
              fullWidth
              helperText={
                <Tooltip title='Do not include any version, featured artist or metadata in the title, these are
            automatically generated by our systems'
                >
                  <Stack alignContent='center' direction='row' >
                    <HelpIcon color='info' sx={{ marginY: 'auto', marginRight: 0.5 }} />
                    <Typography color='info.main' sx={{ marginY: 'auto' }} variant='helperText'>
                      Read our Title Guidelines
                    </Typography>
                  </Stack>
                </Tooltip>
              }
              label='Track Title'
              onBlur={() => save()}
              onChange={event => updateField('title', event.target.value)}
              value={recordingFields.title}
            />
            <Stack>
              {checkTitleErrors &&
              <Typography color='error' variant='body2'>
                Do not include any versions or artist names in the title
              </Typography>}
              {errorTitle &&
              <Typography color='error' variant='body2'>
                Recording titles cannot start or end with numbers
              </Typography>}
              {checkTitleRemixVersionErrors &&
              <Typography color='error' variant='body2'>
                Do not include remix fields in the title or subtitle field
              </Typography>}
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              autoComplete='new-password'
              error={checkTitleRemixVersionErrors}
              fullWidth
              helperText='Such as "Extended Mix", "Instrumental", "Acoustic"'
              label='Version/Subtitle'
              onBlur={() => save()}
              onChange={event => updateField('subTitle', event.target.value)}
              placeholder='25th Anniversary Edition'
              value={recordingFields.subTitle}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <SearchArtistComponent
              error={!!errorPrimaryArtists ||
                errorDuplicatePrimaryArtists.length > 0 ||
                !!checkPrimaryArtists}
              label='Primary Artists'
              placeholder='Search Artists'
              selectedArtists={selectedPrimaryArtists}
              setSelectedArtists={handlePrimaryChange}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <SearchArtistComponent
              error={!!errorPrimaryArtists ||
                errorDuplicateFeaturedArtists.length > 0 ||
                !!errorFeaturedArtists}
              label='Featured Artists'
              placeholder='Search Artists'
              selectedArtists={selectedFeaturedArtists}
              setSelectedArtists={handleFeaturedChange}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <SelectField
              fullWidth
              id='recording-version'
              label='Recording Version'
              onChange={event =>
                updateRecordingVersion(event as
                'Advertisement' | 'Demo' | 'Instrumental' | 'Master' | 'Mixed' | 'Pre-Master' | 'Rough' | 'Trailer')}
              options={[
                { label: 'Demo', value: 'Demo' },
                { label: 'Mixed', value: 'Mixed' },
                { label: 'Pre-Master', value: 'Pre-Master' },
                { label: 'Master', value: 'Master' },
                { label: 'Rough', value: 'Rough' },
                { label: 'Instrumental', value: 'Instrumental' },
                { label: 'Trailer', value: 'Trailer' },
                { label: 'Advertisement', value: 'Advertisement' },
              ]}
              value={recordingFields.recordingVersion}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            {recordingFields.trackType === 'Remix' &&
            <SearchArtistComponent
              error={!!errorPrimaryArtists || errorDuplicateRemixArtists.length > 0}
              key={remixKey}
              label='Remix Artists'
              placeholder='Search Artists'
              selectedArtists={selectedRemixArtists}
              setSelectedArtists={handleRemixChange}
            />}
            {recordingFields.trackType === 'Cover' &&
            <SearchSong
              error={checkCover}
              key={coverKey}
              label='Original Song'
              placeholder='Search Songs'
              selectedSong={selectedOriginalSong}
              setSelectedSong={handleCoverSongChange}
            />}
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <FormControl component='fieldset' sx={{ marginTop: 0 }}>
              <FormLabel
                sx={{ fontSize: theme => theme.typography.textLabel }}
              >
                Track Type
              </FormLabel>
              <RadioGroup
                aria-label='track type'
                name='track-type-radio-buttons-group'
                onChange={event => updateTrackType(event.target.value as 'Cover' | 'Karaoke' | 'Original' | 'Remix')}
                value={recordingFields.trackType}
              >
                <FormControlLabel
                  control={<Radio
                    size='small'
                    value='Original'
                  />}
                  label='Original'
                  value='Original'
                />
                <FormControlLabel
                  control={<Radio
                    size='small'
                    value='Cover'
                  />}
                  label='Cover'
                  value='Cover'
                />
                <FormControlLabel
                  control={<Radio
                    size='small'
                    value='Remix'
                  />}
                  label='Remix'
                  value='Remix'
                />
                <FormControlLabel
                  control={<Radio
                    size='small'
                    value='Karaoke'
                  />}
                  label='Karaoke'
                  value='Karaoke'
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack direction='column' spacing={1}>
              <FormLabel
                sx={{ fontSize: theme => theme.typography.textLabel }}
              >
                Actions
              </FormLabel>
              {newAudioItem &&
              <Typography variant='body1'>
                {newAudioItem.file.name}
              </Typography>}
              {props.distributionItem.status === 'Changes requested' &&
              <label htmlFor={`audio-button-file-${props.recording.id}`} >
                <Button
                  component='span'
                  endIcon={<CloudUploadRoundedIcon />}
                  fullWidth
                  variant='outlined'
                >
                  Replace audio
                </Button>
                <Input
                  id={`audio-button-file-${props.recording.id}`}
                  name='audio'
                  onChange={handleAudioUpload}
                  sx={{ display: 'none', zIndex: '100000' }}
                  type='file'
                />
              </label>}
              <Button
                endIcon={<CloudDownloadRoundedIcon />}
                fullWidth
                href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(props.recording.audioFileUrl)}`}
                target='_blank'
              >
                Download audio
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
export default DistributionRecordingDetailsTab
