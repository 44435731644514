import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import stripeTaxUtils from 'stripe-tax-utils'

import { deleteTaxId } from 'src/api/tax'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import type { TaxId } from 'src/models/Payment'

type Props = {
  close: () => void
  getTaxCodes: () => Promise<void>
  open: boolean
  taxId: TaxId
}

const DeleteTaxIdModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [loading, setLoading] = useState(false)

  const removeTaxCode = async () => {
    setLoading(true)
    await deleteTaxId(props.taxId.id)
      .then(() => void props.getTaxCodes())
      .then(() => setLoading(false))
      .finally(() => props.close())
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Delete Tax Id
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <DeleteItemComponent
          primary={`Remove your ${stripeTaxUtils.getMap().find(item => item.type === props.taxId.type)?.description}?`}
          secondary='This action cannot be undone'
        />

        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            loading={loading}
            onClick={removeTaxCode}
            variant='contained'
          >
            Delete Tax ID
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DeleteTaxIdModal
