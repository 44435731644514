import type Recording from 'src/models/Recording'

export const DISTRIBUTION_STATUS = [
  'Under review',
  'Distributed',
  'Changes requested',
  'Taken Down',
] as const

export type Territory = {
  iso: string
  name: string
}

export type ModerationRequest = {
  id: string
  message: string
  from: 'moderation' | 'team' | 'user'
  attachments: string[]
  timestamp: Date
}

export type ReleeseServices = {
  product: string
  quantity: number
  recording?: Recording
  platform?: string
  platformFrom?: string
  platformTo?: string
}

export type Platform = {
  name: string
}

export type ArtistToUpdate = {
  artistName: string
  profile:
  {
    name: string
    link: string
  }[]
}

export type SingleWriter = {
  writer: string
  ipi: string
  pro: string
  percent: number | undefined
}

export type AtmosSubmission = {
  recordingId: string
  recordingTitle: string
  atmosFileLink: string
}

export type WriterInformation = {
  recordingId: string
  recordingTitle: string
  composerInfo: SingleWriter[]
}

export class Distribution {
  id?: string
  title = ''
  primaryArtists: ArtistObject[] = []
  featuredArtists?: ArtistObject[]
  genre = ''
  subGenres: string[] = []
  labelName = ''
  releaseDate = new Date()
  cLine = ''
  pLine = ''
  catalogNumber = ''
  UPC = ''

  beatportGenre = ''

  artwork?: File
  artworkUrl?: string
  audio?: File[]

  territories: Territory[] = []
  platforms: Platform[] = []

  recordings: Recording[] = []
  projectId?: string
  status: typeof DISTRIBUTION_STATUS[number] = DISTRIBUTION_STATUS[0]

  writerInfo: WriterInformation[] = []
  publishing = false
  neighboring = false

  atmos = false
  atmosFiles: AtmosSubmission[] = []
  appleDigitalMasters = false
  appleDigitalMasterEmail?: string
  appleMotion = false
  appleMotionFolderLink?: string
  priority = false
  moderation: ModerationRequest[] = []

  constructor(dto: Partial<Distribution>) {
    Object.assign(this, dto)
    this.title = dto.title ?? ''
    this.releaseDate = new Date(dto.releaseDate ?? 0)
  }
}

export type DistributionDto = Partial<Distribution>

export class ArtistObject {
  id?: string
  name = ''
  followers?: {
    total: 0
  }
  uri = ''
  images?: [{
    url: string
  }]

  constructor(dto: Partial<ArtistObject>) {
    Object.assign(this, dto)
  }
}

export type ArtistObjectDto = Partial<ArtistObject>

export class SearchObject {
  id?: string
  type: 'Artist' | 'Track' = 'Artist'
  name = ''
  followers?: {
    total: 0
  }
  artists?: string[]
  spotifyId = ''
  images?: [{
    url: string
  }]
  isrc?: string

  constructor(dto: Partial<SearchObject>) {
    Object.assign(this, dto)
  }
}

export type SearchObjectDto = Partial<SearchObject>
