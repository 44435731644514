/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react/no-danger */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import ForumRoundedIcon from '@mui/icons-material/ForumRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Avatar, Divider, Paper, Stack, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'

import { createNewConversation, getConversation } from 'src/api/conversations'
import { getDistributionByProjectId } from 'src/api/distribution'
import { getPitchByProjectId } from 'src/api/pitch'
import { getProject, savePartialProject } from 'src/api/projects'
import { getRecordingsByProjectId } from 'src/api/recordings'
import Loading from 'src/components/Loading'
import NotesInitialValue from 'src/components/pages/Notes/Modern/NotesValueInitial'
import PageNotFound from 'src/components/pages/PageNotFound'
import DistributeModal from 'src/components/pages/Projects/DistributeModal/DistributeModal'
import DistributeModalFree from 'src/components/pages/Projects/DistributeModal/DistributeModalFree'
import { DropzoneProvider } from 'src/components/pages/Projects/FullscreenDropzone'
import PitchModal from 'src/components/pages/Projects/PitchModal/PitchModal'
import ViewPitchModal from 'src/components/pages/Projects/PitchModal/ViewPitchModal'
import ViewProjectDetails from 'src/components/pages/Projects/ViewProject/ViewProjectDetails'
import DetailsTab from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DetailsTab'
import ViewProjectAnalytics from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectAnalytics'
import ViewProjectDistribution from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectDistribution'
import ViewProjectAttachmentsTab from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectFiles'
import ViewProjectFinances from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectFinances'
import ViewProjectMarketing from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectMarketing'
import ViewProjectMessages from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectMessages'
import ViewProjectRecordings from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectRecordings'
import ViewProjectTasks from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectTasks'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'
import FailedIframeView from 'src/components/pages/Task/TaskViews/TaskViewComponents/FailIframe'
import ReleaseCollaboratorsTour from 'src/components/pages/Tour/Projects/TourCollaborators'
import TourDistribute from 'src/components/pages/Tour/Projects/TourDistribute'
import ViewReleaseTour from 'src/components/pages/Tour/Projects/ViewRelease'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Conversation } from 'src/models/Conversation'
import type { Distribution } from 'src/models/Distribution'
import { Permissions } from 'src/models/Organisation'
import type { Pitch } from 'src/models/Pitch'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import SPACING from 'src/styles/spacing'

type Props = {
  readonly refresh: React.Dispatch<React.SetStateAction<boolean>>
}

const ViewProject = (props: Props) => {
  const themeColors = useTheme()
  const navigate = useNavigate()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const { id } = useParams<{ id: string }>()
  const {
    currentOrganisation,
    currentAccountPermissions,
  } = useAuth()

  const [currentTab, setCurrentTab] = useState('8')
  const [project, setProject] = useState<Project | undefined>()
  const [loading, setLoading] = useState(false)
  const [distributeModalOpen, setDistributeModalOpen] = useState(false)
  const [error, setError] = useState(false)
  const [recordings, setRecordings] = useState<Recording[]>([])
  const [newRecordings, setNewRecordings] = useState<File[]>([])
  const [distributionItem, setDistributionItem] = useState<Distribution>()
  const [pitchItem, setPitchItem] = useState<Pitch>()
  const [updateboard, setUpdateBoard] = useState(false)
  const search = useLocation().search
  const goto = new URLSearchParams(search).get('goto')
  const [shareModal, setShareModal] = useState(false)
  const [pitchModal, setPitchModal] = useState(false)
  const [conversation, setConversation] = useState<Conversation>()
  const [loadcreateConvo, setloadCreateConvo] = useState(false)

  const collaboratorItem = project?.collaborators.find(item => item.account.id === currentOrganisation?.id)

  useEffect(() => {
    const message = 'Your recordings are not finished uploading, are you sure you want to leave?'
    function listener(event: BeforeUnloadEvent) {
      if (newRecordings.length > 0) {
        event.preventDefault()
        event.returnValue = message
        return message
      }
    }

    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [newRecordings.length])

  const getConvo = async (projectItem: Project) => {
    if (projectItem.conversationId && projectItem.conversationId.length > 0) {
      await getConversation(projectItem.conversationId)
        .then(setConversation)
    } else if (currentOrganisation && !loadcreateConvo) {
      setloadCreateConvo(true)
      const conversationResponse = await createNewConversation({
        selectedParticipants: [currentOrganisation.id, ...projectItem.collaborators.map(item => item.account.id)],
        type: 'Project',
        selectedProject: projectItem.id,
        selectedAccounts: [],
        id: v4(),
      })
      await savePartialProject({
        ...projectItem,
        conversationId: conversationResponse.id,
      }).then(projectResponse => {
        setProject(projectResponse)
        setConversation(conversationResponse)
      })
        .then(() => {
          setloadCreateConvo(false)
        })
    }
  }

  useEffect(() => {
    if (project) {
      void getConvo(project)
    }
  }, [project])

  useEffect(() => {
    setNewRecordings([])
  }, [id])

  useEffect(() => {
    switch (goto) {
      case 'task':
        setCurrentTab('1')
        break
      case 'messages':
        setCurrentTab('11')
        break
      case 'recordings':
        setCurrentTab('2')
        break
      case 'files':
        setCurrentTab('3')
        break
      case 'marketing':
        setCurrentTab('5')
        break
      case 'distribution':
        setCurrentTab('9')
        break
      case 'assets':
        setShareModal(true)
        break
      case 'pitch':
        setPitchModal(true)
        break
      default:
        setCurrentTab('8')
    }
  }, [goto])

  useEffect(() => {
    updateProjectFunction()
  }, [id, project?.id, currentOrganisation?.id])

  const updateProjectFunction = () => {
    if (id) {
      setLoading(true)
      void getProject(id)
        .then(data => {
          setProject(data)
          if (data.distributed) {
            void getDistributionByProjectId(data.id)
              .then(setDistributionItem)
          }
          if (data.pitched) {
            void getPitchByProjectId(data.id)
              .then(setPitchItem)
          }
          setError(false)
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (error) {
      navigate('/projects/projects')
    }
  }, [error])

  useEffect(() => {
    setLoading(true)
    void updateRecordings()
      .finally(() => setLoading(false))
  }, [id, project?.id, currentOrganisation?.id])

  const updateDistribution = () => {
    if (project?.id) {
      void getDistributionByProjectId(project.id)
        .then(setDistributionItem)
    }
  }

  const updateRecordingsSubtle = async () => {
    if (id && project) {
      await getRecordingsByProjectId(id)
        .then(recordingItems => {
          setRecordings(recordingItems.sort((a, b) =>
            project.recordings.indexOf(a.id) - project.recordings.indexOf(b.id)))
        })
        .finally(() => setLoading(false))
    }
  }

  const updateRecordings = async () => {
    if (id && project) {
      await getRecordingsByProjectId(id)
        .then(recordingItems => {
          setRecordings(recordingItems.sort((a, b) =>
            project.recordings.indexOf(a.id) - project.recordings.indexOf(b.id)))
        })
        .finally(() => setLoading(false))
    }
  }

  const saveProjectReorder = async (recordingItems: Recording[]) => {
    const projectTemporary = project
    if (projectTemporary) {
      await savePartialProject({
        ...projectTemporary,
        id: projectTemporary.id,
        recordings: recordingItems.map(item => item.id),
      }).then(proj => setProject(old => old
        ? { ...old, recordings: proj.recordings }
        : proj))
        .catch(() => null)
    }
  }

  const hasRecordings = recordings.length > 0
  const splitsLocked = recordings.length > 0 &&
    recordings.every(item =>
      item.masterRightsHolders.every(holder =>
        holder.status === 'Locked')) &&
    recordings.every(item =>
      item.masterRightsHolders.length > 0)
  const artworkSet = project && project.projectArtworkUrl.length > 0
  const albumMetadataSet = project &&
    project.title.length > 0 &&
    project.genre.length > 0 &&
    project.subGenres.length > 0 &&
    project.cLine.length > 0 &&
    project.recordLabel.length > 0 &&
    project.pLine.length > 0

  const progress =
    (hasRecordings ? 1 : 0) +
    (splitsLocked ? 1 : 0) +
    (artworkSet ? 1 : 0) +
    (albumMetadataSet ? 1 : 0)

  return (
    <Stack
      sx={{
        paddingBottom: SPACING.RESPONSIVE_CONTOUR,
        paddingRight: SPACING.RESPONSIVE_CONTOUR,
        height: 1,
        width: 1,
      }}
    >
      {progress === 4 && project?.organisation?.id === currentOrganisation?.id && project &&
      <TourDistribute />}
      {project?.distributed && project.organisation?.id === currentOrganisation?.id &&
      <ReleaseCollaboratorsTour />}
      {project &&
      <ViewReleaseTour />}
      <DropzoneProvider>
        <Paper
          elevation={0}
          sx={{
            height: 1,
            overflow: 'hidden',
            width: 1,
            borderRadius: {
              xl: 1,
              lg: 1,
              md: 1,
              sm: 0,
              xs: 0,
            },
          }}
        >
          {!error
            ? <Stack direction='row' height={1} width={1}>
              {!matches &&
              <ViewProjectDetails
                currentTab={currentTab}
                distributionItem={distributionItem}
                pitch={pitchItem}
                project={project}
                setCurrentTab={setCurrentTab}
                setDistributeModalOpen={() => setDistributeModalOpen(true)}
                setPitchItem={setPitchItem}
                setProject={setProject}
                setUpdateBoard={setUpdateBoard}
                updateDistribution={updateDistribution}
              />}
              {!matches &&
              <Divider orientation='vertical' sx={{ height: 1 }} />}
              <Stack
                direction='column'
                width={matches
                  ? 1
                  : 'calc(100% - 280px)'}
              >
                {matches &&
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='flex-start'
                  padding={2}
                  spacing={2}
                >
                  <Avatar
                    src={project?.artworkPreviewUrl}
                    sx={{
                      width: 64,
                      height: 64,
                      borderRadius: '8px',
                      background: theme => theme.palette.background.input,
                    }}
                  >
                    <MusicNoteRoundedIcon
                      sx={{
                        fontSize: 64,
                        color: theme => theme.palette.action.disabled,
                      }}
                    />
                  </Avatar>
                  <Stack width={1}>
                    <Typography lineHeight={1.2} variant='h2'>
                      {project?.title}
                    </Typography>
                    <Typography color='textLabel' variant='body1'>
                      Your Release
                    </Typography>
                  </Stack>
                </Stack>}
                <TabContext
                  value={currentTab}
                >
                  {matches &&
                  <>
                    <Stack alignItems='center' direction='row' spacing={2} width={1}>
                      <TabList
                        onChange={(event, value: string) => setCurrentTab(value)}
                        sx={{
                          minHeight: 32,
                          width: 1,
                          '.MuiTabs-scroller': {
                            paddingLeft: 2,
                          },
                        }}
                        variant='scrollable'
                      >
                        <Tab
                          icon={<LibraryMusicRoundedIcon />}
                          label='Overview'
                          sx={{
                            minHeight: 32,
                          }}
                          value='8'
                        />
                        <Tab
                          icon={<MusicNoteRoundedIcon />}
                          id='nav-release'
                          label='Recordings'
                          sx={{
                            minHeight: 32,
                          }}
                          value='2'
                        />
                        <Tab
                          icon={<TaskAltRoundedIcon />}
                          label='Tasks'
                          sx={{
                            minHeight: 32,
                          }}
                          value='1'
                        />
                        <Tab
                          icon={<ForumRoundedIcon />}
                          label='Messages'
                          sx={{
                            minHeight: 32,
                          }}
                          value='11'
                        />
                        <Tab
                          icon={<FolderRoundedIcon />}
                          label='Files'
                          sx={{
                            minHeight: 32,
                          }}
                          value='3'
                        />
                        <Tab
                          icon={<PhonelinkRoundedIcon />}
                          label='Smart Links'
                          sx={{
                            minHeight: 32,
                          }}
                          value='5'
                        />
                        <Tab
                          disabled={!currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES) ||
                            (!project?.distributed && currentOrganisation?.id !== project?.organisation?.id) ||
                            collaboratorItem?.restrictions.includes('Distribution')}
                          icon={<PublishRoundedIcon />}
                          id='distribute-button'
                          label='Distribution'
                          sx={{
                            minHeight: 32,
                          }}
                          value='9'
                        />
                        <Tab
                          disabled={!currentAccountPermissions?.includes(Permissions.VIEW_EARNINGS) ||
                          collaboratorItem?.restrictions.includes('Earnings')}
                          icon={<AttachMoneyRoundedIcon />}
                          label='Finances'
                          sx={{
                            minHeight: 32,
                          }}
                          value='7'
                        />
                        <Tab
                          disabled={!currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) ||
                          (!project?.distributed && !project?.organisation?.trustedAnalytics) ||
                          project.releaseDate > new Date() ||
                          collaboratorItem?.restrictions.includes('Analytics')}
                          icon={<AnalyticsRoundedIcon />}
                          label='Analytics'
                          sx={{
                            minHeight: 32,
                          }}
                          value='6'
                        />
                      </TabList>
                    </Stack>
                    <Divider />
                  </>}
                  <Stack
                    height={1}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'auto',
                    }}
                    width={1}
                  >
                    {loading
                      ? <Loading />
                      : <>
                        {currentAccountPermissions?.includes(Permissions.VIEW_TASKBOARDS) &&
                        <TabPanel sx={{ width: 1, height: 1, padding: 0 }} value='1'>
                          <ViewProjectTasks
                            project={project}
                            updateboard={updateboard}
                          />
                        </TabPanel>}
                        <Stack
                          sx={{
                            width: 1,
                            height: 1,
                            padding: 0,
                            display: currentTab === '2' ? 'flex' : 'none',
                          }}
                        >
                          {project &&
                          <ViewProjectRecordings
                            currentTab={currentTab}
                            newRecordings={newRecordings}
                            project={project}
                            recordings={recordings}
                            refresh={props.refresh}
                            saveProjectReorder={saveProjectReorder}
                            setNewRecordings={setNewRecordings}
                            setRecordings={setRecordings}
                            updateRecordings={updateRecordings}
                            updateRecordingsSubtle={updateRecordingsSubtle}
                          />}
                        </Stack>
                        <Stack
                          sx={{
                            width: 1,
                            height: 1,
                            padding: 0,
                            display: currentTab === '3' ? 'flex' : 'none',
                          }}
                        >
                          {currentTab === '3' &&
                          <ViewProjectAttachmentsTab
                            project={project}
                          />}
                        </Stack>
                        {currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS) &&
                        <TabPanel sx={{ width: 1, height: 1, padding: 0 }} value='5'>
                          <ViewProjectMarketing
                            project={project}
                          />
                        </TabPanel>}
                        {currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) &&
                        (project?.distributed || project?.organisation?.trustedAnalytics) &&
                        <TabPanel sx={{ width: 1, height: 1, padding: 0 }} value='6'>
                          <ViewProjectAnalytics
                            project={project}
                          />
                        </TabPanel>}
                        <TabPanel sx={{ width: 1, height: 1, padding: 0 }} value='8'>
                          <DetailsTab
                            distribution={distributionItem}
                            mobile
                            pitch={pitchItem}
                            project={project}
                            recordings={recordings}
                            setCurrentTab={setCurrentTab}
                            setDistributeModalOpen={setDistributeModalOpen}
                            setPitchItem={setPitchItem}
                            setProject={setProject}
                            setUpdateBoard={setUpdateBoard}
                          />
                        </TabPanel>
                        <TabPanel sx={{ width: 1, height: 1, padding: 0 }} value='9'>
                          {project &&
                          currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES) &&
                          <ViewProjectDistribution
                            distributionItem={distributionItem}
                            project={project}
                            setProject={setProject}
                            updateProjectFunction={updateProjectFunction}
                            updateRecordingsSubtle={updateRecordingsSubtle}
                          />}
                        </TabPanel>
                        <TabPanel sx={{ width: 1, height: 1, padding: 0 }} value='7'>
                          {currentAccountPermissions?.includes(Permissions.VIEW_EARNINGS) &&
                          <ViewProjectFinances
                            project={project}
                          />}
                        </TabPanel>
                        <TabPanel sx={{ width: 1, height: 1, padding: 0 }} value='11'>
                          {project && conversation &&
                          <ViewProjectMessages
                            conversation={conversation}
                            currentTab={currentTab}
                          />}
                        </TabPanel>

                        {project?.embeddedItems.map(embed =>
                          <TabPanel key={embed.id} sx={{ height: 1, width: 1, padding: 0 }} value={embed.id}>
                            {embed.embedType === 'Releese Doc'
                              ? currentTab === embed.id &&
                              <NotesInitialValue
                                slug={embed.content}
                              />
                              :  embed.content.startsWith('<iframe')
                                ? <div
                                  className='projectIframe'
                                  dangerouslySetInnerHTML={{ __html: embed.content }}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                  }}
                                />
                                : embed.content.startsWith('https://')
                                  ? <div
                                    className='projectIframe'
                                    dangerouslySetInnerHTML={{
                                      __html:
                  `<iframe src=${embed.content}></iframe>`,
                                    }}
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  />
                                  : <FailedIframeView />}
                          </TabPanel>)}
                      </>}
                  </Stack>
                </TabContext>
              </Stack>
            </Stack>
            : <Stack
              alignItems='flex-start'
              direction='row'
              height={1}
              width={1}
            >
              <PageNotFound />
            </Stack>}
          {distributeModalOpen &&
          currentOrganisation?.membershipTier === 'FREE' &&
          currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES) &&
          <DistributeModalFree
            close={() => setDistributeModalOpen(false)}
            open={distributeModalOpen}
          />}
          {distributeModalOpen &&
          project &&
          currentOrganisation?.membershipTier !== 'FREE' &&
          currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES) &&
          <DistributeModal
            close={() => {
              updateProjectFunction()
              void updateRecordingsSubtle()
              setDistributeModalOpen(false)
            }}
            open={distributeModalOpen}
            project={project}
            setProject={setProject}
            updateRecordingsSubtle={updateRecordingsSubtle}
          />}
          {project &&
          <SocialAssetModal
            close={() => setShareModal(false)}
            imageMainUrl={project.artworkPreviewUrl}
            imageSecondaryUrl=''
            open={shareModal}
            platform='Spotify'
            project={project}
            text='Out soon'
          />}
          {pitchItem && project &&
          <ViewPitchModal
            close={() => setPitchModal(false)}
            open={pitchModal}
            pitch={pitchItem}
            project={project}
          />}
          {project && !pitchItem &&
          <PitchModal
            close={() => setPitchModal(false)}
            open={pitchModal}
            project={project}
            setPitchItem={setPitchItem}
          />}
        </Paper>
      </DropzoneProvider>
    </Stack>
  )
}

export default ViewProject
