/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import Account from 'src/models/Account'
import type { SocialList } from 'src/models/Marketing'
import type { Serialized } from 'src/types/react-app-env'

export const PRICE_LOOKUP_KEYS = [
  'FREE',
  'STARTER',
  'CREATOR',
  'CREATOR_PRO',
  'ENTERPRISE',
  'STARTER_YEARLY',
  'CREATOR_YEARLY',
  'CREATOR_PRO_YEARLY',
  'ENTERPRISE_YEARLY',
  'NEW_CREATOR',
  'NEW_PRO',
  'NEW_BUSINESS',
  'NEW_BUSINESS_NOV_24',
  'NEW_CREATOR_YEARLY',
  'NEW_PRO_YEARLY',
  'NEW_BUSINESS_YEARLY',
  'NEW_BUSINESS_NOV_24_YEARLY',
  'JAN_25_STARTER',
  'JAN_25_STARTER_YEARLY',
  'JAN_25_ESSENTIAL',
  'JAN_25_ESSENTIAL_YEARLY',
  'JAN_25_PRO',
  'JAN_25_PRO_YEARLY',
] as const
export type PriceLookupKey = typeof PRICE_LOOKUP_KEYS[number]

export const PRICE_LOOKUP_KEYS_PRETTY = [
  'Free',
  'Starter',
  'Creator (Legacy)',
  'Creator Pro (Legacy)',
  'Enterprise (Legacy)',
  'Starter, Annual',
  'Creator, Annual (Legacy)',
  'Creator Pro, Annual (Legacy)',
  'Enterprise, Annual (Legacy)',
  'Essential (Legacy)',
  'Pro (Legacy)',
  'Business (Legacy)',
  'Business',
  'Essential, Annual (Legacy)',
  'Pro, Annual (Legacy)',
  'Business, Annual (Legacy)',
  'Business, Annual',
  'Starter',
  'Starter, Annual',
  'Essential',
  'Essential, Annual',
  'Pro',
  'Pro, Annual',
] as const
export type PriceLookupKeyPretty = typeof PRICE_LOOKUP_KEYS_PRETTY[number]

export type Links = {
  spotify: string
  soundCloud: string
  twitter: string
  appleMusic: string
  deezer: string
}

export type FavoriteArtist = {
  spotifyId: string
  name: string
  imageUrl: string
}

export type PersonaTypes = {
  distribution: boolean
  gainFans: boolean
  trackAudience: boolean
  organize: boolean
  splits: boolean
}

export type OnboardingItems = {
  setup: boolean
  releaseCreate: boolean
  releaseView: boolean
  releaseCollaborators: boolean
  releaseDistribute: boolean
  recordingMetadata: boolean
  recordingSplit: boolean
  filesShare: boolean
  scheduleSync: boolean
  taskAi: boolean
}

export type Discount = {
  id: string
  start: number
  end: number
  coupon: {
    name: string
    percent_off: number
    amount_off: number
  }
}

export enum Permissions {
  CREATE_EDIT_REMOVE_PROJECTS,
  VIEW_PROJECTS,
  CREATE_EDIT_REMOVE_TASKBOARDS,
  VIEW_TASKBOARDS,
  CREATE_EDIT_REMOVE_MARKETING_LINKS,
  VIEW_MARKETING_LINKS,
  DISTRIBUTE_RELEASES,
  VIEW_EARNINGS,
  VIEW_ANALYTICS,
  MANAGE_PAYOUTS,
  CREATE_EDIT_REMOVE_RIGHTS,
  VIEW_RIGHTS,
  EDIT_ORGANISATION_SETTINGS,
  ADD_REMOVE_USERS,
  MANAGE_BILLING,
  MANAGE_SPLITS,
}

export class BasicOrganisation {
  name = ''
  logoUrl = ''
  defaultSocialList: SocialList[] = []
  id = ''

  constructor(dto: OrganisationDto) {
    Object.assign(this, dto)
  }
}

export type BasicOrganisationDto = Partial<Serialized<BasicOrganisation>>

export class Organisation {
  _id = ''
  id = ''
  name = ''
  logoUrl = ''
  logo?: File
  bannerUrl = ''
  banner?: File
  links: Links
  location = ''
  bio: string
  dateOfJoining = new Date()

  membershipTier: PriceLookupKey
  stripeCancelled: boolean
  endOfSubscription: number

  seatAllocation = 0
  storageAllocation = 0
  storageUsed = 0
  seats: Seat[]
  organisationRoles: Role[] = []

  orgType: string

  trial = false
  trialEnd = new Date()

  verified = false
  verificationSubmitted = false

  business = false
  businessName = ''
  firstName = ''
  lastName = ''
  organisationEmail = ''
  addressLine1 = ''
  addressLine2 = ''
  country = ''
  region = ''
  city = ''
  postalCode = ''

  performingRightsOrganization = ''
  ipiNumber = ''
  hasPublisher = false
  publisherName = ''
  publisherPerformingRightsOrganization = ''
  publisherIpiNumber = ''

  neighboringCloudStatus = 'Not enrolled'
  publishingCloudStatus = 'Not enrolled'

  neighboringCloudEnd = new Date()
  publishingCloudEnd = new Date()

  customDomain = ''
  customDomainSubmitted = false

  conversionApiAccessToken = ''
  conversionApiPixelId = ''

  tiktokEventsApiAccessToken = ''
  tiktokEventsApiPixelId = ''

  defaultFbTag = ''
  defaultGTMTag = ''
  defaultSnapchatTag = ''
  defaultTikTokTag = ''

  tutorialOnboarding: boolean
  tutorialCheckmarks: boolean
  tutorialClickedCheckmark: boolean
  tutorialProjects: boolean
  tutorialMarketing: boolean
  tutorialTasks: boolean

  firstProject: boolean
  firstCollaborator: boolean
  firstLandingPage: boolean
  firstRecording: boolean
  firstTaskboard: boolean
  extendedTrial: boolean

  trustedAnalytics: boolean

  analyticsOnboarded: boolean
  analyticsType: 'artist' | 'label' | undefined
  analyticsArtistId?: string
  analyticsLabelId?: string

  stripeCustomerId?: string

  balance = 0
  trolleyId?: string

  defaultSocialList: SocialList[] = []
  beatportLabelString?: string
  beatportSubmitted: boolean
  neverTrial: boolean

  acceptedRules: boolean

  onboarding: OnboardingItems
  trialStart: Date
  extendOfferClaimed: boolean

  discounts: Discount[]

  ga?: string
  fbp?: string
  fbc?: string
  firstPage?: string

  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_content?: string
  utm_term?: string

  priority: boolean
  analyticsExtension: boolean
  favoriteArtistProfiles: FavoriteArtist[]

  persona_features: PersonaTypes
  importingContent: boolean
  feedbackPricing: boolean

  constructor(dto: OrganisationDto | null | undefined) {
    dto = dto ?? {}
    Object.assign(this, dto)
    this.seatAllocation = dto.seatAllocation ?? Number.POSITIVE_INFINITY
    this.seats = (dto.seats ?? []).map(seat => new Seat(seat))
    this.organisationRoles = (dto.organisationRoles ?? []).map(role => new Role(role))
    this.bio = dto.bio ?? ''
    this.membershipTier = dto.membershipTier ?? 'FREE'
    this.stripeCancelled = dto.stripeCancelled ?? false
    this.verificationSubmitted = dto.verificationSubmitted ?? false
    this.endOfSubscription = dto.endOfSubscription ?? 0
    this.trial = dto.trial ?? false
    this.orgType = dto.orgType ?? ''
    this.trialEnd = new Date(dto.trialEnd ?? 0)
    this.neighboringCloudEnd = new Date(dto.neighboringCloudEnd ?? 0)
    this.publishingCloudEnd = new Date(dto.publishingCloudEnd ?? 0)
    this.tutorialOnboarding = dto.tutorialOnboarding ?? false
    this.tutorialCheckmarks = dto.tutorialCheckmarks ?? false
    this.tutorialClickedCheckmark = dto.tutorialClickedCheckmark ?? false
    this.tutorialProjects = dto.tutorialProjects ?? false
    this.tutorialMarketing = dto.tutorialMarketing ?? false
    this.tutorialTasks = dto.tutorialTasks ?? false
    this.firstProject = dto.firstProject ?? false
    this.firstCollaborator = dto.firstCollaborator ?? false
    this.firstLandingPage = dto.firstLandingPage ?? false
    this.firstRecording = dto.firstRecording ?? false
    this.firstTaskboard = dto.firstTaskboard ?? false
    this.trustedAnalytics = dto.trustedAnalytics ?? false
    this.analyticsOnboarded = dto.analyticsOnboarded ?? false
    this.analyticsType = dto.analyticsType as 'artist' | 'label' | undefined
    this.analyticsArtistId = dto.analyticsArtistId
    this.analyticsLabelId = dto.analyticsLabelId
    this.extendedTrial = dto.extendedTrial ?? false
    this.trolleyId = dto.trolleyId
    this.beatportLabelString = dto.beatportLabelString
    this.beatportSubmitted = dto.beatportSubmitted ?? false
    this.neverTrial = dto.neverTrial ?? false
    this.acceptedRules = dto.acceptedRules ?? true
    this.ga = dto.ga
    this.fbp = dto.fbp
    this.fbc = dto.fbc
    this.firstPage = dto.firstPage
    this.trialStart = new Date(dto.trialStart ?? 0)
    this.extendOfferClaimed = dto.extendOfferClaimed ?? false
    this.discounts = dto.discounts ?? []
    this.priority = dto.priority ?? false
    this.analyticsExtension = dto.analyticsExtension ?? false
    this.favoriteArtistProfiles = dto.favoriteArtistProfiles ?? []
    this.storageAllocation = dto.storageAllocation ?? Number.POSITIVE_INFINITY

    this.links = {
      spotify: dto.links?.spotify ?? '',
      soundCloud: dto.links?.soundCloud ?? '',
      twitter: dto.links?.twitter ?? '',
      appleMusic: dto.links?.appleMusic ?? '',
      deezer: dto.links?.deezer ?? '',
    } as Links

    this.onboarding = {
      setup: dto.onboarding?.setup ?? false,
      releaseCreate: dto.onboarding?.releaseCreate ?? false,
      releaseView: dto.onboarding?.releaseView ?? false,
      releaseCollaborators: dto.onboarding?.releaseCollaborators ?? false,
      releaseDistribute: dto.onboarding?.releaseDistribute ?? false,
      recordingMetadata: dto.onboarding?.recordingMetadata ?? false,
      recordingSplit: dto.onboarding?.recordingSplit ?? false,
      filesShare: dto.onboarding?.filesShare ?? false,
      scheduleSync: dto.onboarding?.scheduleSync ?? false,
      taskAi: dto.onboarding?.taskAi ?? false,
    } as OnboardingItems

    this.persona_features = {
      distribution: dto.persona_features?.distribution ?? false,
      gainFans: dto.persona_features?.gainFans ?? false,
      trackAudience: dto.persona_features?.trackAudience ?? false,
      organize: dto.persona_features?.organize ?? false,
      splits: dto.persona_features?.splits ?? false,
    } as PersonaTypes

    this.importingContent = dto.importingContent ?? false
    this.feedbackPricing = dto.feedbackPricing ?? false
  }
}
export type OrganisationDto = Partial<Serialized<Organisation>>

export class Seat {
  account: Account
  role: Role
  status: string

  constructor(dto: SeatDto) {
    this.account = new Account(dto.account ?? {})
    this.role = new Role(dto.role ?? {})
    this.status = dto.status ?? ''
  }
}

export type SeatDto = Partial<Serialized<Seat>>

export class Role {
  id = ''
  name = ''
  permissions: Permissions[] = []

  constructor(dto: RoleDto) {
    Object.assign(this, dto)
  }
}

export type RoleDto = Partial<Serialized<Role>>
