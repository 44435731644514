/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable complexity */
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded'
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded'
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded'
import StorageRoundedIcon from '@mui/icons-material/StorageRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Card, Grid, LinearProgress, Stack, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import { reActivateSubscription } from 'src/api/payment'
import ReActivatedModal from 'src/components/pages/Settings/Billing/BillingTabs/ReActivatedModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import { bytesToSize } from 'src/utils/fileUtils'

type Props = {
  setBillingModalOpen: (open: boolean) => void
}

const Overview = (props: Props) => {
  const themeItem = useTheme()
  const { refreshCurrentOrganisation } = useAuth()
  const [reactivateLoading, setReactivateLoading] = useState(false)
  const [reActivatedModal, setReActivatedModal] = useState(false)
  const { currentOrganisation } = useAuth()
  const width = 'fit-content'

  const handleReactivateSubscription = async () => {
    setReactivateLoading(true)
    await reActivateSubscription()
      .then(() => setReactivateLoading(false))
      .then(() => refreshCurrentOrganisation())
      .then(() => setReActivatedModal(true))
  }

  return (
    <Stack
      direction='column'
      maxWidth={1312}
      padding={{
        xl: 2,
        lg: 2,
        md: 1,
        sm: 1,
        xs: 1,
      }}
      spacing={2}
      width={1}
    >
      <ReActivatedModal
        close={() => setReActivatedModal(false)}
        open={reActivatedModal}
      />
      <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
        <Grid container spacing={2} sx={{ marginTop: '-16px!important', marginLeft: '-16px!important' }}>
          <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
            <Card
              elevation={0}
              sx={{
                width: 1,
                background: theme => theme.palette.background.elevatedCard,
              }}
            >
              <Stack height={1} justifyContent='space-between' width={1}>
                <Stack direction='row' padding={2} spacing={2} width={1}>
                  <Stack spacing={2} width={1}>
                    <Typography variant='h5'>
                      Current plan
                    </Typography>
                    <Stack width={1}>
                      {currentOrganisation && currentOrganisation.membershipTier === 'FREE' &&
                      <Typography variant='body1'>Free forever tier</Typography>}
                      {currentOrganisation && currentOrganisation.membershipTier === 'STARTER' &&
                      <Typography variant='body1'>
                        Starter, monthly
                      </Typography>}
                      {currentOrganisation && currentOrganisation.membershipTier === 'CREATOR' &&
                      <Typography variant='body1'>
                        Essential, monthly
                      </Typography>}
                      {currentOrganisation && currentOrganisation.membershipTier === 'CREATOR_PRO' &&
                      <Typography variant='body1'>
                        Pro, monthly
                      </Typography>}
                      {currentOrganisation && currentOrganisation.membershipTier === 'ENTERPRISE' &&
                      <Typography variant='body1'>
                        Business, monthly
                      </Typography>}
                      {currentOrganisation && currentOrganisation.membershipTier === 'STARTER_YEARLY' &&
                      <Typography variant='body1'>
                        Starter, annual
                      </Typography>}
                      {currentOrganisation && currentOrganisation.membershipTier === 'CREATOR_YEARLY' &&
                      <Typography variant='body1'>
                        Essential, annual
                      </Typography>}
                      {currentOrganisation && currentOrganisation.membershipTier === 'CREATOR_PRO_YEARLY' &&
                      <Typography variant='body1'>
                        Pro, annual
                      </Typography>}
                      {currentOrganisation && currentOrganisation.membershipTier === 'ENTERPRISE_YEARLY' &&
                      <Typography variant='body1'>
                        Business, annual
                      </Typography>}
                      {currentOrganisation &&
                      currentOrganisation.membershipTier !== 'FREE' &&
                      currentOrganisation.stripeCancelled &&
                      <Typography color={themeItem.palette.error.main} variant='body1'>
                        {`Your plan expires on 
                        ${dayjs(new Date(currentOrganisation.endOfSubscription * 1000)).format('LL')}`}
                      </Typography>}
                      {currentOrganisation?.trial === true &&
                      <Stack spacing={2} width={1}>
                        <Typography color={themeItem.palette.text.secondary} variant='body2'>
                          {`Your trial ends on ${dayjs(currentOrganisation.trialEnd).format('LL')}`}
                        </Typography>
                      </Stack>}
                      {currentOrganisation?.discounts && currentOrganisation.discounts.map(discountItem =>
                        <Stack key={discountItem.id} paddingTop={2} width={1}>
                          <Card sx={{ width: 1 }} variant='outlined'>
                            <Stack padding={2} width={1}>
                              <LocalOfferRoundedIcon
                                sx={{
                                  color: '#FFA500',
                                  fontSize: '32px',
                                }}
                              />
                              <Typography lineHeight={1.2} variant='h4'>
                                {discountItem.coupon.name}
                              </Typography>
                              {discountItem.coupon.percent_off !== 0 &&
                              <Typography variant='body1'>
                                {discountItem.coupon.percent_off}
                                % off
                              </Typography>}
                              {discountItem.coupon.amount_off !== 0 &&
                              <Typography variant='body1'>
                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                  .format(discountItem.coupon.amount_off / 100)}
                                {' off'}
                              </Typography>}
                              {discountItem.end === 0
                                ? <Typography color='textSecondary' variant='body2'>
                                  Forever
                                </Typography>
                                : <>
                                  <Typography color='textSecondary' variant='body2'>
                                    {'From '}
                                    <b>
                                      {dayjs.unix(discountItem.start).format('LL') }
                                    </b>
                                  </Typography>
                                  <Typography color='textSecondary' variant='body2'>
                                    {'To '}
                                    <b>
                                      {dayjs.unix(discountItem.end).format('LL') }
                                    </b>
                                  </Typography>
                                </>}

                            </Stack>
                          </Card>
                        </Stack>)}
                    </Stack>
                  </Stack>

                  <Stack spacing={2}>
                    <ReceiptRoundedIcon color='success' sx={{ fontSize: 48 }} />
                  </Stack>
                </Stack>
                <Stack padding={2} spacing={2} width={1}>
                  <Stack alignItems='center'>
                    {currentOrganisation?.stripeCancelled && currentOrganisation.trial
                      ? <LoadingButton
                        loading={reactivateLoading}
                        onClick={handleReactivateSubscription}
                        sx={{ width }}
                        variant='text'
                      >
                        Reactivate trial
                      </LoadingButton>
                      : currentOrganisation?.trial
                        ? <Button
                          onClick={() => props.setBillingModalOpen(true)}
                          sx={{ width }}
                          variant='text'
                        >
                          Edit trial
                        </Button>
                        : currentOrganisation &&
                        currentOrganisation.membershipTier === 'FREE' &&
                        currentOrganisation.neverTrial
                          ? <Button
                            onClick={() => props.setBillingModalOpen(true)}
                            sx={{ width }}
                            variant='text'
                          >
                            Start your trial
                          </Button>
                          : currentOrganisation && currentOrganisation.membershipTier === 'FREE'
                            ? <Button
                              onClick={() => props.setBillingModalOpen(true)}
                              sx={{ width }}
                              variant='text'
                            >
                              Compare plans and upgrade
                            </Button>
                            : currentOrganisation?.stripeCancelled
                              ? <LoadingButton
                                loading={reactivateLoading}
                                onClick={handleReactivateSubscription}
                                sx={{ width }}
                                variant='text'
                              >
                                Reactivate subscription
                              </LoadingButton>
                              : <Button
                                onClick={() => props.setBillingModalOpen(true)}
                                sx={{ width }}
                                variant='text'
                              >
                                Change plan
                              </Button>}
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
            <Card
              elevation={0}
              sx={{
                width: 1,
                background: theme => theme.palette.background.elevatedCard,
              }}
            >
              <Stack height={1} justifyContent='space-between'>
                <Stack direction='row' padding={2} spacing={2} width={1}>
                  <Stack spacing={2} width={1}>
                    <Typography variant='h5'>
                      Storage
                    </Typography>
                    {currentOrganisation &&
                    <Typography
                      color={currentOrganisation.storageUsed < currentOrganisation.storageAllocation
                        ? 'inherit'
                        : 'error'}
                      variant='body2'
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {bytesToSize(currentOrganisation.storageUsed)}
                      </span>
                      {' '}
                      of
                      {' '}
                      {bytesToSize(currentOrganisation.storageAllocation)}
                    </Typography>}
                  </Stack>
                  <Stack spacing={2}>
                    <StorageRoundedIcon color='secondary' sx={{ fontSize: 48 }} />
                  </Stack>
                </Stack>
                <Stack paddingX={2}>
                  {currentOrganisation &&
                currentOrganisation.storageUsed < currentOrganisation.storageAllocation
                    ? <LinearProgress
                      color='secondary'
                      value={(currentOrganisation.storageUsed / currentOrganisation.storageAllocation) * 100}
                      variant='determinate'
                    />
                    : <LinearProgress
                      color='error'
                      value={100}
                      variant='determinate'
                    />}
                </Stack>
                <Stack padding={2} spacing={2} width={1}>
                  <Stack alignItems='center'>
                    <Button
                      component={NavLink}
                      sx={{ width }}
                      to='/files/home'
                      variant='text'
                    >
                      Manage files
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
        <Grid container spacing={2} sx={{ marginTop: '-16px!important', marginLeft: '-16px!important' }}>
          <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
            <Card
              elevation={0}
              sx={{
                width: 1,
                background: theme => theme.palette.background.elevatedCard,
              }}
            >
              <Stack height={1} justifyContent='space-between'>
                <Stack direction='row' padding={2} spacing={2} width={1}>
                  <Stack spacing={2} width={1}>
                    <Typography variant='h5'>
                      Team members
                    </Typography>
                    <Typography variant='body2'>
                      <span style={{ fontWeight: 'bold' }}>
                        {currentOrganisation?.seats.length}
                      </span>
                      {' '}
                      of
                      {' '}
                      {currentOrganisation?.seatAllocation === Number.POSITIVE_INFINITY
                        ? 'Unlimited'
                        : currentOrganisation?.seatAllocation}
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <AccountCircleRoundedIcon color='info' sx={{ fontSize: 48 }} />
                  </Stack>
                </Stack>
                <Stack padding={2} spacing={2} width={1}>
                  <Stack alignItems='center'>
                    <Button
                      component={NavLink}
                      sx={{ width }}
                      to='/settings/team/members'
                      variant='text'
                    >
                      Manage users
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
            <Card
              elevation={0}
              sx={{
                width: 1,
                background: theme => theme.palette.background.elevatedCard,
              }}
            >
              <Stack height={1} justifyContent='space-between'>
                <Stack direction='row' padding={2} spacing={2} width={1}>
                  <Stack spacing={2} width={1}>
                    <Typography variant='h5'>
                      Contact support
                    </Typography>
                    <Typography variant='body2'>
                      Get help with your billing by speaking to a releese specialist
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <ContactSupportRoundedIcon color='warning' sx={{ fontSize: 48 }} />
                  </Stack>
                </Stack>
                <Stack padding={2} spacing={2} width={1}>

                  <Stack alignItems='center'>
                    <Button
                      href='https://support.releese.io'
                      sx={{ width }}
                      target='_blank'
                      variant='text'
                    >
                      Contact support
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

export default Overview

