/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-duplicate-imports */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded'
import MicExternalOnRoundedIcon from '@mui/icons-material/MicExternalOnRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Button, Chip, Input, Paper, Stack, Typography, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'

import { completeSignUp, setOrganisationAnalyticsSource, updateOrgImageFromUrl } from 'src/api/organisation'
import { importReleaseFunction } from 'src/api/projects'
import SearchOneArtistComponentSignUp from 'src/components/form-elements/SearchOneArtistSignUp'
import SearchOneLabelSignup from 'src/components/form-elements/SearchOneLabelSignUp'
import CookiesHandlerSignup from 'src/components/pages/Login/CookiesHandlerSignup'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { SongStatsLabels } from 'src/models/Analytics'
import type { ArtistObject } from 'src/models/Distribution'

type Props = {
  readonly handleCreateProfile: () => void
  readonly setIsArtist: React.Dispatch<React.SetStateAction<boolean>>
  readonly setProfilePicture: React.Dispatch<React.SetStateAction<string>>
  readonly setPersona: React.Dispatch<React.SetStateAction<string>>
}

const CreateProfile = (props: Props) => {
  const themeItem = useTheme()
  const { currentAccount, currentOrganisation, refreshCurrentOrganisation, updateCurrentOrganisation } = useAuth()
  const [artistView, setArtistView] = useState(true)

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')

  const [selectedArtist, setSelectedArtist] = useState<ArtistObject | null | undefined>(null)
  const [selectedLabel, setSelectedLabel] = useState<SongStatsLabels | null | undefined>(null)

  const [artwork, setArtwork] = useState<File | undefined>()
  const [artworkPreview, setArtworkPreview] = useState<string | null>('')

  const [ga, setGa] = useState<string>()
  const [fbp, setFbp] = useState<string>()
  const [fbc, setFbc] = useState<string>()
  const [utm_source, setUtm_source] = useState<string>()
  const [utm_medium, setUtm_medium] = useState<string>()
  const [utm_campaign, setUtm_campaign] = useState<string>()
  const [utm_content, setUtm_content] = useState<string>()
  const [utm_term, setUtm_term] = useState<string>()

  const setSelectedArtists = (value: ArtistObject | null | undefined) => {
    setSelectedArtist(value)
    setName('')
    props.setPersona(value?.name ?? '')
    setArtworkPreview(null)
    props.setProfilePicture(value?.images?.[0]?.url ? value.images[0].url : '')
    setArtwork(undefined)
  }

  const setSelectedLabels = (value: SongStatsLabels | null | undefined) => {
    setSelectedLabel(value)
    setName('')
    props.setPersona(value?.name ?? '')
    setArtworkPreview(null)
    props.setProfilePicture(value?.avatar ?? '')
    setArtwork(undefined)
  }

  useEffect(() => {
    props.setIsArtist(artistView)
  }, [artistView])

  useEffect(() => {
    if (artwork) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setArtworkPreview(reader.result as string)
        props.setProfilePicture(reader.result as string)
      }
      reader.readAsDataURL(artwork)
    } else {
      setArtworkPreview(null)
    }
  }, [artwork])

  const handleArtworkUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file === undefined) return

    if (file.type.startsWith('image')) {
      setArtwork(file)
    } else {
      setArtwork(undefined)
    }
  }

  useEffect(() => {
    setSelectedArtist(null)
    setSelectedLabel(null)
  }, [artistView])

  const handleFormSubmit = async () => {
    setLoading(true)
    await updateCurrentOrganisation({
      ...currentOrganisation,
      name: selectedArtist
        ? selectedArtist.name
        : selectedLabel
          ? selectedLabel.name
          : name,
      orgType: artistView
        ? 'Artist'
        : 'Organization',
      logo: artwork,
      ga,
      fbp,
      fbc,
      utm_source,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_term,
    })
      .then(async () => {
        void completeSignUp()
        TagManager.dataLayer({
          dataLayer: {
            event: 'sign_up',
            userId: currentOrganisation?.id,
            eventProps: {
              city: currentOrganisation?.city,
              country: currentOrganisation?.country,
              email: currentOrganisation?.organisationEmail,
              firstName: currentOrganisation?.firstName,
              lastName: currentOrganisation?.lastName,
              state: currentOrganisation?.region,
              zip: currentOrganisation?.postalCode,
            },
          },
        })
        TagManager.dataLayer({
          dataLayer: {
            event: 'CompleteRegistration',
            userId: currentOrganisation?.id,
            eventProps: {
              city: currentOrganisation?.city,
              country: currentOrganisation?.country,
              email: currentOrganisation?.organisationEmail,
              firstName: currentOrganisation?.firstName,
              lastName: currentOrganisation?.lastName,
              state: currentOrganisation?.region,
              zip: currentOrganisation?.postalCode,
            },
          },
        })
        if (selectedArtist) {
          await setOrganisationAnalyticsSource('artist', selectedArtist.id ?? '')
            .catch(() => null)
          if (
            currentOrganisation &&
            selectedArtist.id &&
            selectedArtist.id.length > 0) {
            void importReleaseFunction('artist', selectedArtist.id, currentOrganisation.id)
              .then(() => void refreshCurrentOrganisation())
              .catch(() => null)
          }
          if (selectedArtist.images?.[0]?.url && selectedArtist.images[0].url.length > 0) {
            await updateOrgImageFromUrl(selectedArtist.images[0].url)
              .catch(() => null)
          }
        } else if (selectedLabel) {
          await setOrganisationAnalyticsSource('label', selectedLabel.songstats_label_id)
            .catch(() => null)
          if (selectedLabel.avatar && selectedLabel.avatar.length > 0) {
            await updateOrgImageFromUrl(selectedLabel.avatar)
              .catch(() => null)
          }
        }
      })
      .then(refreshCurrentOrganisation)
      .then(() => setLoading(false))
      .then(props.handleCreateProfile)
      .catch(() => setLoading(false))
  }

  return (
    <Stack
      justifyContent='center'
      maxWidth='md'
      sx={{
        border: 'none',
        width: 1,
      }}
    >
      <CookiesHandlerSignup
        setFbc={setFbc}
        setFbp={setFbp}
        setGa={setGa}
        setUtm_campaign={setUtm_campaign}
        setUtm_content={setUtm_content}
        setUtm_medium={setUtm_medium}
        setUtm_source={setUtm_source}
        setUtm_term={setUtm_term}
      />
      <form
        onSubmit={event => {
          event.preventDefault()
          void handleFormSubmit()
        }}
      >
        <Stack height={1} width={1}>
          <Stack direction='column' spacing={8} width={1}>
            <Stack direction='column' width={1}>
              <Stack direction='column' spacing={2}>
                <Stack direction='column'>
                  <Typography variant='h2'>
                    Create your team&apos;s profile
                  </Typography>
                  <Typography color={themeItem.palette.text.secondary} variant='body1'>
                    {currentAccount.firstName}
                    , let&apos;s get you ready to join Releese. Please select or create your
                    {' '}
                    {artistView ? 'artist' : 'label'}
                    {' '}
                    profile so we can get started.
                  </Typography>
                </Stack>
                <Stack
                  alignItems='flex-start'
                  height={1}
                  justifyContent='flex-start'
                  maxWidth={1332}
                  spacing={2}
                  width={1}
                >
                  <Stack
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    paddingBottom={2}
                    paddingTop={1}
                    width={1}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        backgroundColor: theme => artistView
                          ? theme.palette.primary.main
                          : theme.palette.info.main,
                        borderRadius: 16,
                        transition: 'background-color 0.2s linear',
                      }}
                    >
                      <Stack
                        alignItems='center'
                        justifyContent='center'
                        padding={4}
                      >
                        {artistView
                          ? <MicExternalOnRoundedIcon
                            sx={{
                              color: theme => theme.palette.primary.contrastText,
                              fontSize: '144px',
                            }}
                          />
                          : <AlbumRoundedIcon
                            sx={{
                              color: theme => theme.palette.primary.contrastText,
                              fontSize: '144px',
                            }}
                          />}
                      </Stack>
                    </Paper>
                  </Stack>
                  {artistView &&
                  <>
                    <Stack
                      direction='column'
                      width={1}
                    >
                      <SearchOneArtistComponentSignUp
                        disabled={false}
                        error={false}
                        placeholder='Your artist name'
                        selectedArtists={selectedArtist}
                        setSelectedArtists={setSelectedArtists}
                      />
                      {selectedArtist && selectedArtist.uri.length === 0 &&
                      <Stack direction='row' marginTop={2} spacing={2} width={1}>
                        <label
                          htmlFor='contained-button-file-upload-artist-image'
                          style={{
                            flexShrink: 0,
                          }}
                        >
                          <Button
                            color='primary'
                            component='span'
                            startIcon={<FileUploadRoundedIcon />}
                            sx={{
                              flexShrink: 0,
                              flexGrow: 1,
                            }}
                            variant='contained'
                          >
                            Upload avatar
                          </Button>
                          <Input
                            id='contained-button-file-upload-artist-image'
                            onChange={handleArtworkUpload}
                            sx={{
                              display: 'none',
                            }}
                            type='file'
                          />
                        </label>
                      </Stack>}
                    </Stack>
                    {selectedArtist
                      ? <Chip
                        avatar={<Avatar
                          src={selectedArtist.images?.[0]?.url ? selectedArtist.images[0].url : ''}
                          sx={{
                            height: '32px!important',
                            width: '32px!important',
                            marginLeft: '0px!important',
                          }}
                        />}
                        label={selectedArtist.name}
                        size='medium'
                        sx={{
                          height: 48,
                          paddingY: 2,
                          paddingLeft: 1,
                          paddingRight: 0,
                          '& .MuiChip-label': {
                            fontSize: '1rem',
                          },
                        }}
                      />
                      : <Chip
                        avatar={<Avatar
                          src={artworkPreview ?? ''}
                          sx={{
                            height: '32px!important',
                            width: '32px!important',
                            marginLeft: '0px!important',
                          }}
                        />}
                        label={name.length === 0 ? 'John Doe' : name}
                        size='medium'
                        sx={{
                          height: 48,
                          paddingY: 2,
                          paddingLeft: 1,
                          paddingRight: 0,
                          '& .MuiChip-label': {
                            fontSize: '1rem',
                          },
                        }}
                      />}
                  </>}
                  {!artistView &&
                  <>
                    <Stack
                      direction='column'
                      width={1}
                    >
                      <SearchOneLabelSignup
                        disabled={false}
                        error={false}
                        placeholder='Your label name'
                        selectedArtists={selectedLabel}
                        setSelectedArtists={setSelectedLabels}
                      />
                      {selectedLabel && selectedLabel.songstats_label_id.length === 0 &&
                      <Stack direction='row' marginTop={2} spacing={2} width={1}>
                        <label
                          htmlFor='contained-button-file-upload-organisation-image'
                          style={{
                            flexShrink: 0,
                          }}
                        >
                          <Button
                            color='info'
                            component='span'
                            startIcon={<FileUploadRoundedIcon />}
                            sx={{
                              flexShrink: 0,
                              flexGrow: 1,
                            }}
                            variant='contained'
                          >
                            Upload avatar
                          </Button>
                          <Input
                            id='contained-button-file-upload-organisation-image'
                            onChange={handleArtworkUpload}
                            sx={{
                              display: 'none',
                            }}
                            type='file'
                          />
                        </label>
                      </Stack>}
                    </Stack>

                    {selectedLabel
                      ? <Chip
                        avatar={<Avatar
                          src={selectedLabel.avatar}
                          sx={{
                            height: '32px!important',
                            width: '32px!important',
                            marginLeft: '0px!important',
                          }}
                        />}
                        label={selectedLabel.name}
                        size='medium'
                        sx={{
                          height: 48,
                          paddingY: 2,
                          paddingLeft: 1,
                          paddingRight: 0,
                          '& .MuiChip-label': {
                            fontSize: '1rem',
                          },
                        }}
                      />
                      : <Chip
                        avatar={<Avatar
                          src={artworkPreview ?? ''}
                          sx={{
                            height: '32px!important',
                            width: '32px!important',
                            marginLeft: '0px!important',
                          }}
                        />}
                        label={name.length === 0 ? 'Record Records' : name}
                        size='medium'
                        sx={{
                          height: 48,
                          paddingY: 2,
                          paddingLeft: 1,
                          paddingRight: 0,
                          '& .MuiChip-label': {
                            fontSize: '1rem',
                          },
                        }}
                      />}
                  </>}
                </Stack>

                <Stack direction='column' spacing={1}>
                  <Stack direction='row' marginLeft='auto!important' spacing={1} width='fit-content'>
                    <Button
                      color='primary'
                      disabled={loading}
                      onClick={() => setArtistView(old => !old)}
                      variant='subtle'
                    >
                      {artistView
                        ? 'Switch to label'
                        : 'Switch to artist'}
                    </Button>
                    <LoadingButton
                      color={artistView ? 'primary' : 'info'}
                      disabled={!selectedArtist && !selectedLabel && name.length === 0}
                      endIcon={<ArrowForwardIosRoundedIcon />}
                      loading={loading}
                      sx={{
                        width: 'fit-content',
                      }}
                      type='submit'
                      variant='contained'
                    >
                      {(selectedArtist && selectedArtist.uri.length > 0) || selectedLabel
                        ? 'Import '
                        : 'Create '}
                      {artistView
                        ? 'artist '
                        : 'label '}
                      profile
                    </LoadingButton>
                  </Stack>
                  <Typography
                    color='text.secondary'
                    marginTop={1}
                    maxWidth='sm'
                    variant='body2'
                    visibility={selectedArtist && selectedArtist.uri.length > 0
                      ? 'visible'
                      : 'hidden'}
                  >
                    We&apos;ll import your releases into your dashboard. We won&apos;t distribute anything until
                    you are ready.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  )
}

export default CreateProfile
