import axios from 'axios'

import type { TaxId } from 'src/models/Payment'

const PATH = '/tax' as const

export const getTaxIds = () =>
  axios.get<TaxId[]>(`${PATH}/current`)
    .then(response => response.data)

export const createTaxId = (taxType: string, taxValue: string) =>
  axios.post<TaxId>(`${PATH}/tax`, { taxType, taxValue })
    .then(response => response.data)

export const deleteTaxId = (id: string) =>
  axios.delete<boolean>(`${PATH}/${id}`)
    .then(response => response.data)
