/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Divider, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { firstCollaborator, inviteProjectCollaborator } from 'src/api/projects'
import CollaboratorItem from 'src/components/pages/Projects/ViewProject/InviteCollaborators/CollaboratorItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import TeamAvatar from 'src/components/TeamAvatar'
import type Project from 'src/models/Project'
import { emailCheck } from 'src/utils/regexUtil'

type Props = {
  project?: Project
  updatedFields: (project: Project) => void
}

const EditCollaboratorsComponent = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const themeItem = useTheme()
  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()
  const [email, setEmail] = useState('')
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)

  const checkEmail = email.length > 0 && !emailCheck.test(email)

  const addCollaborator = async () => {
    if (props.project && !exists) {
      setLoading(true)
      if (!currentOrganisation?.firstCollaborator) {
        void firstCollaborator()
        await updateCurrentOrganisation({ ...currentOrganisation, firstCollaborator: true })
          .then(() => setLoading(true))
          .then(refreshCurrentOrganisation)
          .finally(() => setLoading(true))
      }
      await inviteProjectCollaborator(props.project.id, email, 'Owner')
        .then(project => props.updatedFields(project))
        .then(() => setEmail(''))
        .finally(() => setLoading(false))
    }
  }

  const copyLink = (event: React.MouseEvent<HTMLElement>) => {
    if (props.project) {
      event.stopPropagation()
      event.preventDefault()
      setLinkCopiedToastOpen(true)
      void navigator.clipboard.writeText(`https://cloud.releese.io/invite/${props.project.id}/${props.project.privateInviteHash}`)
    }
  }

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  const exists = props.project?.collaborators.some(collaborator => collaborator.account.organisationEmail === email)
  const inTeam = currentOrganisation?.seats.some(seat => seat.account.email === email)
  const isYourTeam = email === currentOrganisation?.organisationEmail

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={linkCopiedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Link copied
        </Alert>
      </Snackbar>
      <Stack paddingBottom={4} paddingTop={2} paddingX={2} spacing={2} width={1}>
        {props.project?.organisation?.id === currentOrganisation?.id &&
        <Stack direction='column' spacing={1}>
          <Stack alignItems='center' direction='row' spacing={2}>
            <TextField
              autoComplete='new-password'
              error={exists || checkEmail || inTeam || isYourTeam}
              fullWidth
              inputProps={{
                autoComplete: 'new-password',
              }}
              label='Collaborator email'
              onChange={event => setEmail(event.target.value.replace(' ', '').replace(';', '').replace(/\s/g, ''))}
              placeholder='Email'
              sx={{ marginTop: 0 }}
              value={email}
            />
            <LoadingButton
              disabled={email.length === 0 || exists || checkEmail || isYourTeam}
              loading={loading}
              onClick={addCollaborator}
              sx={{ width: 'fit-content', flexShrink: 0 }}
              variant='contained'
            >
              Add collaborator
            </LoadingButton>
          </Stack>
          {exists &&
          <Typography color='error' variant='body2'>
            This collaborator is already a member of this project.
          </Typography>}
          {inTeam &&
          <Typography color='error' variant='body2'>
            This person is already in your team, you do not need to add them as a collaborator.
          </Typography>}
          {isYourTeam &&
          <Typography color='error' variant='body2'>
            You cannot add yourself as a collaborator on your own project.
          </Typography>}
          {checkEmail &&
          <Typography color='error' variant='body2'>
            Please enter a valid email address.
          </Typography>}
        </Stack>}
        {props.project?.organisation?.id === currentOrganisation?.id &&
        <Stack width='fit-content'>
          <Button
            onClick={event => copyLink(event)}
            rounded
            startIcon={<AddLinkRoundedIcon />}
            sx={{
              borderStyle: 'dashed',
            }}
            variant='outlined'
          >
            Copy private invitation link
          </Button>
        </Stack>}
        <Stack spacing={2}>
          <Stack direction='column' key={props.project?.organisation?.id} spacing={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={1}>
              <Stack alignItems='center' direction='row' spacing={2} width={1}>
                <TeamAvatar
                  organisation={props.project?.organisation}
                />
                <Stack direction='column'>
                  <Typography variant='body1'>
                    {props.project?.organisation?.name}
                  </Typography>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    {props.project?.organisation?.organisationEmail}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {props.project?.collaborators && props.project.collaborators.length > 0 &&
            <Divider sx={{ width: 1 }} />}
          </Stack>
          {props.project?.collaborators.map((collaborator, index) =>
            <Stack direction='column' key={collaborator.account.id} spacing={2} width={1}>
              <CollaboratorItem
                collaborator={collaborator}
                project={props.project}
                updatedFields={props.updatedFields}
              />
              {index + 1 < (props.project?.collaborators.length ?? 0) &&
              <Divider sx={{ width: 1 }} />}
            </Stack>)}
        </Stack>
      </Stack>
    </>
  )
}

export default EditCollaboratorsComponent
