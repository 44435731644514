/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable no-restricted-imports */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/require-await */
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded'
import { Alert, alpha, Box, Button, Divider, FilledInput, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, Snackbar, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDropzone } from 'react-dropzone'

import { firstRecording } from 'src/api/recordings'
import { FileUploadButtonBase } from 'src/components/form-elements/FileUpload'
import SearchField from 'src/components/form-elements/SearchField'
import RecordingsListItem from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingsListItem'
import useDropzoneFullscreen from 'src/components/pages/Projects/FullscreenDropzone'
import BulkDelete from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/BulkDelete'
import BulkUpdateModal from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/BulkUpdateModal'
import CreateSinglesModal from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/CreateSinglesModal'
import ImportRecordings from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/ImportRecordings'
import ViewRecordingsListItem from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/ViewRecordingItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import { GENRES, RECORDING_VERSIONS, TRACK_TYPES } from 'src/models/Recording'

type Props = {
  readonly project: Project
  readonly recordings: Recording[]
  readonly setRecordings: React.Dispatch<React.SetStateAction<Recording[]>>
  readonly newRecordings: File[]
  readonly setNewRecordings: React.Dispatch<React.SetStateAction<File[]>>
  readonly saveProjectReorder: (recordingItems: Recording[]) => Promise<void>
  readonly updateRecordings: () => Promise<void>
  readonly refresh: React.Dispatch<React.SetStateAction<boolean>>
  readonly currentTab: string
  readonly updateRecordingsSubtle: () => Promise<void>
}

const ViewProjectRecordings = (props: Props) => {
  const { currentAccountPermissions, currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } =
    useAuth()

  const themeColors = useTheme()

  const [searchQuery, setSearchQuery] = useState('')
  const [trackType, setTrackType] = useState('')
  const [recordingVersion, setRecordingVersion] = useState('')
  const [genre, setGenre] = useState('')
  const [editing, _Editing] = useState(true)
  const [_, setLoading] = useState(false)
  const [bulkUpdate, setBulkUpdate] = useState(false)
  const [bulkDelete, setBulkDelete] = useState(false)
  const [singles, setSingles] = useState(false)
  const [importItems, setImportItems] = useState(false)

  const [anchorElementEmbed, setAnchorElementEmbed] = useState<HTMLElement | null>(null)
  const openMenuEmbed = Boolean(anchorElementEmbed)

  const handleCloseEmbed = () => {
    setAnchorElementEmbed(null)
  }

  const filterData = props.recordings.filter(recording =>
    recording.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
    recording.recordingVersion.toLowerCase().includes(recordingVersion.toLowerCase()) &&
    recording.trackType.toLowerCase().includes(recordingVersion.toLowerCase()) &&
    recording.genre.toLowerCase().includes(genre.toLowerCase()))

  const { getRootProps } = useDropzone({
    accept: '.wav',
  })
  const [fileError, setFileError] = useState(false)
  const isDragActive = useDropzoneFullscreen()

  const addRecordingFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!currentOrganisation?.firstRecording) {
      void firstRecording()
      await updateCurrentOrganisation({ ...currentOrganisation, firstRecording: true })
        .then(() => setLoading(true))
        .then(refreshCurrentOrganisation)
        .finally(() => setLoading(true))
    }

    const file = event.target.files?.[0]
    if (!file) return
    if ((/\.(wav)$/i).test(file.name)) {
      props.setNewRecordings(oldItems => [file, ...oldItems])
    } else {
      setFileError(true)
    }
  }

  const addRecordingDropFile = async (event: FileList) => {
    if (!currentOrganisation?.firstRecording) {
      void firstRecording()
      await updateCurrentOrganisation({ ...currentOrganisation, firstRecording: true })
        .then(() => setLoading(true))
        .then(refreshCurrentOrganisation)
        .finally(() => setLoading(true))
    }

    const fileArray: File[] = [...event]
    for (const file of fileArray) {
      if ((/\.(wav)$/i).test(file.name)) {
        props.setNewRecordings(oldItems => [file, ...oldItems])
      } else {
        setFileError(true)
      }
    }
  }

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer.files.length > 0) {
      await addRecordingDropFile(event.dataTransfer.files)
      event.dataTransfer.clearData()
    }
  }

  const reorder = (list: Recording[], startIndex: number, endIndex: number) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId) {
      let temporaryFullElement = Object.assign({}, props.recordings)
      temporaryFullElement = reorder(props.recordings, result.source.index, result.destination.index)
      props.setRecordings(reorder(props.recordings, result.source.index, result.destination.index))
      await props.saveProjectReorder(temporaryFullElement)
    }
  }

  const [anchorElementFilter, setAnchorElementFilter] = useState<HTMLElement | null>(null)
  const openMenuFilter = Boolean(anchorElementFilter)
  const handleCloseFilter = () => {
    setAnchorElementFilter(null)
  }

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElementFilter(event.currentTarget)
  }

  return <>
    <Menu
      anchorEl={anchorElementFilter}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={handleCloseFilter}
      open={openMenuFilter}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack
        overflow='auto'
        padding={2}
        spacing={1}
        width={360}
      >
        <Typography lineHeight={1.2} variant='h3'>
          Filters
        </Typography>
        <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
          <Typography
            sx={{
              marginTop: 0,
              width: 100,
              flexShrink: 0,
            }}
            variant='inputLabel'
          >
            Version
          </Typography>
          <Select
            displayEmpty
            fullWidth
            id='version-filter'
            input={<FilledInput />}
            onChange={event => setRecordingVersion(event.target.value)}
            renderValue={selected =>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                  <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                </Stack>
              </Box>}
            value={recordingVersion}
          >
            <MenuItem value=''>All</MenuItem>
            {RECORDING_VERSIONS.map(type =>
              <MenuItem key={type} value={type}>{type}</MenuItem>)}
          </Select>
        </Stack>

        <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
          <Typography
            sx={{
              marginTop: 0,
              width: 100,
              flexShrink: 0,
            }}
            variant='inputLabel'
          >
            Type
          </Typography>
          <Select
            displayEmpty
            fullWidth
            id='type-filter'
            input={<FilledInput />}
            onChange={event => setTrackType(event.target.value)}
            renderValue={selected =>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                  <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                </Stack>
              </Box>}
            value={trackType}
          >
            <MenuItem value=''>All</MenuItem>
            {TRACK_TYPES.map(type =>
              <MenuItem key={type} value={type}>{type}</MenuItem>)}
          </Select>
        </Stack>

        <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
          <Typography
            sx={{
              marginTop: 0,
              width: 100,
              flexShrink: 0,
            }}
            variant='inputLabel'
          >
            Genre
          </Typography>
          <Select
            displayEmpty
            fullWidth
            id='genre-filter'
            input={<FilledInput />}
            onChange={event => setGenre(event.target.value)}
            renderValue={selected =>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                  <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                </Stack>
              </Box>}
            value={genre}
          >
            <MenuItem value=''>All</MenuItem>
            {GENRES.map(type =>
              <MenuItem key={type} value={type}>{type}</MenuItem>)}
          </Select>
        </Stack>
      </Stack>
    </Menu>
    <Menu
      anchorEl={anchorElementEmbed}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClick={handleCloseEmbed}
      onClose={handleCloseEmbed}
      open={openMenuEmbed}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem
        disabled={props.project.distributed ||
          props.recordings.length === 0 ||
          !currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS)}
        onClick={() => setBulkUpdate(true)}
      >
        <ListItemIcon>
          <EditRoundedIcon color='secondary' fontSize='large' />
        </ListItemIcon>
        <ListItemText
          primary='Bulk Edit Metadata'
          secondary='Edit shared metadata on all recordings'
        />
      </MenuItem>
      <MenuItem
        disabled={props.project.distributed ||
          !currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS)}
        onClick={() => setImportItems(true)}
      >
        <ListItemIcon>
          <ImportExportRoundedIcon color='primary' fontSize='large' />
        </ListItemIcon>
        <ListItemText
          primary='Import Recordings'
          secondary='Duplicate recordings from other projects'
        />
      </MenuItem>
      <MenuItem
        disabled={props.recordings.length === 0 ||
          currentOrganisation?.id !== props.project.organisation?.id ||
          !currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS)}
        onClick={() => setSingles(true)}
      >
        <ListItemIcon>
          <AddBoxRoundedIcon color='success' fontSize='large' />
        </ListItemIcon>
        <ListItemText
          primary='Create Singles'
          secondary='Create a project for each individual recording'
        />
      </MenuItem>
      <MenuItem
        disabled={props.project.distributed ||
          props.recordings.length === 0 ||
          currentOrganisation?.id !== props.project.organisation?.id ||
          !currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS)}
        onClick={() => setBulkDelete(true)}
      >
        <ListItemIcon>
          <DeleteRoundedIcon color='error' fontSize='large' />
        </ListItemIcon>
        <ListItemText
          primary='Bulk Delete'
          secondary='Delete multiple recordings at once'
        />
      </MenuItem>
    </Menu>
    {singles &&
    <CreateSinglesModal
      close={() => setSingles(false)}
      open={singles}
      project={props.project}
    />}
    {bulkDelete &&
    <BulkDelete
      close={() => setBulkDelete(false)}
      open={bulkDelete}
      project={props.project}
      updateRecordingsSubtle={props.updateRecordingsSubtle}
    />}
    {bulkUpdate &&
    <BulkUpdateModal
      close={() => setBulkUpdate(false)}
      open={bulkUpdate}
      project={props.project}
      updateRecordings={props.updateRecordingsSubtle}
    />}
    {importItems &&
    <ImportRecordings
      close={() => setImportItems(false)}
      open={importItems}
      project={props.project}
      updateRecordingsSubtle={props.updateRecordingsSubtle}
    />}

    <Stack
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <Snackbar
        autoHideDuration={5000}
        message='Invalid file format'
        onClose={() => setFileError(false)}
        open={fileError}
      >
        <Alert
          onClose={() => setFileError(false)}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Invalid file format
        </Alert>
      </Snackbar>
      {!props.project.distributed &&
        <Stack
          {...getRootProps()}
          onDrop={handleDrop}
          sx={{
            border: theme => `dashed ${theme.palette.primary.main} 4px`,
            backgroundColor: theme => alpha(theme.palette.background.paper, 0.9),
            position: 'absolute',
            visibility: isDragActive ? 'visible' : 'hidden',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9998,
            animation: 'blinker 2s linear infinite',
            '@keyframes blinker': {
              '50%': {
                opacity: 0.9,
              },
            },
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36,
            }}
          >
            <Typography
              color={themeColors.palette.text.label}
            >
              Drop .wav files here
            </Typography>
          </Stack>
        </Stack>}
      <Stack
        direction='row'
        paddingX={2}
        paddingY={2}
        sx={{
          overflowX: 'auto',
          overflowY: 'clip',
          flexShrink: 0,
        }}
        width={1}
      >
        <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
          <Stack direction='row' marginRight='auto' spacing={1}>
            <SearchField
              onChange={event => setSearchQuery(event.target.value)}
              value={searchQuery}
            />
          </Stack>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Tooltip title='Advanced filters'>
              <IconButton
                color='default'
                onClick={handleClickFilter}
              >
                <FilterListRoundedIcon />
              </IconButton>
            </Tooltip>
            <Button
              color='info'
              onClick={event => setAnchorElementEmbed(event.currentTarget)}
              startIcon={<SettingsSuggestRoundedIcon />}
              sx={{
                flexShrink: 0,
              }}
            >
              Automations
            </Button>
          </Stack>

        </Stack>

      </Stack>
      <Divider sx={{ width: 1 }} />
      {!props.project.distributed && currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS) &&
      <Stack padding={2} width={1}>
        <Stack marginX='auto' maxWidth={900} width={1}>
          <FileUploadButtonBase
            id='upload-recordings'
            onChange={addRecordingFile}
          >
            <Paper
              elevation={0}
              sx={{
                border: theme => `1px dashed ${theme.palette.primary.main}`,
                position: 'relative',
                width: 1,
                marginX: 'auto!important',
                maxWidth: 900,
                overflow: 'hidden',
                '&:hover': {
                  opacity: 0.5,
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                },
              }}
            >
              <Stack alignItems='center' marginY={2} textAlign='center'>
                <MusicNoteRoundedIcon
                  color='primary'
                  sx={{
                    width: 'inherit',
                    height: '6rem',
                    padding: 2,
                    border: theme => `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '50%',
                    background: theme => alpha(theme.palette.primary.light, 0.2),
                  }}
                />
                <Typography
                  sx={{ marginTop: 2 }}
                  variant='h6'
                >
                  Upload your recordings
                </Typography>
                <Typography
                  color='inputText'
                  sx={{
                    marginY: 1,
                  }}
                  variant='inputText'
                >
                  Drag and drop WAV files or
                  {' '}
                  <span>
                    <Button
                      color='secondary'
                      sx={{
                        height: 0,
                        marginBottom: 0.1,
                        marginX: 0,
                        padding: 0,
                        fontSize: 'inherit',
                      }}
                      variant='text'
                    >
                      browse files
                    </Button>
                  </span>
                  {' '}
                  to add recordings
                </Typography>
                <Typography
                  variant='helperText'
                >
                  Collaborators on this project can see these recordings
                </Typography>
              </Stack>

            </Paper>
          </FileUploadButtonBase>
        </Stack>
      </Stack>}
      <Stack
        alignItems='center'
        direction='column'
        spacing={2}
        sx={{
          marginX: 'auto',
          paddingX: 2,
        }}
        width={1}
      >
        {props.recordings.length > 0 || props.newRecordings.length > 0
          ? <Stack width={1}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='presaves' key='presaves'>
                {provided =>
                  <Stack {...provided.droppableProps} marginBottom={4} ref={provided.innerRef} width={1}>
                    {props.currentTab === '2' && filterData.map((recording, index) =>
                      <Draggable
                        draggableId={recording.id}
                        index={index}
                        isDragDisabled={props.project.distributed || !editing}
                        key={recording.id}
                      >
                        {providedDrag =>
                          <Stack
                            paddingY={1}
                            ref={providedDrag.innerRef}
                            width={1}
                            {...providedDrag.draggableProps}
                          >
                            <ViewRecordingsListItem
                              distributed={props.project.distributed}
                              dragHandleProps={providedDrag.dragHandleProps}
                              draggable={editing && !props.project.distributed}
                              editing={editing && !props.project.distributed}
                              inProject
                              index={index}
                              key={`recording-${recording.id}`}
                              project={props.project}
                              recording={recording}
                              refresh={props.refresh}
                              updateRecordings={props.updateRecordings}
                              updateRecordingsSubtle={props.updateRecordingsSubtle}
                            />
                          </Stack>}
                      </Draggable>)}
                    {
                      props.newRecordings.map(file =>
                        <RecordingsListItem
                          key={`recording-${file.name}`}
                          project={props.project}
                          recordingFile={file}
                          setNewRecordings={props.setNewRecordings}
                          setRecordings={props.setRecordings}
                        />)
                    }
                  </Stack>}
              </Droppable>
            </DragDropContext>
          </Stack>
          : null}
      </Stack>
    </Stack>
  </>
}

export default ViewProjectRecordings
