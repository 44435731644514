/* eslint-disable complexity */
/* eslint-disable unicorn/no-unsafe-regex */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { setCustomDomain } from 'src/api/organisation'
import DomainSetupModal from 'src/components/pages/Settings/Marketing/Domain/DomainSetupModal'
import { useAuth } from 'src/components/providers/AuthProvider'

const Domain = () => {
  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()
  const colorText = useTheme().palette.text.primary
  const [domain, setDomain] = useState('')
  const [loading, setLoading] = useState(false)
  const [doneModal, setDoneModal] = useState(false)
  const checkCname = /(?:[\dA-Za-z]{2,61}\.){2}[\dA-Za-z]{2,61}/

  const setupCustomDomain = async () => {
    if (currentOrganisation?.membershipTier === 'ENTERPRISE' ||
      currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY' ||
      currentOrganisation?.membershipTier === 'CREATOR_PRO' ||
      currentOrganisation?.membershipTier === 'CREATOR_PRO_YEARLY') {
      setLoading(true)
      await setCustomDomain(domain)
        .then(() => {
          setLoading(false)
          setDoneModal(true)
        })
        .then(refreshCurrentOrganisation)
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Stack height={1} overflow='auto'>
      <DomainSetupModal
        close={() => setDoneModal(false)}
        open={doneModal}
      />
      <Stack direction='column' padding={2} spacing={6}>
        <Stack alignItems='flex-start' direction='column' >
          <Typography lineHeight={1.2} variant='h3'>
            Custom Domain
          </Typography>
          <Typography color='text.label' maxWidth={640} variant='body1'>
            {`Your custom domain can be used on Marketing Cloud Landing Pages. Please point
            a CNAME record from your domain to `}
            <g>
              <code style={{ fontSize: '16px', color: colorText }}>
                link.rls.ee
              </code>
            </g>
          </Typography>
          <Stack marginTop={2} spacing={2}>
            <Alert
              severity='warning'
              sx={{
                maxWidth: 640,
                color: theme => theme.palette.primary.contrastText,
              }}
              variant='filled'
            >
              You cannot change your custom domain after it is set
            </Alert>
            {(currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
              currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY' &&
              currentOrganisation?.membershipTier !== 'CREATOR_PRO' &&
              currentOrganisation?.membershipTier !== 'CREATOR_PRO_YEARLY') &&
              <Alert
                severity='success'
                sx={{
                  maxWidth: 640,
                  color: theme => theme.palette.primary.contrastText,
                }}
                variant='filled'
              >
                Custom domains are only available to Pro and Business subscribers
              </Alert>}
          </Stack>

          {currentOrganisation?.customDomainSubmitted && currentOrganisation.customDomain.length > 0 &&
          <Stack alignItems='center' direction='row' marginTop={2} spacing={1}>
            <CheckCircleRoundedIcon color='success' fontSize='large' />
            <Typography variant='subtitle2'>
              {`Your custom domain is ${currentOrganisation.customDomain}`}
            </Typography>
          </Stack>}

        </Stack>
        <Stack direction='column' spacing={4}>
          <Stack direction='row' spacing={3}>
            <Typography variant='body2' width={192}>
              Custom Domain
            </Typography>
            <Stack direction='column' spacing={1}>
              <Stack alignItems='center' direction='row' spacing={1}>
                <TextField
                  autoComplete='off'
                  disabled={currentOrganisation?.customDomainSubmitted ||
                    (currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
                    currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY' &&
                    currentOrganisation?.membershipTier !== 'CREATOR_PRO' &&
                    currentOrganisation?.membershipTier !== 'CREATOR_PRO_YEARLY')}
                  error={domain.length > 0 && !checkCname.test(domain)}
                  label='Custom domain'
                  onChange={event => setDomain(event.target.value.replace('/', ''))}
                  placeholder='link.example.com'
                  sx={{
                    marginTop: 0,
                    width: 1,
                    maxWidth: 400,
                  }}
                  value={domain}
                />
              </Stack>
              <Typography
                maxWidth={480}
                variant='helperText'
              >
                Input the custom subdomain you want to use
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' spacing={3}>
            <Typography variant='body2' width={192}>
              Domain Verification
            </Typography>
            <Stack direction='column' spacing={1}>
              <LoadingButton
                color='success'
                disabled={currentOrganisation !== null && currentOrganisation !== undefined && (
                  (currentOrganisation.customDomain && currentOrganisation.customDomain.length > 0) ||
                  currentOrganisation.customDomainSubmitted ||
                  !checkCname.test(domain) ||
                  (currentOrganisation.membershipTier !== 'ENTERPRISE' &&
                  currentOrganisation.membershipTier !== 'ENTERPRISE_YEARLY' &&
                  currentOrganisation.membershipTier !== 'CREATOR_PRO' &&
                  currentOrganisation.membershipTier !== 'CREATOR_PRO_YEARLY'))}
                endIcon={<LanguageRoundedIcon />}
                loading={loading}
                onClick={() => setupCustomDomain()}
                sx={{
                  width: 'fit-content',
                }}
                variant='contained'
              >
                Enable Custom Domain
              </LoadingButton>
              <Typography
                maxWidth={480}
                variant='helperText'
              >
                Once your domain is verified, an SSL certificate will be issued and you will
                be able to use your custom domain. It can take up to 90 minutes for this to be in effect
                but is usually done within 60 seconds.
              </Typography>
            </Stack>
          </Stack>

        </Stack>
      </Stack>
    </Stack>
  )
}

export default Domain
