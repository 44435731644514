/* eslint-disable no-duplicate-imports */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Stack } from '@mui/material'
import {
  withCursors,
  withYHistory,
  withYjs,
  YjsEditor,
} from '@slate-yjs/core'
import type { AutoformatPlugin, PlateEditor } from '@udecode/plate'
import {
  createAlignPlugin,
  createAutoformatPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createCodePlugin,
  createComboboxPlugin,
  createDeserializeCsvPlugin,
  createDeserializeDocxPlugin,
  createDeserializeMdPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createFontSizePlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHorizontalRulePlugin,
  createIndentPlugin,
  createItalicPlugin,
  createKbdPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createMentionPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createPlateUI,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  withPlate,
} from '@udecode/plate'
import { createCommentsPlugin, MARK_COMMENT } from '@udecode/plate-comments'
import { createBlockSelectionPlugin } from '@udecode/plate-selection'
import { PlateCommentLeaf } from '@udecode/plate-ui-comments'
import randomColor from 'randomcolor'
import type { ChangeEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { createEditor } from 'slate'
import { v4 } from 'uuid'
import { SocketIOProvider } from 'y-socket.io'
import * as Y from 'yjs'

import { createErrorPlugin, ELEMENT_ALERT_ERROR, ErrorElement } from './Modern/alert/AlertPlugin'
import { createInfoPlugin, ELEMENT_ALERT_INFO, InfoElement } from './Modern/alert/InfoPlugin'
import { createSuccessPlugin, ELEMENT_ALERT_SUCCESS, SuccessElement } from './Modern/alert/SuccessPlugin'
import { createWarningPlugin, ELEMENT_ALERT_WARNING, WarningElement } from './Modern/alert/WarningPlugin'
import { alignPlugin } from './Modern/align/alignPlugin'
import { autoformatPlugin } from './Modern/autoformat/autoformatPlugin'
import { ChecklistElement, createChecklistPlugin, ELEMENT_CHECKLIST } from './Modern/checklist/ChecklistPlugin'
import { createDividerlugin } from './Modern/divider/DividerPlugin'
import { withStyledDraggables } from './Modern/dnd/withStyledDraggables'
import { exitBreakPlugin } from './Modern/exit-break/exitBreakPlugin'
import { createCustomFramePlugin, ELEMENT_FRAME_CUSTOM, IframeElement } from './Modern/iFrame/IframePlugin'
import { indentPlugin } from './Modern/indent/indentPlugin'
import { createLegacyQuotePlugin } from './Modern/legacy/LegacyBlockQuote'
import { createLegacyHeaderOnePlugin } from './Modern/legacy/LegacyHOne'
import { createLegacyHeaderTwoPlugin } from './Modern/legacy/LegacyHTwo'
import { createLegacyParagraphPlugin } from './Modern/legacy/LegacyParagraph'
import { linkPlugin } from './Modern/link/linkPlugin'
import { BulletedListElement, createBulletedListPlugin, ELEMENT_BULLETED_LIST } from './Modern/listCustom/BulletedListPlugin'
import { createListItemPlugin } from './Modern/listCustom/ListItemPlugin'
import { createNumberedListPlugin, ELEMENT_NUMBERED_LIST, NumberedListElement } from './Modern/listCustom/NumberedListPlugin'
import { withStyledPlaceHolders } from './Modern/placeholder/withStyledPlaceHolders'
import { resetBlockTypePlugin } from './Modern/reset-node/resetBlockTypePlugin'
import { selectOnBackspacePlugin } from './Modern/select-on-backspace/selectOnBackspacePlugin'
import { softBreakPlugin } from './Modern/soft-break/softBreakPlugin'
import { createTaskboardPlugin, ELEMENT_TASKBOARD, TaskboardElement } from './Modern/taskboard/TaskboardPlugin'
import type {
  MyEditor,
  MyValue } from './Modern/typescript/plateTypes'
import {
  createMyPlugins,
} from './Modern/typescript/plateTypes'
import { getNotesbackground, updateNotesImage } from 'src/api/taskboards'
import { FileUploadNotesButton } from 'src/components/form-elements/FileUpload'
import { createCloudImagePlugin, KEY_CLOUD } from 'src/components/pages/Notes/Modern/image/createImagePlugin'
import { ImageElement } from 'src/components/pages/Notes/Modern/image/ImageComponentProps'
import NotesEditorModern from 'src/components/pages/Notes/Modern/NotesEditor'
import { useAuth } from 'src/components/providers/AuthProvider'
import Banner from 'src/images/banner-placeholder-1.jpeg'
import type Project from 'src/models/Project'

type Props = {
  slug: string
  project?: Project
  token: string
}

const NotesClient = (props: Props) => {
  const { currentAccount } = useAuth()
  const [anchorElementField, setAnchorElementField] = useState<HTMLButtonElement | null>(null)
  const openMenuField = Boolean(anchorElementField)

  const handleCloseField = () => {
    setAnchorElementField(null)
  }

  let components = createPlateUI({
    [MARK_COMMENT]: PlateCommentLeaf,
    [ELEMENT_ALERT_ERROR]: ErrorElement,
    [ELEMENT_ALERT_INFO]: InfoElement,
    [ELEMENT_ALERT_SUCCESS]: SuccessElement,
    [ELEMENT_ALERT_WARNING]: WarningElement,
    [ELEMENT_TASKBOARD]: TaskboardElement,
    [ELEMENT_FRAME_CUSTOM]: IframeElement,
    [ELEMENT_BULLETED_LIST]: BulletedListElement,
    [ELEMENT_NUMBERED_LIST]: NumberedListElement,
    [ELEMENT_CHECKLIST]: ChecklistElement,
    [KEY_CLOUD]: ImageElement,
  })
  components = withStyledPlaceHolders(components)

  const plugins = useMemo(
    () =>
      createMyPlugins(
        [
          createCloudImagePlugin(),
          createLegacyHeaderOnePlugin(),
          createLegacyHeaderTwoPlugin(),
          createLegacyParagraphPlugin(),
          createLegacyQuotePlugin(),
          createListItemPlugin(),
          createNumberedListPlugin(),
          createBulletedListPlugin(),
          createCustomFramePlugin(),
          createChecklistPlugin(),
          createDividerlugin(),
          createInfoPlugin(),
          createComboboxPlugin(),
          createErrorPlugin(),
          createWarningPlugin(),
          createSuccessPlugin(),
          createTaskboardPlugin(),
          createBlockquotePlugin(),
          createTodoListPlugin(),
          createParagraphPlugin(),
          createHeadingPlugin(),
          createMentionPlugin(),
          createMentionPlugin({
            key: '/',
            options: {
              trigger: '/',
            },
          }),
          createHorizontalRulePlugin(),
          createLinkPlugin(linkPlugin),
          createListPlugin(),
          createTablePlugin(),
          createMediaEmbedPlugin(),
          createCodeBlockPlugin(),
          createAlignPlugin(alignPlugin),
          createBoldPlugin(),
          createCodePlugin(),
          createItalicPlugin(),
          createHighlightPlugin(),
          createUnderlinePlugin(),
          createStrikethroughPlugin(),
          createSubscriptPlugin(),
          createSuperscriptPlugin(),
          createFontColorPlugin(),
          createFontBackgroundColorPlugin(),
          createFontSizePlugin(),
          createKbdPlugin(),
          createNodeIdPlugin(),
          createBlockSelectionPlugin(),
          createDndPlugin({ options: { enableScroller: true } }),
          createIndentPlugin(indentPlugin),
          createAutoformatPlugin<
            AutoformatPlugin<MyValue, MyEditor>,
            MyValue,
            MyEditor
          >(autoformatPlugin),
          createResetNodePlugin(resetBlockTypePlugin),
          createSoftBreakPlugin(softBreakPlugin),
          createExitBreakPlugin(exitBreakPlugin),
          createSelectOnBackspacePlugin(selectOnBackspacePlugin),
          createCommentsPlugin(),
          createDeserializeMdPlugin(),
          createDeserializeCsvPlugin(),
          createDeserializeDocxPlugin(),
        ],
        {
          components: withStyledDraggables({ components, setAnchorElementField }),
        }
      ),
    []
  )

  const name = useMemo(
    () =>
      `${currentAccount.firstName} ${currentAccount.lastName}`,
    []
  )

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    []
  )

  const baseUrlWebSocket = process.env.NODE_ENV === 'development'
    ? 'ws://localhost:5000'
    : 'wss://api.releese.io'

  const [sharedTypeContent, provider] = useMemo(() => {
    const document_ = new Y.Doc()
    const sharedTypeContentItem = document_.get('content', Y.XmlText) as Y.XmlText
    const providerItem = new SocketIOProvider(baseUrlWebSocket,
      props.slug,
      document_,
      {
        autoConnect: true,
        auth: {
          token: props.token,
        },
      })
    return [sharedTypeContentItem, providerItem]
  }, [])

  const editor = useMemo(() => {
    const cursorData = {
      color,
      name,
    }

    return withPlate(
      withYHistory(
        withCursors(
          withYjs(createEditor(), sharedTypeContent, { autoConnect: false }),
          provider.awareness,
          {
            data: cursorData,
          },
        )
      ) as any,
      {
        plugins,
        disableCorePlugins: {
          history: true,
        },
      }
    )
  }, [provider.awareness, sharedTypeContent])

  useEffect(() => {
    YjsEditor.connect(editor)
    return () => YjsEditor.disconnect(editor)
  }, [editor])

  useEffect(() => {
    provider.awareness.setLocalStateField('data', {
      name,
      color,
      key: currentAccount.id,
    })

    provider.connect()

    return () => {
      provider.disconnect()
    }
  }, [color, sharedTypeContent, provider])

  const [imageUrl, setImageUrl] = useState(`url(${Banner})`)

  const uploadNotesPicture = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    await updateNotesImage(file, props.slug)
      .then(image => setImageUrl(`url(${image})`))
  }

  useEffect(() => {
    void getNotesbackground(props.slug)
      .then(url => {
        if (url.length > 0) {
          setImageUrl(`url(${url})`)
        } else {
          setImageUrl(`url(${Banner})`)
        }
      })
  }, [props.slug])

  return (
    <Stack
      width={1}
    >
      <Box
        height={320}
        sx={{
          backgroundImage: imageUrl,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          flexShrink: 0,
          display: 'block',
          position: 'relative',
          '.MuiButtonBase-root': {
            opacity: 0,
          },
          ':hover .MuiButtonBase-root': {
            opacity: 1,
          },
        }}
        width={1}
      >
        <Stack
          sx={{
            position: 'absolute',
            zIndex: 100,
            right: 16,
            bottom: 16,

          }}
        >
          <FileUploadNotesButton
            id={v4()}
            name='image'
            onChange={uploadNotesPicture}
          >
            Edit banner
          </FileUploadNotesButton>
        </Stack>
      </Box>
      <Stack
        marginX='auto'
        maxWidth='xl'
        paddingTop={2}
        paddingX={10}
        width={1}
      >
        <NotesEditorModern
          anchorElementField={anchorElementField}
          editor={editor as unknown as PlateEditor<MyValue>}
          handleCloseField={handleCloseField}
          notesId={props.slug}
          openMenuField={openMenuField}
          project={props.project}
          setanchorElementField={setAnchorElementField}
        />
      </Stack>
    </Stack>
  )
}

export default NotesClient
