import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button } from '@mui/material'
import { useState } from 'react'

import AddTaxIdModal from 'src/components/pages/Account/Billing/BillingComponents/AddTaxIdModal'
import TaxComponentItemCheckout from 'src/components/pages/Account/Billing/BillingComponents/TaxItemCheckout'
import type { TaxId } from 'src/models/Payment'

type Props = {
  business: boolean
  taxIds: TaxId[]
  getTaxCodes: () => Promise<void>
}

const TaxComponentCheckout = (props: Props) => {
  const [openTaxModal, setOpenTaxModal] = useState(false)

  return <>
    {openTaxModal &&
    <AddTaxIdModal
      close={() => setOpenTaxModal(false)}
      getTaxCodes={props.getTaxCodes}
      open={openTaxModal}
    />}
    {props.taxIds.map(tax =>
      <TaxComponentItemCheckout
        getTaxCodes={props.getTaxCodes}
        key={tax.id}
        taxId={tax}
      />)}
    {props.business &&
    <Button
      color='secondary'
      endIcon={<AddRoundedIcon />}
      onClick={() => setOpenTaxModal(true)}
      rounded
      sx={{
        width: 'fit-content',
        marginTop: '16px!important',
        color: theme => theme.palette.secondary.main,
      }}
      variant='outlined'
    >
      Add Tax ID
    </Button>}
  </>
}
export default TaxComponentCheckout
