/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-mixed-operators */
/* eslint-disable complexity */
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Alert, Button, Card, Chip, lighten, Snackbar, Stack, Switch, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useExitIntent } from 'use-exit-intent'

import CompleteFeatureList from 'src/components/pages/Account/Billing/BillingComponents/CompleteFeatureList'
import EssentialCard from 'src/components/pages/Account/Billing/BillingComponents/EssentialCard'
import pricingFeaturesList from 'src/components/pages/Account/Billing/BillingComponents/pricingFeaturesList'
import ProCard from 'src/components/pages/Account/Billing/BillingComponents/ProCard'
import StarterCard from 'src/components/pages/Account/Billing/BillingComponents/StarterCard'
import FeedbackModal from 'src/components/pages/Account/Billing/FeedbackModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import background from 'src/images/Pricing/bg.png'
import type { PriceLookupKey } from 'src/models/Organisation'

export type SeatCount = {
  label: string
  value: number
}

type Props = {
  readonly setPriceLookupKey: (price: PriceLookupKey) => void
  readonly setTerm: (term: number) => void
  readonly setSeats: (seats: number) => void
  readonly setCheckout: (checkout: string) => void
  readonly neverTrial: boolean
  readonly previousTrial: boolean
  readonly term: number
}

const PlanComponent = (props: Props) => {
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))
  const [form, setForm] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const { currentOrganisation, updateCurrentOrganisation } = useAuth()
  const { registerHandler } = useExitIntent({
    desktop: {
      mouseLeaveDelayInSeconds: 2,
      triggerOnMouseLeave: true,
      triggerOnIdle: false,
      useBeforeUnload: false,
    },
    mobile: {
      delayInSecondsToTrigger: 10,
      triggerOnIdle: true,
    },
  })

  registerHandler({
    id: 'openModal',
    handler: () => {
      if (currentOrganisation?.feedbackPricing === false) {
        setForm(true)
        void updateCurrentOrganisation({
          feedbackPricing: true,
        })
      }
    },
  })

  const starter = 15
  const creator = 25
  const pro = 50
  const month = 1
  const year = 12

  const [activeBilling, setActiveBilling] = useState(starter)
  const [_total, setTotal] = useState(starter * year * 0.8)

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_plans',
        userId: currentOrganisation?.id,
        eventProps: {
          city: currentOrganisation?.city,
          country: currentOrganisation?.country,
          email: currentOrganisation?.organisationEmail,
          firstName: currentOrganisation?.firstName,
          lastName: currentOrganisation?.lastName,
          state: currentOrganisation?.region,
          zip: currentOrganisation?.postalCode,
        },
      },
    })
  }, [currentOrganisation])

  const updateTotal = (price: number, term: number) => {
    switch (price) {
      case starter:
        if (term === year) {
          props.setPriceLookupKey('JAN_25_STARTER_YEARLY')
          setTotal(price * term * 0.8)
          props.setSeats(1)
        } else {
          props.setPriceLookupKey('JAN_25_STARTER')
          setTotal(price * term)
          props.setSeats(1)
        }
        break
      case creator:
        if (term === year) {
          props.setPriceLookupKey('JAN_25_ESSENTIAL_YEARLY')
          setTotal(price * term * 0.8)
        } else {
          props.setPriceLookupKey('JAN_25_ESSENTIAL')
          setTotal(price * term)
        }
        break
      case pro:
        if (term === year) {
          props.setPriceLookupKey('JAN_25_PRO_YEARLY')
          setTotal(price * term * 0.8)
        } else {
          props.setPriceLookupKey('JAN_25_PRO')
          setTotal(price * term)
        }
        break
      default:
        props.setPriceLookupKey('JAN_25_STARTER_YEARLY')
        setTotal(price * term * 0.8)
        break
    }
  }

  const handleActiveBilling = (price: number) => () => {
    setActiveBilling(price)
    updateTotal(price, props.term)
    props.setCheckout('checkout')
  }

  const handleBillingCycle = (checked: boolean) => {
    props.setTerm(checked ? year : month)
    updateTotal(activeBilling, checked ? year : month)
  }

  const handleDowngrade = () => {
    props.setCheckout('cancelconfirmation')
  }

  return (
    <>

      <Stack
        alignContent='center'
        alignItems='center'
        direction='column'
        justifyContent='center'
        padding={matches ? 2 : 0}
        spacing={4}
        width={1}
      >
        <Stack
          direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' }}
          maxWidth={1300}
          paddingX={{
            xl: 0,
            lg: 0,
            md: 2,
            sm: 0,
            xs: 0,
          }}
          spacing={2}
          width={1}
        >
          <Stack alignItems='center' direction='column' flexGrow={1} justifyContent='center' spacing={2} width={1}>
            <Stack alignItems='center' direction='column' flexGrow={1} justifyContent='center' spacing={1} width={1}>
              <Typography
                lineHeight={1}
                textAlign='center'
                variant={matches ? 'h2' : 'h1'}
              >
                {props.neverTrial && !props.previousTrial
                  ? 'Try all features now — Cancel anytime'
                  : 'Get full access to our powerful tools'}
              </Typography>
              <Typography color='textSecondary' textAlign='center' variant='body1'>
                Join thousands of artists and labels already using Releese
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' justifyContent='center' paddingBottom={2} spacing={1} width={1}>
              <Typography color='textSecondary'>
                Monthly
              </Typography>
              <Switch
                checked={props.term === year}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={event => handleBillingCycle(event.target.checked)}
              />
              <Typography color='textSecondary'>
                Annual
              </Typography>
            </Stack>
            <Chip
              color='primary'
              label='Save 20% on all annual plans'
              size='medium'
              sx={{
                marginTop: '0px!important',
              }}
            />
            <Stack
              maxWidth='1110px'
              spacing={matches ? 2 : 0}
              sx={{ flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' } }}
              width={1}
            >
              <Stack width={1}>
                <StarterCard
                  billingCycle={props.term}
                  handleBilling={handleActiveBilling}
                  month={month}
                  starter={starter}
                />
              </Stack>
              <Stack
                sx={{
                  marginX: { xl: 2, lg: 2, md: 2, sm: 0, xs: 0 },
                  marginY: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
                }}
                width={1}
              >
                <EssentialCard
                  billingCycle={props.term}
                  essential={creator}
                  handleBilling={handleActiveBilling}
                  month={month}
                />
              </Stack>
              <Stack width={1}>
                <ProCard
                  billingCycle={props.term}
                  handleBilling={handleActiveBilling}
                  month={month}
                  pro={pro}
                />
              </Stack>
            </Stack>
            {currentOrganisation && currentOrganisation?.membershipTier !== 'FREE' &&
            <Stack alignItems='center' justifyContent='center' width={1}>
              <Button
                color='inherit'
                onClick={() => handleDowngrade()}
                sx={{
                  color: theme => theme.palette.text.label,
                }}
                variant='text'
              >
                Cancel account and subscription
              </Button>
            </Stack>}
          </Stack>
        </Stack>
        <Stack
          alignItems='center'
          justifyContent='center'
          marginTop={4}
          paddingX={{
            xl: 0,
            lg: 0,
            md: 2,
            sm: 0,
            xs: 0,
          }}
          width={1}
        >
          <Card variant='outlined'>
            <Stack maxWidth='1110px' paddingX={2} width={1}>
              <Stack
                alignContent='center'
                alignItems='center'
                direction={{
                  xl: 'row',
                  lg: 'row',
                  md: 'row',
                  sm: 'column',
                  xs: 'column',
                }}
                spacing={2}
              >
                <Stack paddingY={4} spacing={2} width={1}>
                  <Stack width={1}>
                    <Typography variant='h2'>
                      Business
                    </Typography>
                    <Typography color={themeItem.palette.text.secondary} variant='body1'>
                      For teams that need centralized visibility, control, and support. Get Releese&apos;s
                      powerful, intuitive music business management platform with the most advanced and tailored
                      features and support that scale with you.
                    </Typography>
                  </Stack>
                  <Button
                    endIcon={<ArrowForwardRoundedIcon />}
                    href='https://tally.so/r/wMXpEA'
                    sx={{
                      color: 'white',
                      background: 'black',
                      ':hover': {
                        background: lighten('#000000', 0.1),
                      },
                      width: 'fit-content',
                    }}
                    target='_blank'
                  >
                    Contact us for pricing
                  </Button>
                  <Stack spacing={2}>
                    <Typography lineHeight={1} variant='h4'>
                      Everything in Pro, and
                    </Typography>
                    <Typography variant='body1'>

                      <span>
                        ✓
                      </span>
                      <span style={{ paddingLeft: 8 }}>
                        Keep 97.1% of earnings
                      </span>
                    </Typography>
                    <Typography variant='body1'>
                      <span>
                        ✓
                      </span>
                      <span style={{ paddingLeft: 8 }}>
                        Royalty recoups
                      </span>
                    </Typography>
                    <Typography variant='body1'>
                      <span>
                        ✓
                      </span>
                      <span style={{ paddingLeft: 8 }}>
                        Territory carving
                      </span>
                    </Typography>
                    <Typography variant='body1'>
                      <span>
                        ✓
                      </span>
                      <span style={{ paddingLeft: 8 }}>
                        Dedicated account manager
                      </span>
                    </Typography>
                    <Typography variant='body1'>
                      <span>
                        ✓
                      </span>
                      <span style={{ paddingLeft: 8 }}>
                        Custom user roles
                      </span>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack marginTop='auto' width={{ xl: '75%', lg: '75%', md: 1, sm: 1, xs: 1 }}>
                  <img
                    alt='hand drawing'
                    height={2000}
                    loading='lazy'
                    src={background}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    width={2000}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>

        <CompleteFeatureList
          featureGroup={pricingFeaturesList}
        />
      </Stack>
      <FeedbackModal
        open={form}
        setForm={setForm}
        setFormSubmitted={setFormSubmitted}
      />
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={() => setFormSubmitted(false)}
        open={formSubmitted}
      >
        <Alert
          onClose={() => setFormSubmitted(false)}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Thank you for your feedback
        </Alert>
      </Snackbar>
    </>
  )
}

export default PlanComponent
