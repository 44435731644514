import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PersonIcon from '@mui/icons-material/Person'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import { Avatar, Button, Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack, Switch, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import { DrawerState } from './MenuDrawer'
import type { themeSelector } from 'src/components/App'
import OrganizationSelect from 'src/components/form-elements/OrganizationSelectField'
import LogOutModal from 'src/components/pages/Menu/MenuModals/LogoutModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink, RouterLink } from 'src/components/SafeRouterLink'
import { Permissions } from 'src/models/Organisation'

type Props = {
  readonly toggleDrawer: (toggleState: DrawerState) => () => void
  readonly changeTheme: (theme: themeSelector) => void
  readonly currentTheme: string
}

const MenuProfile = (props: Props) => {
  const themeItem = useTheme()
  const [themeChecked, setThemeChecked] = useState(props.currentTheme === 'dark')
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState(0)
  const { currentAccount, currentAccountPermissions } = useAuth()

  const updateTheme = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThemeChecked(event.target.checked)
    if (event.target.checked) {
      props.changeTheme('dark')
    } else {
      props.changeTheme('light')
    }
  }

  return (
    <Stack
      alignItems='center'
      height={1}
      justifyContent='space-between'
      overflow='auto'
      paddingY={2}
      spacing={2}
      width={1}
    >
      <Stack alignItems='center' height={1} justifyContent='flex-start' spacing={2} width={1}>
        <Stack alignItems='center' spacing={1} width={1}>
          <Avatar
            src={currentAccount.profilePictureUrl}
            sx={{ height: 96, width: 96 }}
          />
          <Stack alignItems='center' sx={{ paddingX: 2 }} width={1}>
            <Typography textAlign='center' variant='body1'>
              {`${currentAccount.firstName} ${currentAccount.lastName}`}
            </Typography>
            <Typography color={themeItem.palette.text.secondary} textAlign='center' variant='body2'>
              {currentAccount.email}
            </Typography>
          </Stack>
        </Stack>
        <Stack alignItems='center' sx={{ paddingX: 2 }} width={1}>
          <OrganizationSelect />
        </Stack>
        <Divider sx={{ width: 1 }} />
        <List
          onClick={props.toggleDrawer(DrawerState.Closed)}
          sx={{
            paddingX: 2,
            '& .MuiListItemIcon-root': {
              marginRight: '16px!important',
              fontSize: '1.25rem',
            },
          }}
        >
          <RouterLink style={{ textDecoration: 'none' }} to='/settings/account/personalinformation'>
            <ListItemButton
              onClick={() => setSelectedMenuItem(1)}
              selected={selectedMenuItem === 1}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary='Account Settings' />
            </ListItemButton>
          </RouterLink>
          <RouterLink style={{ textDecoration: 'none' }} to='/settings/notifications'>
            <ListItemButton
              onClick={() => setSelectedMenuItem(2)}
              selected={selectedMenuItem === 2}
            >
              <ListItemIcon>
                <NotificationsRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Notifications' />
            </ListItemButton>
          </RouterLink>
          {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS)
            ? <NavLink style={{ textDecoration: 'none' }} to='/settings/profile/profile'>
              <ListItemButton >
                <ListItemIcon>
                  <AccountCircleRoundedIcon />
                </ListItemIcon>
                <ListItemText primary='Profile' />
              </ListItemButton>
            </NavLink>
            : <ListItemButton
              disabled
            >
              <ListItemIcon>
                <AccountCircleRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Profile' />
            </ListItemButton>}
          {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS)
            ? <NavLink style={{ textDecoration: 'none' }} to='/settings/general/details'>
              <ListItemButton >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary='Team Details' />
              </ListItemButton>
            </NavLink>
            : <ListItemButton
              disabled
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Team Details' />
            </ListItemButton>}
          {currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS)
            ? <NavLink style={{ textDecoration: 'none' }} to='/settings/team/members'>
              <ListItemButton >
                <ListItemIcon>
                  <PeopleAltRoundedIcon />
                </ListItemIcon>
                <ListItemText primary='Members' />
              </ListItemButton>
            </NavLink>
            : <ListItemButton
              disabled
            >
              <ListItemIcon>
                <PeopleAltRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Members' />
            </ListItemButton>}
          {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS)
            ? <NavLink style={{ textDecoration: 'none' }} to='/settings/marketing/domain'>
              <ListItemButton>
                <ListItemIcon>
                  <PhonelinkRoundedIcon />
                </ListItemIcon>
                <ListItemText primary='Smart Links Settings' />
              </ListItemButton>
            </NavLink>
            : <ListItemButton
              disabled
            >
              <ListItemIcon>
                <PhonelinkRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Smart Links Settings' />
            </ListItemButton>}
          {currentAccountPermissions?.includes(Permissions.MANAGE_BILLING)
            ? <NavLink
              style={{ textDecoration: 'none' }}
              to='/settings/billing/overview'
            >
              <ListItemButton >
                <ListItemIcon>
                  <PaymentRoundedIcon />
                </ListItemIcon>
                <ListItemText primary='Billing' />
              </ListItemButton>
            </NavLink>
            : <ListItemButton
              disabled
            >
              <ListItemIcon>
                <PaymentRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Billing' />
            </ListItemButton>}
          <a
            href='https://support.releese.io'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
            target='_blank'
          >
            <ListItemButton>
              <ListItemIcon>
                <HelpRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Support' />
            </ListItemButton>
          </a>
          <a
            href='https://releese.io/referral-program/'
            rel='noreferrer'
            style={{ textDecoration: 'none' }}
            target='_blank'
          >
            <ListItemButton>
              <ListItemIcon>
                <HandshakeRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Referral Program' />
            </ListItemButton>
          </a>
        </List>
      </Stack>
      <Stack alignItems='center' direction='row' spacing={2}>
        <LightModeRoundedIcon color='action' fontSize='large' />
        <Switch
          checked={themeChecked}
          inputProps={{ 'aria-label': 'controlled' }}
          onChange={updateTheme}
        />
        <DarkModeRoundedIcon color='action' fontSize='large' />
      </Stack>
      <Stack direction='column' justifyContent='flex-start' spacing={1} width={1}>
        <Divider sx={{ width: 1 }} />
        <Stack alignItems='center' direction='column' spacing={1}>
          <Stack width='fit-content'>
            <Button
              color='inherit'
              onClick={() => setOpenLogoutModal(true)}
              startIcon={<PowerSettingsNewRoundedIcon />}
              sx={{
                color: theme => theme.palette.text.primary,
              }}
              variant='text'
            >
              Log out
            </Button>
          </Stack>
          <Stack direction='row' justifyContent='center' spacing={1}>
            <Typography color='text.secondary' variant='helperText'>Member since: </Typography>
            <Typography color='text.secondary' variant='helperText'>
              {dayjs(currentAccount.createdAt).format('MMMM YYYY')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <LogOutModal
        close={() => setOpenLogoutModal(false)}
        open={openLogoutModal}
      />
    </Stack>
  )
}

export default MenuProfile
