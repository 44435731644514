import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded'
import { Avatar, CardActionArea, Paper, Stack, Typography, useTheme } from '@mui/material'

import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  setSelected: React.Dispatch<React.SetStateAction<number>>
  selected: number
}

const SmartLinkCard = (props: Props) => {
  const transitionMode = 'all 0.2s ease'
  const themeItem = useTheme()

  const { currentOrganisation } = useAuth()

  const disabled = !currentOrganisation ||
      currentOrganisation.membershipTier === 'FREE'
  return (
    <Paper
      elevation={0}
      sx={{
        background: 'transparent',
        border: theme => props.selected === 0
          ? `1px solid ${theme.palette.primary.main}`
          : '1px solid transparent',
        '&:hover': {
          cursor: 'pointer',
          transition: transitionMode,
          background: theme => theme.palette.primary.subtleResting,
        },
      }}
    >
      <CardActionArea
        disabled={disabled}
        onClick={() => props.setSelected(0)}
        sx={{ height: 1, borderRadius: 1 }}
      >
        <Stack alignItems='center' direction='column' padding={2} spacing={1} width={1}>
          <Stack alignItems='center' height='100%' justifyContent='center'>
            <Avatar
              sx={{
                background: theme => disabled
                  ? theme.palette.action.disabled
                  : theme.palette.primary.main,
                width: 96,
                height: 96,
              }}
            >
              <ViewWeekRoundedIcon
                sx={{
                  margin: 'auto',
                  fontSize: 64,
                  color: theme => theme.palette.primary.contrastText,
                  rotate: '90deg',
                }}
              />
            </Avatar>
          </Stack>
          <Stack alignItems='center' direction='column' spacing={0}>
            <Typography
              color={disabled
                ? themeItem.palette.text.disabled
                : themeItem.palette.text.primary}
              variant='h4'
            >
              Smart link
            </Typography>
            <Typography
              align='center'
              color={disabled
                ? themeItem.palette.text.disabled
                : themeItem.palette.text.secondary}
              variant='body2'
            >
              Create a landing page that lets your fans pick their preferred streaming service.
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Paper>
  )
}
export default SmartLinkCard
