/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable complexity */
import { faDailymotion, faThreads, faVimeo } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import FacebookIcon from '@mui/icons-material/Facebook'
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded'
import TextFieldsRoundedIcon from '@mui/icons-material/TextFieldsRounded'
import TitleRoundedIcon from '@mui/icons-material/TitleRounded'
import YouTubeIcon from '@mui/icons-material/YouTube'

import SevenDigitalLogo from 'src/components/Icons/7digitalLogo'
import AllSaintsLogo from 'src/components/Icons/AllSaintsLogo'
import AmazonMusicIcon from 'src/components/Icons/AmazonMusicIcon'
import AnghamiLogo from 'src/components/Icons/AnghamiLogo'
import AppleMusicIcon from 'src/components/Icons/AppleMusicIcon'
import AudiomackLogo from 'src/components/Icons/AudiomackLogo'
import AwaLogo from 'src/components/Icons/AwaLogo'
import BamdcampLogo from 'src/components/Icons/BandcampLogo'
import BandsintownIcon from 'src/components/Icons/Bandsintown'
import BeatportLogo from 'src/components/Icons/BeatportLogo'
import BoomplayLogo from 'src/components/Icons/BoomplayLogo'
import BugsLogo from 'src/components/Icons/BugsLogo'
import CapcutLogo from 'src/components/Icons/CapcutLogo'
import ClaroLogo from 'src/components/Icons/ClaroLogo'
import DeezerIcon from 'src/components/Icons/DeezerIcon'
import DiscordIcon from 'src/components/Icons/DiscordIcon'
import EmusicLogo from 'src/components/Icons/eMusicLogo'
import FloLogo from 'src/components/Icons/FloLogo'
import GenieMusicLogo from 'src/components/Icons/genieMusicLogo'
import GracenoteLogo from 'src/components/Icons/GracenoteLogo'
import HungamaLogo from 'src/components/Icons/HungamaLogo'
import IheartRadioLogo from 'src/components/Icons/IheartRadioLogo'
import ItunesLogo from 'src/components/Icons/ItunesLogo'
import JooxLogo from 'src/components/Icons/JooxLogo'
import KanjianLogo from 'src/components/Icons/Kanjian'
import KkboxLogo from 'src/components/Icons/KkboxLogo'
import KuackLogo from 'src/components/Icons/KuackLogo'
import LineMusicLogo from 'src/components/Icons/LineMusicLogo'
import MelonLogo from 'src/components/Icons/MelonIcon'
import MlcLogo from 'src/components/Icons/MLCLogo'
import NapsterLogo from 'src/components/Icons/NapsterLogo'
import NaverLogo from 'src/components/Icons/NaverLogo'
import NeteaseLogo from 'src/components/Icons/NeteaseLogo'
import NuudayLogo from 'src/components/Icons/NuudayLogo'
import OpenSeaIcon from 'src/components/Icons/OpenSeaIcon'
import PandoraIcon from 'src/components/Icons/PandoraIcon'
import QobuzLogo from 'src/components/Icons/QobuzLogo'
import QqIcon from 'src/components/Icons/QqIcon'
import QzoneIcon from 'src/components/Icons/QzoneIcon'
import SaavnLogo from 'src/components/Icons/SaavnLogo'
import SeatedLogo from 'src/components/Icons/seatedLogo'
import ShazamIcon from 'src/components/Icons/ShazamIcon'
import SnapchatIcon from 'src/components/Icons/SnapchatIcon'
import SongkickIcon from 'src/components/Icons/Songkick'
import SoundCloudIcon from 'src/components/Icons/SoundCloudIcon'
import SpotifyIcon from 'src/components/Icons/SpotifyIcon'
import TencentLogo from 'src/components/Icons/TencentLogo'
import TicketmasterIcon from 'src/components/Icons/Ticketmaster'
import TidalLogo from 'src/components/Icons/TidalLogo'
import TikTokIcon from 'src/components/Icons/TikTokIcon'
import TumblrIcon from 'src/components/Icons/TumblrIcon'
import TwitchIcon from 'src/components/Icons/TwitchIcon'
import TwitterIcon from 'src/components/Icons/TwitterIcon'
import VevoLogo from 'src/components/Icons/VevoLogo'
import VkIcon from 'src/components/Icons/VkIcon'
import WeChatIcon from 'src/components/Icons/WeChatIcon'
import WeiboIcon from 'src/components/Icons/WeiboIcon'
import ZingMp3Logo from 'src/components/Icons/ZingMp3Logo'

const GetIcon = (LinkName: string) => {
  switch (true) {
    case LinkName.toLowerCase().includes('overview'):
      return <AnalyticsRoundedIcon />
    case LinkName.toLowerCase().includes('catalog'):
      return <MusicNoteRoundedIcon />
    case LinkName.toLowerCase().includes('spotify'):
      return <SpotifyIcon />
    case LinkName.toLowerCase().includes('allsaints'):
      return <AllSaintsLogo />
    case LinkName.toLowerCase().includes('apple music'):
      return <AppleMusicIcon />
    case LinkName.toLowerCase().includes('apple_music'):
      return <AppleMusicIcon />
    case LinkName.toLowerCase().includes('itunes & apple music'):
      return <AppleMusicIcon />
    case LinkName.toLowerCase().includes('deezer'):
      return <DeezerIcon />
    case LinkName.toLowerCase().includes('amazon music'):
      return <AmazonMusicIcon />
    case LinkName.toLowerCase().includes('amazon'):
      return <AmazonMusicIcon />
    case LinkName.toLowerCase().includes('soundcloud'):
      return <SoundCloudIcon />
    case LinkName.toLowerCase().includes('tiktok'):
      return <TikTokIcon />
    case LinkName.toLowerCase().includes('youtube'):
      return <YouTubeIcon />
    case LinkName.toLowerCase().includes('itunes'):
      return <ItunesLogo />
    case LinkName.toLowerCase().includes('tidal'):
      return <TidalLogo />
    case LinkName.toLowerCase().includes('pandora'):
      return <PandoraIcon />
    case LinkName.toLowerCase().includes('audiomack'):
      return <AudiomackLogo />
    case LinkName.toLowerCase().includes('iheartradio'):
      return <IheartRadioLogo />
    case LinkName.toLowerCase().includes('vevo'):
      return <VevoLogo />
    case LinkName.toLowerCase().includes('joox'):
      return <JooxLogo />
    case LinkName.toLowerCase().includes('kkbox'):
      return <KkboxLogo />
    case LinkName.toLowerCase().includes('napster'):
      return <NapsterLogo />
    case LinkName.toLowerCase().includes('youtube music'):
      return <YouTubeIcon />
    case LinkName.toLowerCase().includes('youtube content id'):
      return <YouTubeIcon />
    case LinkName.toLowerCase().includes('beatport'):
      return <BeatportLogo />
    case LinkName.toLowerCase().includes('bandcamp'):
      return <BamdcampLogo />
    case LinkName.toLowerCase().includes('anghami'):
      return <AnghamiLogo />
    case LinkName.toLowerCase().includes('facebook'):
      return <FacebookIcon />
    case LinkName.toLowerCase().includes('instagram'):
      return <InstagramIcon />
    case LinkName.toLowerCase().includes('twitter'):
      return <TwitterIcon />
    case LinkName.toLowerCase().includes('7digital'):
      return <SevenDigitalLogo />
    case LinkName.toLowerCase().includes('bugs!'):
      return <BugsLogo />
    case LinkName.toLowerCase().includes('kuack media'):
      return <KuackLogo />
    case LinkName.toLowerCase().includes('nuuday'):
      return <NuudayLogo />
    case LinkName.toLowerCase().includes('claro-musica'):
      return <ClaroLogo />
    case LinkName.toLowerCase().includes('flo'):
      return <FloLogo />
    case LinkName.toLowerCase().includes('melon'):
      return <MelonLogo />
    case LinkName.toLowerCase().includes('naver'):
      return <NaverLogo />
    case LinkName.toLowerCase().includes('gracenote'):
      return <GracenoteLogo />
    case LinkName.toLowerCase().includes('kanjian'):
      return <KanjianLogo />
    case LinkName.toLowerCase().includes('qobuz'):
      return <QobuzLogo />
    case LinkName.toLowerCase().includes('tencent'):
      return <TencentLogo />
    case LinkName.toLowerCase().includes('awa'):
      return <AwaLogo />
    case LinkName.toLowerCase().includes('emusic'):
      return <EmusicLogo />
    case LinkName.toLowerCase().includes('netease'):
      return <NeteaseLogo />
    case LinkName.toLowerCase().includes('saavn'):
      return <SaavnLogo />
    case LinkName.toLowerCase().includes('discord'):
      return <DiscordIcon />
    case LinkName.toLowerCase().includes('qq'):
      return <QqIcon />
    case LinkName.toLowerCase().includes('qzone'):
      return <QzoneIcon />
    case LinkName.toLowerCase().includes('snapchat'):
      return <SnapchatIcon />
    case LinkName.toLowerCase().includes('tumblr'):
      return <TumblrIcon />
    case LinkName.toLowerCase().includes('twitch'):
      return <TwitchIcon />
    case LinkName.toLowerCase().includes('vk'):
      return <VkIcon />
    case LinkName.toLowerCase().includes('weibo'):
      return <WeiboIcon />
    case LinkName.toLowerCase().includes('wechat'):
      return <WeChatIcon />
    case LinkName.toLowerCase().includes('shazam'):
      return <ShazamIcon />
    case LinkName.toLowerCase().includes('bandsintown'):
      return <BandsintownIcon />
    case LinkName.toLowerCase().includes('songkick'):
      return <SongkickIcon />
    case LinkName.toLowerCase().includes('ticketmaster'):
      return <TicketmasterIcon />
    case LinkName.toLowerCase().includes('link'):
      return <LinkRoundedIcon />
    case LinkName.toLowerCase().includes('social'):
      return <AlternateEmailRoundedIcon />
    case LinkName.toLowerCase().includes('text'):
      return <TextFieldsRoundedIcon />
    case LinkName.toLowerCase().includes('video_embed'):
      return <OndemandVideoRoundedIcon />
    case LinkName.toLowerCase().includes('image'):
      return <ImageRoundedIcon />
    case LinkName.toLowerCase().includes('next_release'):
      return <SaveRoundedIcon />
    case LinkName.toLowerCase().includes('latest_release'):
      return <TableRowsRoundedIcon />
    case LinkName.toLowerCase().includes('header'):
      return <TitleRoundedIcon />
    case LinkName.toLowerCase().includes('chat'):
      return <ChatBubbleRoundedIcon />
    case LinkName.toLowerCase().includes('left_divider'):
      return <HorizontalRuleRoundedIcon />
    case LinkName.toLowerCase().includes('card_block'):
      return <IndeterminateCheckBoxRoundedIcon />
    case LinkName.toLowerCase().includes('daily_motion'):
      return <FontAwesomeIcon icon={faDailymotion} />
    case LinkName.toLowerCase().includes('vimeo'):
      return <FontAwesomeIcon icon={faVimeo} />
    case LinkName.toLowerCase().includes('threads'):
      return <FontAwesomeIcon icon={faThreads} />
    case LinkName.toLowerCase().includes('opensea'):
      return <OpenSeaIcon />
    case LinkName.toLowerCase().includes('seated'):
      return <SeatedLogo />
    case LinkName.toLowerCase().includes('line'):
      return <LineMusicLogo />
    case LinkName.toLowerCase().includes('boomplay'):
      return <BoomplayLogo />
    case LinkName.toLowerCase().includes('zing'):
      return <ZingMp3Logo />
    case LinkName.toLowerCase().includes('hungama'):
      return <HungamaLogo />
    case LinkName.toLowerCase().includes('genie'):
      return <GenieMusicLogo />
    case LinkName.toLowerCase().includes('download'):
      return <DownloadRoundedIcon />
    case LinkName.toLowerCase().includes('website'):
      return <LinkRoundedIcon />
    case LinkName.toLowerCase().includes('capcut'):
      return <CapcutLogo />
    case LinkName.toLowerCase().includes('mlc'):
      return <MlcLogo />
    default:
      return <LinkRoundedIcon />
  }
}

export default GetIcon
