/* eslint-disable max-len */
/* eslint-disable unicorn/prefer-string-slice */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Card, Chip, Divider, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import type { StripeError } from '@stripe/stripe-js'
import type { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import store from 'storejs'

import { createSubscription, setupIntent } from 'src/api/payment'
import { getTaxIds } from 'src/api/tax'
import TaxComponentCheckout from 'src/components/pages/Account/Billing/BillingComponents/TaxComponentCheckout'
import { useAuth } from 'src/components/providers/AuthProvider'
import { type PriceLookupKey, PRICE_LOOKUP_KEYS, PRICE_LOOKUP_KEYS_PRETTY } from 'src/models/Organisation'
import type { TaxId, UpcomingInvoice } from 'src/models/Payment'

type Props = {
  readonly selectedPrice: Exclude<PriceLookupKey, 'FREE'>
  readonly priceSelected: number | undefined
  readonly proratedPrice: UpcomingInvoice | undefined
  readonly term: number
  readonly seats: number
  readonly setCheckout: (checkout: string) => void
  readonly firstName: string
  readonly lastName: string
  readonly city: string
  readonly line1: string
  readonly region: string
  readonly postalCode: string
  readonly country: string
  readonly setFirstName: React.Dispatch<React.SetStateAction<string>>
  readonly setLastName: React.Dispatch<React.SetStateAction<string>>
  readonly setCity: React.Dispatch<React.SetStateAction<string>>
  readonly setLine1: React.Dispatch<React.SetStateAction<string>>
  readonly setRegion: React.Dispatch<React.SetStateAction<string>>
  readonly setPostalCode: React.Dispatch<React.SetStateAction<string>>
  readonly setCountry: React.Dispatch<React.SetStateAction<string>>
  readonly neverTrial: boolean
}

const CheckoutPage = (props: Props) => {
  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()
  const stripe = useStripe()
  const elements = useElements()
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))
  const locationStorage = store.get('location') as string | undefined

  const [loadingStripe, setLoadingStripe] = useState(false)

  const [clientSecret, setClientSecret] = useState<string | undefined>()

  const [cardNumberCompleted, setCardNumberCompleted] = useState(false)
  const [addressCompleted, setAddressCompleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [business, setBusiness] = useState(false)
  const [businessName, setBusinessName] = useState('')

  const [taxIds, setTaxIds] = useState<TaxId[]>([])

  const doesElementExist = !!elements

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_checkout',
        userId: currentOrganisation?.id,
        eventProps: {
          city: currentOrganisation?.city,
          country: currentOrganisation?.country,
          email: currentOrganisation?.organisationEmail,
          firstName: currentOrganisation?.firstName,
          lastName: currentOrganisation?.lastName,
          state: currentOrganisation?.region,
          zip: currentOrganisation?.postalCode,
        },
      },
    })
  }, [currentOrganisation])

  useEffect(() => {
    if (currentOrganisation) {
      setBusiness(currentOrganisation.business)
      setBusinessName(currentOrganisation.businessName)
    }
  }, [currentOrganisation])

  const getTaxCodes = async () => {
    await getTaxIds()
      .then(setTaxIds)
  }

  useEffect(() => {
    void getTaxCodes()
  }, [currentOrganisation?.id])

  const createSetupIntent = useCallback(async () => {
    try {
      // We go to stripe to initialise the Setup intent on the server side
      // This gets attached to the current stripe element
      const intent = await setupIntent()
      setClientSecret(intent.client_secret)
      stripe?.elements({
        clientSecret: intent.client_secret,
        loader: 'auto',
      })
    } catch (error: unknown) {
      setErrorMessage((error as StripeError).message ?? ' ')
      setLoadingStripe(false)
    }
  }, [])

  useEffect(() => {
    if (doesElementExist && props.proratedPrice) {
      elements.update({
        amount: props.proratedPrice.total <= 0 ? 0 : Math.trunc(props.proratedPrice.total * 100),
      })

      void createSetupIntent()
    }
  }, [doesElementExist, elements, props.proratedPrice, taxIds.length])

  const validateForm =
    addressCompleted &&
    cardNumberCompleted

  const getSubscriptionWithMethod = async (setupIntentId: string) =>
    createSubscription(
      props.selectedPrice,
      props.seats,
      setupIntentId,
      props.neverTrial,
    ).catch((error: AxiosError<Error>) => {
      setErrorMessage(error.response?.data.message ?? 'An error has occured while creating your subscription')
      setLoadingStripe(false)
    })

  const handleFormSubmit = async () => {
    if (!stripe) return
    setErrorMessage(' ')
    setLoadingStripe(true)
    try {
      if (!elements) {
        setErrorMessage('An error has occured while creating your subscription')
        setLoadingStripe(false)
        return
      }

      const submitResult = await elements.submit()

      if (submitResult.error?.message) {
        setErrorMessage(submitResult.error.message)
        setLoadingStripe(false)
        return
      }

      // After the user has confirmed the payment method, we create confirm the setupIntent, this handles 3DS as well
      const intent = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        clientSecret: clientSecret ?? '',
        confirmParams: {
          return_url: 'https://cloud.releese.io',
        },
      })

      if (intent.error?.message) {
        setErrorMessage(intent.error.message)
        setLoadingStripe(false)
        return
      }

      await updateCurrentOrganisation({
        ...props.firstName.trim().length > 0 && { firstName: props.firstName },
        ...props.lastName.trim().length > 0 && { lastName: props.lastName },
        addressLine1: props.line1,
        city: props.city,
        country: props.country,
        region: props.region,
        postalCode: props.postalCode,
        business,
        businessName,
      }).then(async () => {
        await refreshCurrentOrganisation()
      })

      // finally, the Subscription gets added
      const subscription = await getSubscriptionWithMethod(intent.setupIntent?.id ?? '')
      if (!subscription) return

      if (props.neverTrial && currentOrganisation) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'trial',
            userId: currentOrganisation.id,
            eventProps: {
              city: props.city,
              country: props.country,
              email: currentOrganisation.organisationEmail,
              firstName: props.firstName,
              lastName: props.lastName,
              state: props.region,
              zip: props.postalCode,
              value: props.priceSelected,
            },
          },
        })
      }

      if (subscription.pending_setup_intent !== null &&
        subscription.pending_setup_intent.status !== 'succeeded' &&
        intent.setupIntent?.payment_method) {
        const confirmSetupResult = await stripe.confirmSetup({
          clientSecret: subscription.pending_setup_intent.client_secret,
          confirmParams: {
            return_url: 'https://cloud.releese.io',
            payment_method: intent.setupIntent.payment_method as string,
          },
        })
        if (confirmSetupResult.error.message) {
          setErrorMessage(confirmSetupResult.error.message)
          setLoadingStripe(false)
          return
        }
      } else if (subscription.latest_invoice.payment_intent &&
        intent.setupIntent?.payment_method) {
        const confirmPaymentResult = await stripe.confirmPayment({
          clientSecret: subscription.latest_invoice.payment_intent.client_secret,
          confirmParams: {
            return_url: 'https://cloud.releese.io',
            payment_method: intent.setupIntent.payment_method as string,
          },
        })
        if (confirmPaymentResult.error.message) {
          setErrorMessage(confirmPaymentResult.error.message)
          setLoadingStripe(false)
          return
        }
      }

      props.setCheckout('subscribed')
      return void refreshCurrentOrganisation()
    } catch (error: unknown) {
      setErrorMessage((error as StripeError).message ?? ' ')
      setLoadingStripe(false)
    }
  }

  return <FormControl sx={{ width: 1, marginTop: '8px!important' }}>
    <Grid
      container
      marginLeft={matches ? '-16px!important' : '-32px!important'}
      spacing={matches ? 2 : 4}
      width={matches ? 'calc(100% + 16px)' : 'calc(100% + 32px)'}
    >
      <Grid item lg={7} md={7} sm={12} xl={7} xs={12}>
        <Card sx={{ overflow: 'visible', width: 1 }} variant='outlined'>
          <Stack padding={2} spacing={1} width={1}>
            <Typography variant='h3'>
              Billing details
            </Typography>
            <FormControl
              sx={{
                marginTop: '4px!important',
              }}
            >
              <RadioGroup
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                onChange={event => setBusiness(event.target.value === 'business')}
                row
                value={business ? 'business' : 'individual'}
              >
                <FormControlLabel control={<Radio />} label='Individual' value='individual' />
                <FormControlLabel control={<Radio />} label='Business' value='business' />
              </RadioGroup>
            </FormControl>
            {business &&
              <TextField
                label='Business name'
                onChange={event => setBusinessName(event.target.value)}
                placeholder='Business name'
                sx={{
                  marginTop: '24px!important',
                }}
                value={businessName}
              />}
            <AddressElement
              onChange={event => {
                setAddressCompleted(event.complete)
                props.setFirstName(event.value.firstName ?? event.value.name.substring(0, event.value.name.indexOf(' ')))
                props.setLastName(event.value.lastName ?? event.value.name.substring(event.value.name.indexOf(' ') + 1))
                props.setCity(event.value.address.city)
                props.setCountry(event.value.address.country)
                props.setLine1(event.value.address.line1)
                props.setPostalCode(event.value.address.postal_code)
                props.setRegion(event.value.address.state)
              }}
              options={{
                mode: 'billing',
                display: {
                  name: 'split',
                },
                contacts: [{
                  name: `${currentOrganisation?.firstName} ${currentOrganisation?.lastName}`,
                  address: {
                    line1: props.line1,
                    city: props.city,
                    country: props.country,
                    postal_code: props.postalCode,
                    state: props.region,
                  },
                },
                ],
                defaultValues: {
                  firstName: currentOrganisation?.firstName ?? '',
                  lastName: currentOrganisation?.lastName ?? '',
                  address: props.country.length === 0
                    ? {
                      country: locationStorage ?? '',
                    }
                    : {
                      line1: props.line1,
                      city: props.city,
                      country: props.country,
                      postal_code: props.postalCode,
                      state: props.region,
                    },
                },
              }}
            />
            <TaxComponentCheckout
              business={business}
              getTaxCodes={getTaxCodes}
              taxIds={taxIds}
            />
            <Typography
              sx={{
                marginTop: '16px!important',
              }}
              variant='h3'
            >
              Payment details
            </Typography>
            <PaymentElement
              onChange={event => {
                setCardNumberCompleted(event.complete)
              }}
            />
          </Stack>
        </Card>
      </Grid>
      <Grid item lg={5} md={5} sm={12} xl={5} xs={12}>
        <Card sx={{ width: 1 }} variant='outlined'>
          <Stack padding={2} spacing={2}>
            <Typography variant='h3'>
              Summary
            </Typography>
            {props.priceSelected &&
              <Stack direction='row' justifyContent='space-between' spacing={1}>
                <Stack direction='column'>
                  <Typography variant='body1'>
                    {PRICE_LOOKUP_KEYS_PRETTY[PRICE_LOOKUP_KEYS.indexOf(props.selectedPrice)]}
                  </Typography>
                  {props.term === 12 &&
                    <Typography color='textSecondary' variant='body1'>
                      Annual plan, save 20%
                    </Typography>}
                </Stack>
                <Stack alignItems='flex-end' direction='column'>
                  <Typography variant='body1'>
                    $
                    {props.term === 12
                      ? (props.priceSelected * 1.25).toFixed(2)
                      : props.priceSelected.toFixed(2)}
                  </Typography>

                  {props.term === 12 &&
                    <Typography
                      color='success'
                      variant='body1'
                    >
                      -$
                      {(props.priceSelected * 1.25 - props.priceSelected).toFixed(2)}
                    </Typography>}
                </Stack>
              </Stack>}
            <Divider />
            {props.proratedPrice?.discounts?.map(discount =>
              <Stack direction='row' justifyContent='space-between' key={discount.discount_name}>
                <Typography color='text.label' variant='body1'>
                  {discount.discount_name}
                </Typography>
                <Typography color='success' variant='body1'>
                  {discount.percent &&
                    discount.percent.toFixed(0)}
                  % off, forever
                </Typography>
              </Stack>)}

            {props.proratedPrice &&
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='body1'>
                  Subtotal
                </Typography>
                <Typography variant='body1'>
                  $
                  {`${props.proratedPrice.subtotal <= 0
                    ? '0.00'
                    : props.proratedPrice.subtotal.toFixed(2)}`}
                </Typography>
              </Stack>}

            {props.proratedPrice?.total_tax_amounts?.map(tax =>
              <Stack direction='row' justifyContent='space-between' key={tax.tax_rate}>
                <Typography color='text.label' variant='body1'>
                  {tax.tax_rate}
                </Typography>
                <Typography color='text.label' variant='body1'>
                  $
                  {`${tax.amount && tax.amount <= 0
                    ? '0.00'
                    : tax.amount?.toFixed(2)}`}
                </Typography>
              </Stack>)}
            {props.proratedPrice &&
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='body1'>
                  Total
                </Typography>
                <Typography variant='body1'>
                  $
                  {`${props.proratedPrice.total <= 0
                    ? '0.00'
                    : props.proratedPrice.total.toFixed(2)}`}
                </Typography>
              </Stack>}

            {props.proratedPrice &&
              <Stack direction='row' justifyContent='space-between'>
                <Typography lineHeight={1} variant='h4'>
                  Due today (USD)
                </Typography>
                <Typography lineHeight={1} variant='h4'>
                  $
                  {`${props.proratedPrice.due_today < 0 ? '0.00' : props.proratedPrice.due_today.toFixed(2)}`}
                </Typography>
              </Stack>}

            <Divider />

            <Stack direction='column' spacing={2}>
              {errorMessage.length > 0 &&
                <Typography color='error' variant='body2'>
                  {errorMessage}
                </Typography>}
              <LoadingButton
                color='success'
                disabled={!validateForm}
                fullWidth
                loading={loadingStripe}
                loadingPosition='start'
                onClick={handleFormSubmit}
                variant='contained'
              >
                {props.neverTrial
                  ? 'Confirm and start free trial'
                  : currentOrganisation?.trial
                    ? 'Update free trial'
                    : 'Confirm and pay'}
              </LoadingButton>
              <Stack alignItems='center' direction='row' justifyContent='space-between'>
                <Chip
                  icon={<LockRoundedIcon />}
                  label='Secure transaction'
                  size='small'
                  variant='subtle'
                />
                <Button
                  color='secondary'
                  onClick={() => props.setCheckout('plan')}
                  size='small'
                  variant='text'
                >
                  Change plan
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Card>
        {(currentOrganisation?.trial || currentOrganisation?.neverTrial) &&
          <Stack
            marginX='auto'
            paddingX={2}
            paddingY={1}
            spacing={1}
            width='fit-content'
          >
            <Stack alignItems='center' direction='row' spacing={1}>
              <CheckCircleRoundedIcon color='success' />
              <Typography color='textSecondary' textAlign='center' variant='body2'>
                No charge until your trial ends
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <CheckCircleRoundedIcon color='success' />
              <Typography color='textSecondary' textAlign='center' variant='body2'>
                Cancel anytime from your dashboard
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <CheckCircleRoundedIcon color='success' />
              <Typography color='textSecondary' textAlign='center' variant='body2'>
                Trusted by over 7,500 members
              </Typography>
            </Stack>
          </Stack>}
      </Grid>
    </Grid>
  </FormControl>
}
export default CheckoutPage
