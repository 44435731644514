/* eslint-disable max-lines */
/* eslint-disable no-case-declarations */
/* eslint-disable sonarjs/no-small-switch */
import AppleIcon from '@mui/icons-material/Apple'
import FacebookIcon from '@mui/icons-material/FacebookRounded'
import GoogleIcon from '@mui/icons-material/Google'
import { LoadingButton } from '@mui/lab'
import { Button, darken, Divider, lighten, Link, Stack, TextField, Typography, useTheme } from '@mui/material'
import type { MultiFactorError, MultiFactorResolver } from 'firebase/auth'
import { FacebookAuthProvider, getMultiFactorResolver, GoogleAuthProvider, OAuthProvider, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { useEffect, useRef, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useLocation } from 'react-router-dom'

import { verifyEmail } from 'src/api/accounts'
import { useAuth } from 'src/components/providers/AuthProvider'
import { RouterLink } from 'src/components/SafeRouterLink'
import firebaseErrorMessages from 'src/data/firebaseAuthErrorCodes'
import { auth } from 'src/utils/firebase'

const Login = () => {
  const themeHook = useTheme()
  const { search } = useLocation()
  const { refreshCurrentAccount } = useAuth()
  const [loginState, setLoginState] = useState('login')
  const [errorMessage, setErrorMessage] = useState<string>()

  const [loadingMfa, setLoadingMFA] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [resolverLogin, setResolverLogin] = useState<MultiFactorResolver>()
  const ref = useRef<HTMLDivElement | null>(null)
  const [captchaTest, setCaptchaTest] = useState<RecaptchaVerifier>()
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    if (ref.current && !captchaTest) {
      setCaptchaTest(new RecaptchaVerifier(ref.current, {
        size: 'invisible',
      }, auth))
    }
  }, [ref, captchaTest])

  useEffect(() => {
    if (captchaTest && !rendered) {
      void captchaTest.render()
        .then(() => {
          setRendered(true)
        })
    }
  }, [captchaTest, rendered])

  const handleMultiError = (error: MultiFactorError) => {
    switch (error.code) {
      case 'auth/multi-factor-auth-required':
        const resolver = getMultiFactorResolver(auth, error)
        if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          const recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha',
            {
              'size': 'invisible',
            },
            auth,
          )
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          }
          const phoneAuthProvider = new PhoneAuthProvider(auth)
          setResolverLogin(resolver)
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(setVerificationId)
            .then(() => setLoginState('mfa'))
        } else {
          setLoadingMFA(false)
          setErrorMessage(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
        }
        break
      default:
        setLoadingMFA(false)
        setErrorMessage(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
    }
  }

  const facebookLogin = () => {
    const facebookProvider = new FacebookAuthProvider()
    facebookProvider.addScope('email')
    void signInWithPopup(auth, facebookProvider)
      .then(async user => {
        if (!user.user.emailVerified && user.user.email) {
          await verifyEmail(user.user.email)
            .catch(() => null)
        }
        void refreshCurrentAccount()
      })
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const appleLogin = () => {
    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email')
    void signInWithPopup(auth, appleProvider)
      .then(refreshCurrentAccount)
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const googleLogin = () => {
    const googleProvider = new GoogleAuthProvider()
    void signInWithPopup(auth, googleProvider)
      .then(refreshCurrentAccount)
      .catch((error: MultiFactorError) => {
        void handleMultiError(error)
      })
  }

  const handleSubmit = async () => {
    if (captchaTest) {
      const valueCaptcha = await captchaTest.verify()
        .catch(() => {
          setLoading(false)
          setErrorMessage('Please complete the CAPTCHA to log in')
          return ''
        })
      if (valueCaptcha.length > 0) {
        setErrorMessage('')
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password)
          .then(refreshCurrentAccount)
          .catch((error: MultiFactorError) => {
            void handleMultiError(error)
          })
          .finally(() => setLoading(false))
      } else {
        setLoading(false)
        setErrorMessage('Please complete the CAPTCHA to log in')
      }
    }
  }

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      void handleSubmit()
    }
  }

  const handleMfa = () => {
    setErrorMessage('')
    setLoadingMFA(true)
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    if (resolverLogin) {
      resolverLogin.resolveSignIn(multiFactorAssertion)
        .then(refreshCurrentAccount)
        .catch((error: MultiFactorError) => {
          void handleMultiError(error)
          setLoadingMFA(false)
        })
    }
  }

  return <>
    <Stack alignItems='flext-start' width={1}>
      <Typography textAlign='left' variant='h3'>Log in</Typography>
      <Stack height={0} id='recaptcha' marginTop='0px!important' />
    </Stack>
    {(() => {
      switch (loginState) {
        case 'mfa':
          return <Stack alignItems='center' paddingBottom={2} spacing={3}>
            <Typography variant='body2'>
              Please enter the code that was sent your phone number, standard rates apply.
            </Typography>
            <form onSubmit={handleMfa}>
              <Stack alignItems='center' paddingBottom={2} spacing={3}>
                <OtpInput
                  focusStyle={{
                    borderColor: themeHook.palette.primary.main,
                    borderSize: '1px',
                    borderStyle: 'solid',
                    outline: 'none',
                  }}
                  hasErrored={!!errorMessage}
                  inputStyle={{
                    borderColor: themeHook.palette.border,
                    borderSize: '1px',
                    borderStyle: 'solid',
                    borderRadius: '8px',
                    width: '54px',
                    height: '54px',
                    fontSize: '1rem',
                    color: themeHook.palette.text.primary,
                    fontWeight: '400',
                    backgroundColor: themeHook.palette.background.input,
                  }}
                  isInputNum
                  numInputs={6}
                  onChange={(event: string) => setVerificationCode(event)}
                  separator={<span style={{ width: '8px' }} />}
                  shouldAutoFocus
                  value={verificationCode}
                />
                <LoadingButton
                  id='handle-login'
                  loading={loadingMfa}
                  onClick={handleMfa}
                  onSubmit={handleMfa}
                  type='submit'
                  variant='contained'
                >
                  Login
                </LoadingButton>
                {errorMessage &&
                <Typography color='error' variant='helperText'>{errorMessage}</Typography>}
              </Stack>
            </form>
          </Stack>
        default:
          return <>
            <Stack alignItems='center' direction='column' spacing={1} width={1}>
              <Button
                fullWidth
                onClick={facebookLogin}
                startIcon={<FacebookIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.facebook,
                  ':hover': {
                    backgroundColor: theme => darken(theme.palette.facebook, 0.2),
                  },
                }}
              >
                Continue with Facebook
              </Button>
              <Button
                fullWidth
                onClick={appleLogin}
                startIcon={<AppleIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.apple,
                  ':hover': {
                    backgroundColor: theme => lighten(theme.palette.apple, 0.2),
                  },
                }}
              >
                Sign in with Apple
              </Button>
              <Button
                fullWidth
                onClick={googleLogin}
                startIcon={<GoogleIcon />}
                sx={{
                  backgroundColor: theme => theme.palette.google,
                  ':hover': {
                    backgroundColor: theme => darken(theme.palette.google, 0.2),
                  },
                }}
              >
                Continue with Google
              </Button>
            </Stack>
            <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={1}>
              <Divider sx={{ flexGrow: 1 }} />
              <Typography variant='overline'>Or</Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Stack>
            <Stack paddingBottom={1} paddingTop={1} spacing={4} width={1}>
              <TextField
                error={!!errorMessage}
                helperText={errorMessage}
                label='Email'
                onChange={event_ => setEmail(event_.target.value)}
                onKeyPress={handleEnter}
                placeholder='example@email.com'
                required
                type='email'
                value={email}
              />
              <TextField
                error={!!errorMessage}
                label='Password'
                onChange={event_ => setPassword(event_.target.value)}
                onKeyPress={handleEnter}
                placeholder='Password'
                required
                type='password'
                value={password}
              />
            </Stack>
            <Stack spacing={2} width={1}>
              <LoadingButton
                id='handle-login'
                loading={loading}
                onClick={() => void handleSubmit()}
                variant='contained'
              >
                Login
              </LoadingButton>
              <Link component={RouterLink} to={`/account/lostpassword${search}`} variant='body2'>
                Forgot your password?
              </Link>

              <hr />
              <Typography color='textSecondary' variant='body2'>
                Don’t have an account?
              </Typography>
              <Button color='secondary' component={RouterLink} to={`/account/signup${search}`}>
                Sign Up
              </Button>
            </Stack>
            <Stack width={1}>
              <Stack id='recaptcha-container' ref={ref} />
            </Stack>
          </>
      }
    })()}

  </>
}

export default Login
