/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react/display-name */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { Fab, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { useRef, useState } from 'react'
import type { ListChildComponentProps } from 'react-window'
import { FixedSizeList as List } from 'react-window'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import SearchField from 'src/components/form-elements/SearchField'
import ConversationItem from 'src/components/pages/Messages/ConversationsPanel/ConversationItem'
import NewMessageModal from 'src/components/pages/Messages/NewMessageModal/NewMessageModal'
import { useMessenger } from 'src/components/providers/MessengerProvider'

const ConversationsPanel = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const { conversations } = useMessenger()
  const [newMessage, setNewMessage] = useState(false)
  const [searchText, setSearchText] = useState('')

  const filteredConversations = conversations
    .filter(item => item.lastMessage.content.length > 0)
    .sort((a, b) => +b.lastMessage.createdAt - +a.lastMessage.createdAt)

  const Row = ({ index, style }: ListChildComponentProps) => {
    const conversation = filteredConversations[index]
    return (
      <div style={{ ...style }}>
        <ConversationItem conversation={conversation} key={conversation.id} searchText={searchText} />
      </div>
    )
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const dimensions = useContainerDimensions(ref)

  return (
    <Stack
      sx={{ flexShrink: 0 }}
      width={matches ? 1 : 280}
    >
      <Stack alignItems='center' direction='row' padding={2} spacing={1}>
        <SearchField
          onChange={event => setSearchText(event.target.value)}
          sx={{ marginTop: 0, width: matches ? 1 : 280 }}
        />
        <Tooltip
          onClick={() => setNewMessage(true)}
          sx={{
            flexShrink: 0,
          }}
          title='New conversation'
        >
          <Fab color='primary' size='small'>
            <CreateRoundedIcon />
          </Fab>
        </Tooltip>
      </Stack>
      <Stack height={1} overflow='hidden' ref={ref} width={1}>
        <List
          direction='vertical'
          height={dimensions.height ?? 1}
          itemCount={filteredConversations.length ?? 0}
          itemSize={84}
          width='100%'
        >
          {Row}
        </List>
      </Stack>
      <NewMessageModal
        close={() => setNewMessage(false)}
        conversations={conversations.sort((a, b) =>
          +b.lastMessage.createdAt - +a.lastMessage.createdAt)}
        open={newMessage}
      />
    </Stack>
  )
}

export default ConversationsPanel
