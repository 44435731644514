/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/better-regex */
/* eslint-disable no-await-in-loop */
import { Stack, Table, TableCell, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import LatestTrackItemSearch from 'src/components/pages/Audience/Components/Latest Tracks/LatesTrackItemSearch'
import type { NewMusicFridayTopGainersnalytics } from 'src/models/AnalyticsCloud'

type Props = {
  topTracks: NewMusicFridayTopGainersnalytics[]
}

const TracksGain = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const topTracks = props.topTracks.filter(item => item._id !== null)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const activeItem = topTracks

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setPage(0)
  }, [rowsPerPage])

  return (
    <Stack width={1}>
      <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2} width={1}>
        <Typography variant='h3'>
          Latest
        </Typography>
      </Stack>
      <Stack
        direction='column'
        minWidth={0}
        padding={0}
        width={1}
      >
        <Table>
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                borderBottom: 2,
                borderBottomColor: 'divider',
                color: 'text.secondary',
              },
            }}
          >
            <TableRow>
              <TableCell
                align='left'
                padding='normal'
              >
                Song
              </TableCell>
              <TableCell
                align='left'
                padding='normal'
              >
                Virality
              </TableCell>
              {!mobile &&
              <>
                <TableCell
                  align='left'
                  padding='normal'
                >
                  Release date
                </TableCell>
                <TableCell
                  align='left'
                  padding='normal'
                >
                  Popularity
                </TableCell>
              </>}
            </TableRow>
          </TableHead>
          {activeItem
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(track =>
              <LatestTrackItemSearch
                key={track._id}
                track={track}
              />)}
        </Table>
        <TablePagination
          component='div'
          count={activeItem.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Stack>
    </Stack>
  )
}

export default TracksGain
