/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import { Card, CardActionArea, Paper, Stack, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Linkify from 'react-linkify'
import Markdown from 'react-markdown'

import AccountAvatar from 'src/components/AccountAvatar'
import GetLink from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/GetLink'
import ImageView from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/ImageView'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import Account from 'src/models/Account'
import type { Message } from 'src/models/Message'

type MessageElementProps = {
  readonly message: Message
  readonly time: Date
  readonly accounts: Account[]
}

const CorrespondantMessage = (props: MessageElementProps) => {
  const { currentAccount } = useAuth()
  const [imageOpen, setImageOpen] = useState(false)
  const { readMessage } = useMessenger()
  const { ref, inView } = useInView({ triggerOnce: true })

  useEffect(() => {
    if (inView && !props.message.readByAccountIds.includes(currentAccount.id)) {
      readMessage(props.message.id ?? '')
    }
  }, [inView])

  const foundAccount = props.accounts.find(item => item.id === props.message.senderId)

  return (
    <Stack
      direction='row'
      key={props.message.id ?? props.message.createdAt.toString()}
      ref={ref}
      spacing={1}
    >
      <Tooltip title={`${foundAccount?.firstName ?? 'Inactive'} ${foundAccount?.lastName ?? 'Member'}`}>
        <Stack>
          <AccountAvatar account={foundAccount ??  new Account({})} nolink size={42} />
        </Stack>
      </Tooltip>
      <Tooltip title={dayjs(props.time).format('llll')}>
        {(/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/).test(props.message.content)
          ? <Card elevation={0}>
            <CardActionArea onClick={() => setImageOpen(true)}>
              <img
                alt='Message'
                src={props.message.content}
                style={{ borderRadius: 8, boxShadow: 'none' }}
                width={312}
              />
            </CardActionArea>
            <ImageView close={() => setImageOpen(false)} image={props.message.content} open={imageOpen} />
          </Card>
          : (/(https|http)/).test(props.message.content)
            ? <Stack>
              <Paper
                elevation={0}
                sx={{
                  maxWidth: 336,
                  padding: 1.5,
                  backgroundColor: theme => theme.palette.background.input,
                }}
              >
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) =>
                  <a href={decoratedHref} key={key} rel='noreferrer' style={{ color: 'inherit' }} target='blank'>
                    {decoratedText}
                  </a>}
                >
                  <Typography
                    sx={{
                      wordWrap: 'break-word',
                      '& > :not(:last-child)': {
                        marginBottom: 1.5,
                      },
                      '& > *': {
                        marginTop: 0,
                        marginBottom: 0,
                      },
                    }}
                  >
                    <Markdown>
                      {props.message.content}
                    </Markdown>
                  </Typography>
                </Linkify>
              </Paper>
              {GetLink(props.message.content)}
            </Stack>
            : <Paper
              elevation={0}
              sx={{
                maxWidth: 336,
                padding: 1.5,
                backgroundColor: theme => theme.palette.background.input,
              }}
            >
              <Typography
                sx={{
                  wordWrap: 'break-word',
                  '& > :not(:last-child)': {
                    marginBottom: 1.5,
                  },
                  '& > *': {
                    marginTop: 0,
                    marginBottom: 0,
                  },
                }}
              >
                <Markdown>
                  {props.message.content}
                </Markdown>
              </Typography>
              {' '}
            </Paper>}
      </Tooltip>
    </Stack>
  )
}

export default CorrespondantMessage
