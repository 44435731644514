import { Button, Card, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { getPaymentMethods } from 'src/api/payout'
import EarningsDistribution from 'src/components/pages/Earnings/EarningsTabs/EarningsDistribution'
import PaymentTable from 'src/components/pages/Wallet/PaymentItems/PaymentTable'
import PayoutMethodModal from 'src/components/pages/Wallet/PayoutMethods/PayoutMethodModal'
import WithdrawModal from 'src/components/pages/Wallet/WithdrawModal/WithdrawModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { PayoutMethod } from 'src/models/Payout'

const Wallet = () => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentAccount, currentOrganisation } = useAuth()
  const [withdrawModal, setWithdrawModal] = useState(false)
  const [payoutMethodModal, setPayoutMethodModal] = useState(false)
  const [payoutMethods, setPayoutMethods] = useState<PayoutMethod[]>()
  const [refreshMethods, setRefreshMethods] = useState(false)

  useEffect(() => {
    void getPaymentMethods()
      .then(setPayoutMethods)
  }, [currentOrganisation?.id])

  useEffect(() => {
    void getPaymentMethods()
      .then(setPayoutMethods)
  }, [refreshMethods])

  const balance =
    (currentOrganisation?.balance ?? 0) < 0 &&
    (currentOrganisation?.balance ?? 0) > -0.1
      ? 0
      : currentOrganisation?.balance

  return (
    <Stack
      alignItems='center'
      height={1}
      overflow='auto'
      sx={{
        paddingBottom: 1.5,
        paddingRight: 1.5,
        paddingLeft: {
          xl: 0,
          lg: 0,
          md: 0,
          sm: 1.5,
          xs: 1.5,
        },
        paddingTop: {
          xl: 0,
          lg: 0,
          md: 0,
          sm: 1.5,
          xs: 1.5,
        },
      }}
      width={1}
    >
      <Stack alignItems='center' width={1}>
        {withdrawModal &&
        <WithdrawModal
          close={() => setWithdrawModal(false)}
          open={withdrawModal}
        />}
        {payoutMethodModal &&
        <PayoutMethodModal
          close={() => setPayoutMethodModal(false)}
          open={payoutMethodModal}
          refresh={() => setRefreshMethods(old => !old)}
        />}
        <Stack direction='column' paddingBottom={2} spacing={2} width={1}>
          <Stack direction={mobile ? 'column' : 'row'} spacing={2} width={1}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: 1,
                width: mobile ? '100%' : 'fit-content',
              }}
            >
              <Stack padding={2}>
                <Stack alignItems='flex-start' spacing={1}>
                  <Typography
                    lineHeight={1.2}
                    textAlign='left'
                    variant='h1'
                  >
                    Wallet
                  </Typography>
                  <Stack alignItems={mobile ? 'center' : 'flex-start'} spacing={1} width={1}>
                    <Card
                      elevation={0}
                      sx={{
                        background: 'linear-gradient(150deg, rgba(251,66,131,1) 0%, rgba(255,144,46,1) 91%)',
                        width: 280,
                      }}
                    >
                      <Stack padding={2} spacing={2}>
                        <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='body3'>
                          RELEESE INNOVATIONS
                        </Typography>
                        <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='h2'>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                            .format(balance ?? 0)}
                        </Typography>
                        <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='body3'>
                          {'Member since '}
                          {dayjs(currentAccount.createdAt).format('MMMM YYYY')}
                        </Typography>
                      </Stack>
                    </Card>
                    <Tooltip
                      title={currentOrganisation &&
                        currentOrganisation.balance < 5
                        ? 'The minimum amount you can withdraw is $5.00'
                        : ''}
                    >
                      <div>
                        <Button
                          color='success'
                          disabled={payoutMethods?.length === 0 ||
                            !currentOrganisation?.balance ||
                            currentOrganisation.balance < 5}
                          onClick={() => setWithdrawModal(true)}
                          sx={{
                            width: 280,
                          }}
                        >
                          Withdraw
                        </Button>
                      </div>
                    </Tooltip>
                    <Button
                      color='inherit'
                      onClick={() => setPayoutMethodModal(true)}
                      rounded
                      sx={{
                        width: 280,
                      }}
                      variant='outlined'
                    >
                      {payoutMethods?.length === 0
                        ? 'Create Payout Method'
                        : 'Manage Payout Methods'}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
            <PaymentTable />
          </Stack>
        </Stack>
        <Paper
          elevation={0}
          sx={{
            borderRadius: 1,
            width: '100%',
          }}
        >
          <Stack width={1}>
            <EarningsDistribution />
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}

export default Wallet

