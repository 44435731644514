/* eslint-disable react-hooks/exhaustive-deps */
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PersonIcon from '@mui/icons-material/Person'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import { Avatar, Box, Card, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Stack, useMediaQuery, useTheme } from '@mui/material'
import { Route, Routes, useLocation } from 'react-router-dom'

import Account from 'src/components/pages/Settings/Account/Account'
import Billing from 'src/components/pages/Settings/Billing/Billing'
import General from 'src/components/pages/Settings/General/General'
import MarketingSettings from 'src/components/pages/Settings/Marketing/Marketing'
import Notifications from 'src/components/pages/Settings/Notifications/Notifications'
import ProfileSettings from 'src/components/pages/Settings/Profile/Profile'
import Team from 'src/components/pages/Settings/Team/Team'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import TeamAvatar from 'src/components/TeamAvatar'
import { Permissions } from 'src/models/Organisation'
import SPACING from 'src/styles/spacing'

const Settings = () => {
  const { currentAccount, currentOrganisation, currentAccountPermissions } = useAuth()
  const location = useLocation()
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('lg'))

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        height: 1,
        width: 1,
        overflow: 'visible',
        padding: SPACING.RESPONSIVE_CONTOUR,
      }}
    >
      {!matches &&
      <Card
        sx={{
          flexShrink: 0,
          width: {
            xl: 320,
            lg: 320,
            md: 320,
            sm: 240,
            xs: 240,
          },
          borderRadius: {
            xl: 1,
            lg: 1,
            md: 1,
            sm: 0,
            xs: 0,
          },
          padding: 1,
        }}
      >
        <Stack alignItems='center' direction='row' padding={1} spacing={3}>
          <Stack width={1}>
            <Stack>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    src={currentAccount.profilePictureUrl}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${currentAccount.firstName} ${currentAccount.lastName}`}
                  secondary={currentAccount.email}
                  sx={{ lineBreak: 'auto' }}
                />
              </ListItem>
              <List
                sx={{
                  '& .MuiListItemIcon-root': {
                    marginRight: '16px!important',
                    fontSize: '1.25rem',
                  },
                }}
              >
                <NavLink style={{ textDecoration: 'none' }} to='/settings/account/personalinformation'>
                  <ListItemButton
                    selected={location.pathname.includes('account')}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary='Account Settings' />
                  </ListItemButton>
                </NavLink>
                <NavLink style={{ textDecoration: 'none' }} to='/settings/notifications'>
                  <ListItemButton
                    selected={location.pathname.includes('notifications')}
                  >
                    <ListItemIcon>
                      <NotificationsRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Notifications' />
                  </ListItemButton>
                </NavLink>
              </List>

              <List
                sx={{
                  '& .MuiListItemIcon-root': {
                    marginRight: '16px!important',
                    fontSize: '1.25rem',
                  },
                }}
              >
                <Stack paddingBottom={1} width={1}>
                  <ListItem>
                    <ListItemAvatar>
                      <TeamAvatar
                        organisation={currentOrganisation}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={currentOrganisation?.name}
                      secondary={`Team with ${currentOrganisation?.seats.length} 
                      member${currentOrganisation?.seats && currentOrganisation.seats.length > 1 ? 's' : ''}`}
                      sx={{ lineBreak: 'auto' }}
                    />
                  </ListItem>
                </Stack>
                {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS)
                  ? <NavLink style={{ textDecoration: 'none' }} to='/settings/profile/profile'>
                    <ListItemButton
                      selected={location.pathname.includes('profile')}
                    >
                      <ListItemIcon>
                        <AccountCircleRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Profile' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <AccountCircleRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Profile' />
                  </ListItemButton>}
                {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS)
                  ? <NavLink style={{ textDecoration: 'none' }} to='/settings/general/details'>
                    <ListItemButton
                      selected={location.pathname.includes('general')}
                    >
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary='Team Details' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary='Team Details' />
                  </ListItemButton>}
                {currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS)
                  ? <NavLink style={{ textDecoration: 'none' }} to='/settings/team/members'>
                    <ListItemButton
                      selected={location.pathname.includes('team')}
                    >
                      <ListItemIcon>
                        <PeopleAltRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Members' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <PeopleAltRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Members' />
                  </ListItemButton>}
                {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS)
                  ? <NavLink style={{ textDecoration: 'none' }} to='/settings/marketing/domain'>
                    <ListItemButton
                      selected={location.pathname.includes('marketing')}
                    >
                      <ListItemIcon>
                        <PhonelinkRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Smart Links Settings' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <PhonelinkRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Smart Links Settings' />
                  </ListItemButton>}
                {currentAccountPermissions?.includes(Permissions.MANAGE_BILLING)
                  ? <NavLink
                    style={{ textDecoration: 'none' }}
                    to='/settings/billing/overview'
                  >
                    <ListItemButton
                      selected={location.pathname.includes('billing')}
                    >
                      <ListItemIcon>
                        <PaymentRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Billing' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <PaymentRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Billing' />
                  </ListItemButton>}
              </List>
            </Stack>
          </Stack>
        </Stack>
      </Card>}
      <Card
        sx={{
          flexGrow: 1,
          marginLeft:
          {
            xl: 2,
            lg: 2,
            md: 0,
            sm: 0,
            xs: 0,
          },
          borderRadius:
          {
            xl: 1,
            lg: 1,
            md: 1,
            sm: 0,
            xs: 0,
          },
          minHeight: 0.75,
          width: 500,
          maxHeight: 1,
          height: 1,
          overflow: 'hidden',
        }}
      >
        <Stack height={1} overflow='hidden' width={1} >
          <Routes>
            <Route element={<Account />} path='/account/*' />
            <Route element={<Notifications />} path='/notifications' />
            {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS) &&
            <Route element={<ProfileSettings />} path='/profile/*' />}
            {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS) &&
            <Route element={<General />} path='/general/*' />}
            {currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS) &&
            <Route element={<Team />} path='/team/*' />}
            {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS) &&
            <Route element={<MarketingSettings />} path='/marketing/*' />}
            {currentAccountPermissions?.includes(Permissions.MANAGE_BILLING) &&
            <Route element={<Billing />} path='/billing/*' />}
          </Routes>
        </Stack>
      </Card>
    </Box>
  )
}
export default Settings
