/* eslint-disable react-hooks/exhaustive-deps */
import { EventType, UpolloClient } from '@upollo/web'
import mixpanel from 'mixpanel-browser'
import type { FC } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'

import type { EarlyAuth } from 'src/components/providers/AuthProvider'
import { useAuth } from 'src/components/providers/AuthProvider'

type GtmContextProps = {
  gtmPixel?: string
  setGtmPixel: (gtmTag: string) => void
  upollo: UpolloClient
}

export type InitProps = Omit<GtmContextProps,
'currentTime' | 'playing' | 'recording'> & {
  gtmPixel: GtmContextProps['gtmPixel'] | null | undefined
}

const initialValue = {
  gtmPixel: undefined,
} as InitProps

const GtmContext = createContext(initialValue as GtmContextProps)

export const useGtm = () => useContext(GtmContext)

export const GtmProvider: FC = props => {
  const [gtmPixel, setGtmPixel] = useState<InitProps['gtmPixel']>()
  const { currentOrganisation } = useAuth() as EarlyAuth

  // eslint-disable-next-line max-len
  const upollo = new UpolloClient('iLFxZD3GX95aFEFdtXCzk3Fb8hKBMtenjKmhGGBN28uh6425564d1a3e6cf4ad9a0bdbaf4c771b6433b9a7d648638bf7299aa2b054bc8f')

  mixpanel.init('01b2a9129dee562e1f7ecfad962ed146',
    {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
      record_sessions_percent: 5,
    })

  useEffect(() => {
    if (currentOrganisation) {
      void upollo.track({
        userId: currentOrganisation.id,
        userEmail: currentOrganisation.organisationEmail,
        userName: currentOrganisation.name,
      }, EventType.EVENT_TYPE_LOGIN_SUCCESS)
    }
  }, [currentOrganisation])

  useEffect(() => {
    if (currentOrganisation && gtmPixel) {
      mixpanel.identify(currentOrganisation.id)
    }
  }, [currentOrganisation?.id])

  useEffect(() => {
    if (!gtmPixel) {
      const tagmanagerArguments = {
        gtmId: process.env.REACT_APP_GTM ?? '',
      }
      TagManager.initialize(tagmanagerArguments)
      setGtmPixel(process.env.REACT_APP_GTM ?? '')
      mixpanel.init('01b2a9129dee562e1f7ecfad962ed146',
        {
          debug: false,
          track_pageview: true,
          persistence: 'localStorage',
          record_sessions_percent: 5,
        })
    }
  }, [gtmPixel])

  useEffect(() => {
    if (gtmPixel && currentOrganisation) {
      TagManager.dataLayer({
        dataLayer: {
          userId: currentOrganisation.id,
        },
      })
    }
  }, [currentOrganisation?.id, gtmPixel])

  const value = useMemo<InitProps>(
    () => ({
      gtmPixel,
      setGtmPixel,
      upollo,
    }),
    [gtmPixel]
  ) as GtmContextProps

  return (
    <GtmContext.Provider value={value}>
      {props.children}
    </GtmContext.Provider>
  )
}
