/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton, Stack } from '@mui/material'
import { EventType, FlagType, UpolloClient } from '@upollo/web'
import { useEffect, useState } from 'react'

import { onboardingEvent } from 'src/api/organisation'
import SubscriptionCancelled from 'src/components/pages/Account/Billing/BillingComponents/SubscriptionCancelled'
import SubscriptionCreated from 'src/components/pages/Account/Billing/BillingComponents/SubscriptionCreated'
import CancelConfirmation from 'src/components/pages/Account/Billing/CancelConfirmation'
import CheckoutFormComponent from 'src/components/pages/Account/Billing/CheckoutFormComponent'
import PlanComponent from 'src/components/pages/Account/Billing/PlanComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Organisation, PriceLookupKey } from 'src/models/Organisation'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly account: Organisation
  readonly price?: PriceLookupKey
}

const BillingComponent = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [priceLookupKey, setPriceLookupKey] = useState<PriceLookupKey>(props.price ?? 'JAN_25_STARTER_YEARLY')
  const [term, setTerm] = useState(12)
  const [seats, setSeats] = useState(1)
  const [checkout, setCheckout] = useState('plan')
  const [loading, setLoading] = useState(false)
  const [previousTrial, setPreviousTrial] = useState(false)

  const checkAbuse = async () => {
    if (currentOrganisation) {
      setLoading(true)
      // eslint-disable-next-line max-len
      const upollo = new UpolloClient('iLFxZD3GX95aFEFdtXCzk3Fb8hKBMtenjKmhGGBN28uh6425564d1a3e6cf4ad9a0bdbaf4c771b6433b9a7d648638bf7299aa2b054bc8f')
      await upollo.assess({
        userId: currentOrganisation.id,
        userEmail: currentOrganisation.organisationEmail,
        userName: currentOrganisation.name,
      }, EventType.EVENT_TYPE_REDEEMED_OFFER)
        .then(analysis => {
          const flagTypes = new Set(analysis.flags.map(flag => flag.type))
          if (flagTypes.has(FlagType.TRIALED_ON_OTHER_ACCOUNT) || flagTypes.has(FlagType.BLACKLISTED_IP)) {
            setPreviousTrial(true)
          }
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (props.open) {
      void onboardingEvent('View plans')
    }
  }, [props.open])

  useEffect(() => {
    void checkAbuse()
  }, [currentOrganisation?.id])

  const neverTrial = currentOrganisation?.neverTrial === true && !previousTrial

  return (
    <Stack alignItems='center' width={1}>
      {loading
        ? <Stack
          alignItems='center'
          direction='column'
          height={1}
          justifyContent='center'
          paddingBottom={3}
          spacing={1}
          sx={{
            background: theme => theme.palette.background.input,
          }}
          width={1}
        >
          <Skeleton variant='rounded' />
        </Stack>
        : <Stack
          alignItems='center'
          direction='column'
          height={1}
          justifyContent='center'
          paddingBottom={3}
          spacing={1}
          sx={{
            background: theme => theme.palette.background.input,
          }}
          width={1}
        >
          {(() => {
            switch (checkout) {
              case 'plan':
                return <PlanComponent
                  neverTrial={neverTrial}
                  previousTrial={previousTrial}
                  setCheckout={setCheckout}
                  setPriceLookupKey={setPriceLookupKey}
                  setSeats={setSeats}
                  setTerm={setTerm}
                  term={term}
                />
              case 'checkout':
                return <Stack
                  alignItems='center'
                  height={1}
                  justifyContent='center'
                  maxWidth={1024}
                  paddingX={2}
                  width={1}
                >
                  <CheckoutFormComponent
                    account={props.account}
                    neverTrial={neverTrial}
                    priceLookupKey={priceLookupKey}
                    seats={seats}
                    setCheckout={setCheckout}
                    term={term}
                  />
                </Stack>
              case 'cancelconfirmation':
                return <Stack height={1} maxWidth={1024} paddingX={3} width={1}>
                  <CancelConfirmation
                    setCheckout={setCheckout}
                    setPriceLookupKey={setPriceLookupKey}
                  />
                </Stack>
              case 'cancelled':
                return <Stack height={1} maxWidth={1024} paddingX={3} width={1}>
                  <SubscriptionCancelled />
                </Stack>
              case 'subscribed':
                return priceLookupKey !== 'FREE'
                  ? <Stack height={1} maxWidth={1024} paddingX={3} width={1}>
                    <SubscriptionCreated
                      close={props.close}
                    />
                  </Stack>
                  : <Stack height={1} maxWidth={1024} paddingX={3} width={1}>
                    <SubscriptionCancelled />
                  </Stack>
                // eslint-disable-next-line sonarjs/no-duplicated-branches
              default:
                return <PlanComponent
                  neverTrial={neverTrial}
                  previousTrial={previousTrial}
                  setCheckout={setCheckout}
                  setPriceLookupKey={setPriceLookupKey}
                  setSeats={setSeats}
                  setTerm={setTerm}
                  term={term}
                />
            }
          })()}
        </Stack>}
    </Stack>
  )
}

export default BillingComponent
