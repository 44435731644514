/* eslint-disable @typescript-eslint/no-non-null-assertion */
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Grid, Stack, Typography } from '@mui/material'
import type { AuthError } from 'firebase/auth'
import { multiFactor } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { resetPassword, verifyEmail } from 'src/api/accounts'
import EmailPasswordResetModal from 'src/components/pages/Settings/Account/AccountTabs/AccountComponents/EmailPasswordResetModal'
import EmailVerificationSentModal from 'src/components/pages/Settings/Account/AccountTabs/AccountComponents/EmailVerificationSentModal'
import MfaModal from 'src/components/pages/Settings/Account/AccountTabs/AccountComponents/MfaModal'
import MfaRemoveMfaModal from 'src/components/pages/Settings/Account/AccountTabs/AccountComponents/MfaRemoveMfaModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import firebaseErrorMessages from 'src/data/firebaseAuthErrorCodes'
import { auth } from 'src/utils/firebase'

const widthFormat = 'fit-content'

const Security = () => {
  const { currentFirebaseUser } = useAuth()
  const [openMfaModal, setMfaModalOpen] = useState(false)
  const [openRemoveMfaModal, setRemoveMfaModalOpen] = useState(false)
  const [openEmailVerificationModal, setOpenEmailVerificationModal] = useState(false)
  const [openEmailPasswordResetModal, setOpenEmailPasswordResetModal] = useState(false)
  const [loadingReset, setLoadingReset] = useState(false)
  const [errorMessageReset, setErrorMessageReset] = useState('')
  const [twoFaEnabled, setTwoFaEnabled] = useState(false)

  const findMultiUser = () => {
    setTwoFaEnabled(multiFactor(auth.currentUser!).enrolledFactors.length > 0)
  }

  useEffect(() => {
    findMultiUser()
  }, [])

  const handlePasswordReset = () => {
    if (currentFirebaseUser.email) {
      setLoadingReset(true)
      void resetPassword(currentFirebaseUser.email)
        .then(() => {
          setLoadingReset(false)
          setOpenEmailPasswordResetModal(true)
        })
        .catch((error: AuthError) =>  {
          setErrorMessageReset(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)!.message)
          setLoadingReset(false)
        })
    }
  }

  return (
    <Stack direction='column' paddingX={2} paddingY={2} spacing={2}>
      <Typography variant='h3'>Security</Typography>
      <Stack direction='row' spacing={3}>
        <Grid container width={1}>
          <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
            <Typography variant='body1' width={192}>
              Verify your email
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xl={8} xs={12}>
            <Stack direction='column' spacing={1}>
              <LoadingButton
                color='success'
                disabled={currentFirebaseUser.emailVerified || !currentFirebaseUser.email}
                endIcon={<EmailRoundedIcon />}
                onClick={() => {
                  if (currentFirebaseUser.email) {
                    void verifyEmail(currentFirebaseUser.email)
                      .then(() => setOpenEmailVerificationModal(true))
                      .catch(() => null)
                  }
                }}
                rounded
                sx={{
                  width: widthFormat,
                }}
                variant='subtle'
              >
                Send verification link
              </LoadingButton>
              <Typography
                color='textSecondary'
                maxWidth={480}
                variant='helperText'
              >
                {!currentFirebaseUser.emailVerified &&
            'You will receive an email with instructions on how to verify your email.'}
                {currentFirebaseUser.emailVerified &&
            'Your email is verified.'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <Stack direction='row' spacing={3}>
        <Grid container width={1}>
          <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
            <Typography variant='body1' width={192}>
              Reset your password
            </Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xl={8} xs={12}>
            <Stack direction='column' spacing={1}>
              <LoadingButton
                endIcon={<VpnKeyRoundedIcon />}
                loading={loadingReset}
                onClick={handlePasswordReset}
                rounded
                sx={{
                  width: widthFormat,
                }}
                variant='contained'
              >
                Reset my password
              </LoadingButton>
              <Typography
                color='textSecondary'
                maxWidth={480}
                variant='helperText'
              >
                You will receive an email with instructions on how to reset your password,
                you will be able to login with the new password once you logout.
              </Typography>
              {errorMessageReset &&
              <Typography color='error'>{errorMessageReset}</Typography>}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {!twoFaEnabled &&
        <Stack direction='row' spacing={3}>
          <Grid container width={1}>
            <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
              <Typography variant='body1' width={192}>
                Enable two-factor authentication
              </Typography>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xl={8} xs={12}>
              <Stack direction='column' spacing={1}>
                <Button
                  disabled={!currentFirebaseUser.emailVerified}
                  endIcon={<LockRoundedIcon />}
                  onClick={() => setMfaModalOpen(true)}
                  rounded
                  sx={{
                    width: widthFormat,
                  }}
                  variant='contained'
                >
                  Setup two-factor authentication
                </Button>
                <Typography
                  color='textSecondary'
                  maxWidth={480}
                  variant='helperText'
                >
                  {!currentFirebaseUser.emailVerified &&
            'Please verify your email before setting up 2FA.'}
                  {currentFirebaseUser.emailVerified &&
            'For extra security, this requires you to log out and login to setup.'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>}
      {twoFaEnabled &&
        <Stack direction='row' spacing={3}>
          <Grid container width={1}>
            <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
              <Typography variant='body1' width={192}>
                Disable two-factor authentication
              </Typography>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xl={8} xs={12}>
              <Stack direction='column' spacing={1}>
                <Button
                  color='error'
                  endIcon={<LockRoundedIcon />}
                  onClick={() => setRemoveMfaModalOpen(true)}
                  rounded
                  sx={{
                    width: widthFormat,
                  }}
                  variant='contained'
                >
                  Remove two-factor authentication
                </Button>
                <Typography
                  color='textSecondary'
                  maxWidth={480}
                  variant='helperText'
                >
                  For extra security, this requires you to confirm your login to continue.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>}

      {openMfaModal &&
      <MfaModal
        close={() => {
          setMfaModalOpen(false)
          findMultiUser()
        }}
        open={openMfaModal}
      />}
      {openRemoveMfaModal &&
      <MfaRemoveMfaModal
        close={() => {
          setRemoveMfaModalOpen(false)
          findMultiUser()
        }}
        open={openRemoveMfaModal}
      />}
      {openEmailVerificationModal &&
      <EmailVerificationSentModal
        close={() => {
          setOpenEmailVerificationModal(false)
        }}
        open={openEmailVerificationModal}
      />}
      {openEmailPasswordResetModal &&
      <EmailPasswordResetModal
        close={() => {
          setOpenEmailPasswordResetModal(false)
        }}
        open={openEmailPasswordResetModal}
      />}
    </Stack>
  )
}

export default Security
