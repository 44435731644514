/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/no-unsafe-regex */
/* eslint-disable complexity */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import { Alert, Button, Card, Chip, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getUniqueBioLink, getUniqueLink } from 'src/api/links'
import DomainNameSelectField from 'src/components/form-elements/DomainNameSelectField'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import MarketingSettingsModal from 'src/components/pages/Links/LinksList/LinksListModal/MarketingCloudSettingsModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingLink } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
  uniqueLinkBoolean: boolean
  setUniqueLinkBoolean: React.Dispatch<React.SetStateAction<boolean>>
}

const checkCname = /(?:[\dA-Za-z]{2,61}\.){2}[\dA-Za-z]{2,61}/

const EditLinkComplete = (props: Props) => {
  const themeItem = useTheme()
  const { currentOrganisation } = useAuth()
  const [settings, setSettings] = useState(false)
  const [billingModal, setBillingModalOpen] = useState(false)

  const notRightTier =
    currentOrganisation?.membershipTier !== 'CREATOR_PRO' &&
    currentOrganisation?.membershipTier !== 'CREATOR_PRO_YEARLY' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY'

  const domainsList = currentOrganisation?.customDomainSubmitted
    ? [ 'rls.ee', 'trck.ee', currentOrganisation.customDomain ]
    : [ 'rls.ee', 'trck.ee' ]

  const missingSubdomain = props.smartLinkData.subdomain.length === 0 && !checkCname.test(props.smartLinkData.domain)
  const missingUrl = props.smartLinkData.url.length === 0

  const handleSubdomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const linkName =
      event.target.value.replace(/\s/g, '').replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~\-]/g, '').toLowerCase()
    props.setLinkData(old => ({ ...old, subdomain: linkName }))
  }

  const handleDomainChange = (service: string) => {
    props.setLinkData(old => ({ ...old, domain: service }))
  }

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const linkName =
      event.target.value.replace(/\s/g, '').replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~]/g, '').toLowerCase()
    props.setLinkData(old => ({ ...old, url: linkName }))
  }

  useEffect(() => {
    if (props.smartLinkData.subdomain.length > 0 &&
        !checkCname.test(props.smartLinkData.domain) &&
        props.smartLinkData.type !== 'Biolink') {
      void getUniqueLink(props.smartLinkData.subdomain,
        props.smartLinkData.domain,
        props.smartLinkData.url,
        props.smartLinkData.id ?? '')
        .then(props.setUniqueLinkBoolean)
    } else if (props.smartLinkData.type !== 'Biolink') {
      const test = props.smartLinkData.domain.split('.')
      void getUniqueLink(test[0], test[1], props.smartLinkData.url, props.smartLinkData.id ?? '')
        .then(props.setUniqueLinkBoolean)
    } else {
      void getUniqueBioLink(props.smartLinkData.url, props.smartLinkData.id ?? '')
        .then(props.setUniqueLinkBoolean)
    }
  }, [props.smartLinkData.subdomain, props.smartLinkData.domain, props.smartLinkData.url, props.smartLinkData.type])

  return (
    <Stack direction='column' maxWidth={896} spacing={4}>
      <MarketingSettingsModal
        close={() => setSettings(false)}
        open={settings}
      />
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Stack width={1}>
        <Stack
          alignItems='flex-start'
          direction='row'
          justifyContent='space-between'
          paddingTop={2}
          width={1}
        >
          <Typography variant='h3'>
            Destination
          </Typography>
        </Stack>
        <Card
          variant='outlined'
        >
          <Stack direction='column' padding={2} width={1}>
            <Stack>
              <Stack>
                <Typography lineHeight={1.4} variant='h4'>
                  Pick your link
                </Typography>
                <Typography color='text.secondary' variant='body1'>
                  {props.smartLinkData.type !== 'Biolink'
                    ? 'Select your preferred subdomain, domain, and destination link.'
                    : 'Select your bio link username.'}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                {props.smartLinkData.type !== 'Biolink' &&
                <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={4} spacing={2}>
                  <TextField
                    autoComplete='new-password'
                    disabled={checkCname.test(props.smartLinkData.domain)}
                    error={missingSubdomain || !props.uniqueLinkBoolean}
                    fullWidth
                    id='subdomain'
                    label='Subdomain'
                    onChange={handleSubdomainChange}
                    placeholder='Subdomain'
                    sx={{ marginTop: 0 }}
                    value={props.smartLinkData.subdomain}
                  />
                  <DomainNameSelectField
                    error={!props.uniqueLinkBoolean}
                    serviceName={props.smartLinkData.domain}
                    services={domainsList}
                    setInputSelected={handleDomainChange}
                  />
                  <TextField
                    autoComplete='new-password'
                    error={missingUrl || !props.uniqueLinkBoolean}
                    fullWidth
                    id='url'
                    label='URL'
                    onChange={handleUrlChange}
                    placeholder='URL'
                    sx={{ marginTop: 0 }}
                    value={props.smartLinkData.url}
                  />
                </Stack>}
                {props.smartLinkData.type === 'Biolink' &&
                <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={4} spacing={2}>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>
                        mylinks.ee/
                      </InputAdornment>,
                    }}
                    autoComplete='new-password'
                    fullWidth
                    id='myusername'
                    label='Username'
                    onChange={handleUrlChange}
                    placeholder='myusername'
                    sx={{
                      marginTop: 0,
                      '.MuiInputBase-input': {
                        paddingLeft: 0,
                      },
                    }}
                    value={props.smartLinkData.url}
                  />
                </Stack>}

                {!props.uniqueLinkBoolean &&
                <Typography color='error' variant='body2'>
                  This URL is already taken, please choose another
                </Typography>}

                {!missingSubdomain && !missingUrl && props.smartLinkData.type !== 'Biolink' &&
                <Stack width={1}>
                  <Typography color='text.label' variant='body2'>
                    Link preview
                  </Typography>
                  <Typography color={themeItem.palette.text.link} variant='body1'>
                    {String(props.smartLinkData.subdomain).length > 0 &&
                    String(props.smartLinkData.domain).split('.').length === 2
                    // eslint-disable-next-line max-len
                      ? `https://${String(props.smartLinkData.subdomain)}.${String(props.smartLinkData.domain)}/${String(props.smartLinkData.url)}`
                      : `https://${String(props.smartLinkData.domain)}/${String(props.smartLinkData.url)}`}
                  </Typography>
                  <Typography color='text.secondary' marginTop={2} variant='body2'>
                    The subdomain is what comes before the unique Releese domain, typically this would be your
                    artist/label name. The URL is what comes after the last slash, typically this would be your
                    song title without any spaces.
                  </Typography>
                </Stack>}
                {(missingSubdomain || missingUrl) && props.smartLinkData.type !== 'Biolink' &&
                <Stack width={1}>
                  <Typography color='text.label' variant='body2'>
                    Example link preview
                  </Typography>
                  <Typography color={themeItem.palette.text.link} variant='body1'>
                    https://subdomain.rls.ee/url
                  </Typography>
                  <Typography color='text.secondary' marginTop={2} variant='body2'>
                    The subdomain is what comes before the unique Releese domain, typically this would be your
                    artist/label name. The URL is what comes after the last slash, typically this would be your
                    song title without any spaces.
                  </Typography>
                </Stack>}
              </Stack>
              {!notRightTier &&
              props.smartLinkData.type !== 'Biolink' &&
              <Button
                color='secondary'
                onClick={() => setSettings(true)}
                startIcon={<PhonelinkRoundedIcon />}
                sx={{
                  width: 'fit-content',
                  marginTop: 1,
                }}
              >
                Manage custom domain
              </Button>}
            </Stack>
          </Stack>
        </Card>
      </Stack>

      {notRightTier && props.smartLinkData.type !== 'Biolink' &&
      <Alert icon={false} severity='info' sx={{ background: '#26252a' }} variant='filled'>
        <Stack>
          <Typography lineHeight={1.4} variant='h3'>
            {'Custom domain '}
            <Chip
              color='success'
              label='Pro+'
              size='small'
            />
          </Typography>
          <Typography color='white' variant='body1'>
            Remove all Releese branding and bring your own domain instead of &quot;rls.ee&quot; and &quot;trck.ee&quot;.
          </Typography>
        </Stack>
        <Button
          onClick={() => setBillingModalOpen(true)}
          startIcon={<AutoAwesomeRoundedIcon />}
          sx={{
            width: 'fit-content',
            marginTop: 1,
            flexShrink: 0,
            color: 'white!important',
            background: '#FFA500',
            ':hover': {
              background: '#c47f00',
            },
          }}
        >
          Upgrade
        </Button>
      </Alert>}
    </Stack>
  )
}
export default EditLinkComplete
