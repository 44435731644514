/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/display-name */
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { Fab, Stack, Tooltip, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import type { ListChildComponentProps } from 'react-window'
import { FixedSizeList as List } from 'react-window'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import SearchField from 'src/components/form-elements/SearchField'
import { DrawerState } from 'src/components/pages/Menu/MenuDrawer'
import ConversationItem from 'src/components/pages/Messages/ConversationsPanel/ConversationItem'
import NewMessageModal from 'src/components/pages/Messages/NewMessageModal/NewMessageModal'
import { useMessenger } from 'src/components/providers/MessengerProvider'

type Props = {
  toggleDrawer: React.Dispatch<React.SetStateAction<DrawerState>>
  openSmallMessages: React.Dispatch<React.SetStateAction<boolean>>
}

const MenuMessages = (props: Props) => {
  const { conversations } = useMessenger()
  const [newMessage, setNewMessage] = useState(false)
  const [searchText, setSearchText] = useState('')

  const handleClick = () => {
    props.openSmallMessages(true)
    props.toggleDrawer(DrawerState.Closed)
  }

  const filteredConversations = conversations
    .filter(item => item.lastMessage.content.length > 0)
    .sort((a, b) => +b.lastMessage.createdAt - +a.lastMessage.createdAt)

  const Row = ({ index, style }: ListChildComponentProps) => {
    const conversation = filteredConversations[index]
    return (
      <Stack onClick={() => handleClick()} style={{ ...style }}>
        <ConversationItem conversation={conversation} key={conversation.id} searchText={searchText} />
      </Stack>
    )
  }

  const ref = useRef<HTMLDivElement | null>(null)
  const dimensions = useContainerDimensions(ref)

  return (
    <Stack height={1} sx={{ flexShrink: 0 }} width={360}>
      <Typography
        paddingLeft={2}
        paddingTop={2}
        variant='h3'
      >
        Messages
      </Typography>
      <Stack alignItems='center' direction='row' paddingBottom={2} paddingTop={1} paddingX={2} spacing={1}>
        <SearchField
          onChange={event => setSearchText(event.target.value)}
          sx={{ marginTop: 0, width: 300 }}
        />
        <Tooltip onClick={() => setNewMessage(true)} title='New conversation'>
          <Fab color='primary' size='small'>
            <CreateRoundedIcon />
          </Fab>
        </Tooltip>
      </Stack>
      <Stack height={1} overflow='hidden' ref={ref} width={1}>
        <List
          height={dimensions.height ?? 1}
          itemCount={filteredConversations.length ?? 0}
          itemSize={84}
          width='100%'
        >
          {Row}
        </List>
      </Stack>
      <NewMessageModal
        close={() => setNewMessage(false)}
        conversations={conversations.sort((a, b) =>
          +b.lastMessage.createdAt - +a.lastMessage.createdAt)}
        open={newMessage}
      />
    </Stack>
  )
}

export default MenuMessages
